<template src="./activityScaleAndOptions.html"></template>

<script>
import NewFeedbackFormRepository from "../../../Repository/Feedbackform";
import showStatus from "../../../NetworkManager/showStatus";
import Feedback from "../../../Repository/Feedback.js";

export default {
  name: "activityScaleAndOptions",
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      primaryCOlor: "#ff4f1f",
      lowestNo: "",
      highestNo: "",
      scales: [],
      tableHeaders: [
        {
          text: "Question No",
          align: "start",
          value: "questionNumber",
          width: "5px",
        },
        {
          text: "Statement",
          align: "start",
          value: "statement",
          width: "30px",
        },
        { text: "Type", align: "start", value: "type", width: "10px" },
        {
          text: "scale/options",
          align: "start",
          value: "scaleOrOptions",
          width: "10px",
          sortable: false,
        },
        {
          text: "Scale Lowest",
          align: "start",
          value: "scaleLowest",
          width: "10px",
          sortable: false,
        },
        {
          text: "Scale highest",
          align: "start",
          value: "scaleHighest",
          width: "10px",
          sortable: false,
        },
        {
          text: "No of Options",
          align: "start",
          value: "noOfOptions",
          width: "10px",
          sortable: false,
        },
        {
          text: "Scale label / Option labels",
          value: "scaleLabel",
          width: "140px",
          sortable: false,
        },
      ],
      templateData: null,
      optionQuestions: [],
      feedback: {},
      feedbackId: "",
      createdFeedback: {},
      selectedSubject: 0,
      threshold: 2,
      isSaved: false,
      isBackdated: false,
      isNext: false,
      isEdit: false,
      tempIndex: 0,
      isFirstVisit: null,
      isThresholdEnable: null,
      isNextEnable: null,
      isDataSaved: undefined,
      copyQuestionsModal: false,
      copiedSubjectDetail: {},
      copyFromSubject: "",
      acceptAllScale: false,
      isLoading: true,
    };
  },
  props: [
    "props_selectedFeedback",
    "props_isTab",
    "props_isTabActive",
    "props_selections",
    "props_isNextClick",
  ],
  created() {
    this.NewFeedbackFormRepositoryInstance = new NewFeedbackFormRepository(
      this
    );
    this.feedbackRepositoryInstance = new Feedback(this);
    9;
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.templateData =
      this.$store.getters["liveData/get_templateTabDependencies"];
    this.feedbackId = this.$store.getters["liveData/get_createdFeedback"];
    this.createdFeedback = this.$store.getters["liveData/get_createdFeedback"];
    this.isEdit = this.props_selectedFeedback._id !== undefined ? true : false;
    //nullish coalescing operator (??)
    this.createdFeedback.coursesWiseQuestions ??= this.createdFeedback.courses;
    this.isThresholdEnable = this.createdFeedback.setting.isForNBA;

    const tempQuestionsSource =
      this.props_selectedFeedback.formType === "Course Feedback"
        ? this.props_selectedFeedback.coursesWiseQuestions
        : this.props_selectedFeedback.allQuestions;

    if (tempQuestionsSource !== undefined) {
      this.createdFeedback.coursesForScale = JSON.parse(
        JSON.stringify(tempQuestionsSource)
      );
    }
    this.isFirstVisit = this.props_isTabActive;
    this.storeValuesForEachCourse();
    this.isFirstVisit = false;
  },
  watch: {
    props_isNextClick() {
      this.isNext = false;
      this.createdFeedback =
        this.$store.getters["liveData/get_createdFeedback"];
      this.storeValuesForEachCourse();
    },
    props_isTab() {
      if (
        this.isFirstVisit ||
        this.isFirstVisit === null ||
        this.isFirstVisit === undefined
      ) {
        this.isFirstVisit = false;
      } else {
        this.createdFeedback =
          this.$store.getters["liveData/get_createdFeedback"];
        this.storeValuesForEachCourse();
      }
    },
  },
  methods: {
    checkType(item) {
      item.scaleHighest = 0;
      item.scaleLowest = 0;
      item.noOfOptions = 0;
      item.scaleLabel = [];
      if (item.selectedType === "Multiple Select") {
        item.selectedScaleOrOption = "Options";
      } else if (item.selectedType === "Single Select") {
        item.selectedScaleOrOption = "Scale";
      } else if (item.selectedType === "Rating") {
        item.selectedScaleOrOption = "Scale";
      }
    },
    removeNegativeSign(threshold) {
      if (threshold < 0) {
        threshold = 0;
      }
    },
    updateSelectedFeedback(value) {
      this.$emit("update-selected-feedback", value);
    },
    handleScaleChangeForBasic(value) {
      this.$emit("update-Scale-basic", value);
    },
    async storeValuesForEachCourse() {
      this.isLoading = true;
      this.threshold = this.createdFeedback.threshold
        ? this.createdFeedback.threshold
        : 2;
      this.isBackdated =
        this.createdFeedback.conductionDetails === "Backdated" &&
        this.createdFeedback.feedbackId === undefined;
      if (this.createdFeedback?.formType === "Course Feedback") {
        for (
          let index = 0;
          index < this.createdFeedback?.coursesWiseQuestions?.length;
          index++
        ) {
          const subject = this.createdFeedback.coursesWiseQuestions[index];
          subject.optionQuestions = [];

          await this.storeValues(subject.allQuestions, subject.optionQuestions);
        }
      } else {
        this.optionQuestions = [];
        await this.storeValues(
          this.createdFeedback.allQuestions,
          this.optionQuestions
        );
      }
      this.tempIndex++;
      this.isLoading = false;
    },
    async storeValues(allQuestions, optionQuestions) {
      for (let i = 0; i < allQuestions?.length; i++) {
        let templateQuestions = allQuestions[i];
        for (let j = 0; j < templateQuestions?.question?.length; j++) {
          for (
            let k = 0;
            k < templateQuestions?.question[j]?.question?.length;
            k++
          ) {
            optionQuestions.push({
              questionNumber:
                templateQuestions?.question[j]?.question[k]?.questionNumber,
              statement:
                templateQuestions?.question[j]?.question[k]?.questionStatement,
              questionId:
                templateQuestions?.question[j]?.question[k]?.questionId,
              type: [
                "Multiple Select",
                "Single Select",
                // "Short Answer",
                // "Long Answer",
                // "Rating",
              ],
              scaleOrOptions: ["Options", "Scale"],
              scaleLowest:
                templateQuestions?.question[j]?.question[k]?.scales?.lowestNo ||
                0,
              scaleHighest:
                templateQuestions?.question[j]?.question[k]?.scales
                  ?.highestNo || 0,
              noOfOptions:
                templateQuestions?.question[j]?.question[k]?.scales
                  ?.noOfOptions || 0,
              scaleLabel:
                templateQuestions?.question[j]?.question[k]?.scales?.scale ||
                [],
              errorMsg: "",
              selectedType:
                templateQuestions?.question[j]?.question[k]?.scales
                  ?.selectedType || "",
              selectedScaleOrOption:
                templateQuestions?.question[j]?.question[k]?.scales
                  ?.selectedScaleOrOption || "",
            });
          }
        }
      }
      return optionQuestions;
    },
    validateScale(item) {
      if (item.scaleLowest > item.scaleHighest) {
        item.errorMsg = "Invalid range";
      } else {
        item.errorMsg = "";
        this.addOptions(item);
      }
    },
    addOptions(item, i) {
      let arr = [];
      let length = 0;
      let selectedNo = 0;

      if (item?.selectedScaleOrOption === "Options") {
        length = Number(item?.noOfOptions);
        selectedNo = item?.noOfOptions;
      }

      if (
        item?.selectedScaleOrOption === "Scale" &&
        item?.scaleHighest > item?.scaleLowest
      ) {
        // Calculate the number of objects needed based on the range
        selectedNo = item?.scaleHighest - item?.scaleLowest + 1;
        length = selectedNo;
      }

      if (selectedNo && selectedNo >= (item?.scaleLabel?.length || 0)) {
        for (let j = item?.scaleLabel?.length || 0; j < length; j++) {
          arr.push({
            scaleNumber: item?.scaleLowest + j, // Start scaleNumber from scaleLowest
            scaleLabel: "",
          });
        }
        item.scaleLabel = item.scaleLabel.concat(arr);
      } else {
        item.scaleLabel = item.scaleLabel.slice(0, length);
      }

      this.$set(this.optionQuestions, i, item);
    },
    updateQuestions(questions, optionQuestions, parentScale = null) {
      questions?.forEach((question) => {
        // If the questionType is "Question", update its scales
        if (question.questionType === "Question") {
          optionQuestions?.forEach((option) => {
            if (option.questionId === question.questionId) {
              // Update scales for the main question
              question.scales = {
                scale: option.scaleLabel,
                selectedType: option.selectedType,
                selectedScaleOrOption: option.selectedScaleOrOption,
              };

              if (option.selectedScaleOrOption === "Scale") {
                question.scales.lowestNo = option.scaleLowest;
                question.scales.highestNo = option.scaleHighest;
              } else if (option.selectedScaleOrOption === "Options") {
                question.scales.noOfOptions = option.noOfOptions;
              }

              // Set the current question's scale as the parent scale
              parentScale = question.scales;
            }
          });
        }

        // If the questionType is "SubQuestion", apply the parent scale if available
        if (question.questionType === "SubQuestion" && parentScale) {
          question.scales = { ...parentScale };
        }

        // Recursively update sub-questions if they exist
        if (question.question && Array.isArray(question.question)) {
          // Pass the parent scale to the recursive function call
          this.updateQuestions(question.question, optionQuestions, parentScale);
        }
      });

      return questions;
    },
    storeScaleOrOptions() {
      if (this.createdFeedback?.formType === "Course Feedback") {
        for (
          let index = 0;
          index < this.createdFeedback.coursesWiseQuestions.length;
          index++
        ) {
          const subject = this.createdFeedback.coursesWiseQuestions[index];
          const questions = this.updateQuestions(
            subject.allQuestions,
            subject.optionQuestions
          );
        }
      } else {
        this.updateQuestions(
          this.createdFeedback.allQuestions,
          this.optionQuestions
        );
      }
      this.addQuestionToFeedback();
    },
    async addQuestionToFeedback() {
      try {
        let res = {};
        const objToPush = {
          instituteId: this.createdFeedback.instituteId,
          semId: this.createdFeedback.semId,
          conductionDetails: this.createdFeedback.conductionDetails,
          courseYear: this.createdFeedback.courseYear,
          divisions: this.createdFeedback.divisions,
          department: this.createdFeedback.department,
          startDate: this.createdFeedback.startDate,
          endDate: this.createdFeedback.endDate,
          extendedDate: this.createdFeedback.extendedDateTime,
          stakeholder: this.createdFeedback.stakeholder,
          formDescription: this.createdFeedback.formDescription,
          formTitle: this.createdFeedback.formTitle,
          formType: this.createdFeedback.formType,
          formURL: this.createdFeedback.formURL,
          responseType: this.createdFeedback.responseType,
          threshold: this.threshold,
          selectedTypeOfSurvey: this.createdFeedback.selectedTypeOfSurvey,
          setting: this.createdFeedback.setting,
          stakeHolderDetails: this.createdFeedback.stakeHolderDetails,
          status: this.isBackdated ? "Published" : "Draft",
          isScaleSaved: false,
        };
        if (this.createdFeedback?.formType === "Course Feedback") {
          // Remove the unwanted fields from this.createdFeedback
          this?.createdFeedback?.coursesWiseQuestions?.forEach((course) => {
            delete course.optionQuestions;
          });
          objToPush["courses"] = this.createdFeedback.coursesWiseQuestions;
          objToPush["tempQuestions"] =
            this.createdFeedback.coursesWiseQuestions;
        } else {
          objToPush["allQuestions"] = this.createdFeedback.allQuestions;
          objToPush["tempQuestions"] = this.createdFeedback.allQuestions;
        }
        if (this.isSaved) {
          if (this.createdFeedback.feedbackId !== undefined) {
            objToPush["feedbackId"] = this.createdFeedback.feedbackId;
            res = await this.feedbackRepositoryInstance.updateFeedback(
              objToPush
            );
          } else {
            objToPush["feedbackId"] = this.createdFeedback.formURL;
            res = await this.feedbackRepositoryInstance.createFeedback(
              objToPush
            );
          }
          if (res.status === 200) {
            objToPush.coursesWiseQuestions ??= objToPush.courses;
            await this.$store.commit("liveData/set_createdFeedback", objToPush);
            await this.updateSelectedFeedback(true);
            await this.handleScaleChangeForBasic(true);
            this.$store.getters["liveData/get_createdFeedback"];
            showStatus("Successfully saved.", 2000, "success", this);
            if (!this.isEdit && this.isBackdated) {
              this.$router.push("/activityFeedbackFormRevamped");
            }
            this.isDataSaved = true;
            this.isNext = false;
            this.storeValuesForEachCourse();
          } else {
            showStatus("Something went wrong", 2000, "error", this);
          }
        } else {
          objToPush["feedbackId"] = this.isDataSaved
            ? this.createdFeedback.formURL
            : undefined;
          objToPush["coursesWiseQuestions"] =
            objToPush.courses || objToPush.allQuestions;
          objToPush["coursesForScale"] =
            objToPush.courses || objToPush.allQuestions;
          await this.goToNextPage(objToPush);
        }
      } catch (e) {
        console.error(e);
      }
    },
    handleCopyQuestionsModal(subject) {
      this.copyQuestionsModal = true;
      this.copiedSubjectDetail = subject;
    },
    handleCopyFromSubject() {
      // Create a map for the optionQuestions from copyFromSubject
      const questionMap = new Map(
        this.copyFromSubject.optionQuestions.map((obj2) => [
          obj2.questionNumber,
          obj2,
        ])
      );

      // Iterate over copiedSubjectDetail.optionQuestions and update the properties
      this.copiedSubjectDetail.optionQuestions.forEach((obj1) => {
        const matchingObj = questionMap.get(obj1.questionNumber);
        if (matchingObj) {
          obj1.selectedScaleOrOption = matchingObj.selectedScaleOrOption;
          obj1.selectedType = matchingObj.selectedType;
          obj1.scaleLowest = matchingObj.scaleLowest;
          obj1.scaleLabel = matchingObj.scaleLabel;
          obj1.scaleHighest = matchingObj.scaleHighest;
        }
      });

      this.copyQuestionsModal = false;
      showStatus(
        `Successfully Copied data from ${this.copyFromSubject.subjectName}.`,
        2000,
        "success",
        this
      );
    },
    selectAllScaleLabel(subject) {
      const isCourseFeedback =
        this.createdFeedback?.formType === "Course Feedback";
      const targetArray = isCourseFeedback ? subject.optionQuestions : subject;
      const acceptAllScale = isCourseFeedback
        ? subject.acceptAllScale
        : this.acceptAllScale;

      if (targetArray.length > 0) {
        const {
          scaleLowest,
          scaleLabel,
          scaleHighest,
          selectedScaleOrOption,
          selectedType,
        } = targetArray[0];

        targetArray.forEach((obj, i) => {
          if (i > 0) {
            Object.assign(
              obj,
              acceptAllScale
                ? {
                    scaleLowest,
                    scaleLabel,
                    scaleHighest,
                    selectedScaleOrOption,
                    selectedType,
                  }
                : {
                    scaleLowest: "",
                    scaleLabel: [],
                    scaleHighest: "",
                    selectedScaleOrOption: "",
                    selectedType: "",
                  }
            );
          }
        });
      }
    },
    // selectAllScaleLabel(subject) {
    //   if (this.createdFeedback?.formType === "Course Feedback") {
    //     const {
    //       scaleLowest,
    //       scaleLabel,
    //       scaleHighest,
    //       selectedScaleOrOption,
    //       selectedType,
    //     } = subject.optionQuestions[0];

    //     subject.optionQuestions.forEach((obj, i) => {
    //       if (i > 0) {
    //         Object.assign(
    //           obj,
    //           subject.acceptAllScale
    //             ? {
    //                 scaleLowest,
    //                 scaleLabel,
    //                 scaleHighest,
    //                 selectedScaleOrOption,
    //                 selectedType,
    //               }
    //             : {
    //                 scaleLowest: "",
    //                 scaleLabel: [],
    //                 scaleHighest: "",
    //                 selectedScaleOrOption: "",
    //                 selectedType: "",
    //               }
    //         );
    //       }
    //     });
    //   } else {
    //     const {
    //       scaleLowest,
    //       scaleLabel,
    //       scaleHighest,
    //       selectedScaleOrOption,
    //       selectedType,
    //     } = subject[0];

    //     subject.forEach((obj, i) => {
    //       if (i > 0) {
    //         Object.assign(
    //           obj,
    //           this.acceptAllScale
    //             ? {
    //                 scaleLowest,
    //                 scaleLabel,
    //                 scaleHighest,
    //                 selectedScaleOrOption,
    //                 selectedType,
    //               }
    //             : {
    //                 scaleLowest: "",
    //                 scaleLabel: [],
    //                 scaleHighest: "",
    //                 selectedScaleOrOption: "",
    //                 selectedType: "",
    //               }
    //         );
    //       }
    //     });
    //   }
    // },
    async goToNextPage(createdFeedback) {
      this.$store.commit("liveData/set_createdFeedback", createdFeedback);

      if (this.isNext) {
        this.$emit("nextButtonClick");
      }
    },
  },
};
</script>

<style src="./activityScaleAndOptions.css"></style>

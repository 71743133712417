<template src="./activityResponses.html"></template>

<script>
import NewFeedbackFormRepository from "../../../Repository/Feedbackform";
import showStatus from "../../../NetworkManager/showStatus";
import Feedback from "../../../Repository/Feedback.js";
import UserRepository from "../../../Repository/User";
import FeedbackResponse from "../../../Repository/FeedbackResponse.js";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import {
  generateJsonFromExcel,
  createExcelThroughJson,
  generateFromJson,
  generateFromJsonForDropdownAndPreFieldData,
} from "../../../utils/excel";
export default {
  name: "activityResponses",
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      progressIndicator1: 0,
      uploadQuestionsViaExcel: false,
      generatedLink: "",
      generatedIcon: "mdi-content-copy",
      isCopied: false,
      selectedRadio: null,
      uploadResponsesViaExcel: false,
      responsesExcelFile: null,
      feedback: {},
      feedbackId: "",
      optionQuestions: [],
      excelLink: "",
      schema: {},
      feedbackTopSelections: {},
      selectedInstitute: "",
      students: [],
      allStudents: [],
      faculties: [],
      facultiesNameWithUid: [],
      selectedSubject: 0,
      fbResponseHeader: ["question", "No of Students attemped", "Scale"],
      fbResponse: [
        {
          questionStatement: "ABC",
          noOfStudent: 40,
          attemptedStudents: 20,
          scale: [
            {
              scaleLabel: "A",
              attemped: 2,
            },
            {
              scaleLabel: "B",
              attemped: 4,
            },
            {
              scaleLabel: "C",
              attemped: 3,
            },
          ],
        },
        {
          questionStatement: "XYZ",
          noOfStudent: 40,
          attemptedStudents: 20,
          scale: [
            {
              scaleLabel: "AA",
              attemped: 2,
            },
            {
              scaleLabel: "BB",
              attemped: 4,
            },
            {
              scaleLabel: "CC",
              attemped: 3,
            },
          ],
        },
      ],
      mainHeaders: [
        { text: "Questions", colspan: 1 },
        { text: "No of students attempted", colspan: 1 },
      ],
      subHeaders: [
        { text: "" }, // Placeholder for the "Questions" column
        { text: "" }, // Placeholder for the "No of students attempted" column
      ],
      studentsRes: [
        {
          prn: "1234567",
          name: "Name Surname",
          submissionStatus: "1/01/2024",
          q1: 5,
          q2: "Good",
          q3: { q31: "Option 1", q32: "Option 1" },
          q4: ["Option 1", "Option 2"],
          q5: 5,
        },
        {
          prn: "1234567",
          name: "Name Surname",
          submissionStatus: "1/01/2024",
          q1: 3,
          q2: "It was better than last time",
          q3: { q31: "Option 2", q32: "Option 2" },
          q4: ["Option 2"],
          q5: 3,
        },
        {
          prn: "1234567",
          name: "Name Surname",
          submissionStatus: null,
          q1: null,
          q2: null,
          q3: { q31: null, q32: null },
          q4: [],
          q5: null,
        },
        {
          prn: "1234567",
          name: "Name Surname",
          submissionStatus: "1/01/2024",
          q1: 3,
          q2: "Better",
          q3: { q31: "Option 1", q32: "Option 1" },
          q4: ["Option 1", "Option 3"],
          q5: 3,
        },
      ],
      allQuestions: [],
      totalCol: 0,
      feedbacksResponse: [],
      studentResponses: [],
      noDataMessage: "",
      studentLoader: false,
      selectedFeedback: [],
      selectedVal: 0,
      selectedSubjectId: "",
      isResponseLoading: false,
    };
  },
  watch: {
    isCopied: function resetIcon() {
      setTimeout(() => {
        this.isCopied = false;
        this.generatedIcon = "mdi-content-copy";
      }, 2000);
    },
    selectedSubject(newVal) {
      this.selectedVal = newVal;
      this.createdFeedback.courses[newVal].allQuestions;
      this.createdFeedback.courses[newVal].onlyQuestions =
        this.createdFeedback.courses[newVal].allQuestions;
      // this.allQuestions[newVal].onlyQuestions =
      //   this.allQuestions[
      //     this.selectedVal
      //   ].allQuestions[0].question[0].question;
      this.getResponse();
    },
  },
  async created() {
    this.feedbackRepositoryInstance = new Feedback(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.feedbackResponseRepositoryInstance = new FeedbackResponse(this);
    this.feedbackTopSelections =
      this.$store.getters["liveData/get_feedbackTopSelections"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.templateData =
      this.$store.getters["liveData/get_templateTabDependencies"];
    this.createdFeedback = this.$store.getters["liveData/get_createdFeedback"];
    this.feedbackId = this.$store.getters["liveData/get_settingFeedbackId"];

    if (!this.createdFeedback?.feedbackId) return;

    const { stakeholder, formType, courses, allQuestions } =
      this.createdFeedback;

    if (stakeholder === "Students") {
      this.allQuestions =
        formType === "Program Feedback" ? allQuestions || [] : courses;

      await this.fetchStudents();
    } else {
      await this.fetchFaculties();
    }

    this.totalCol = 4 + this.allQuestions?.length;
  },
  methods: {
    async fetchStudents() {
      this.isResponseLoading = true;
      try {
        let facultyId = [];
        let students = [];

        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.feedbackTopSelections.semester.semId,
          department: this.createdFeedback.department,
          courseYear: this.createdFeedback.courseYear,
        };
        if (this.createdFeedback.formType === "Program Feedback") {
          objToPush.multiDIvision = this.createdFeedback.divisions;
          students =
            await this.semesterUserRepositoryInstance.getStudentsOfADivision(
              objToPush
            );
        } else {
          objToPush.subjectIds = this.createdFeedback.courses?.map(
            (item) => item.subjectId
          );
          students =
            await this.semesterUserRepositoryInstance.getStudentsOfSubjects(
              objToPush
            );
        }
        if (students.length > 0) {
          facultyId = students.map((student) => student.uId);

          this.facultiesNameWithUid =
            await this.userRepositoryInstance.getFullNameOfUsers({
              uIds: facultyId,
            });

          const facultyDetailsMap = new Map();
          this.facultiesNameWithUid.forEach((faculty) => {
            facultyDetailsMap.set(faculty.uId, {
              studentName: faculty.fullName,
              collegePRNNo: faculty.collegePRNNo,
              email: faculty.email,
              uId: faculty.uId,
            });
          });

          this.allStudents = students
            .filter(
              (student) => facultyDetailsMap.get(student.uId)?.collegePRNNo
            )
            .map((student) => ({
              ...student,
              ...facultyDetailsMap.get(student.uId),
            }));
        } else {
          showStatus("Students are not present", 2000, "error", this);
        }
        await this.getResponse();
        this.isResponseLoading = false;
      } catch (err) {
        console.error(err);
        this.allStudents = [];
      }
    },
    async generateResponsesExcel() {
      const subject = this.allQuestions[this.selectedSubject];
      const data = this.studentResponses.map((e) => {
        const { uId, submissionStatus, ...rest } = e;

        // Create a new object with transformed keys
        const transformedRest = Object.fromEntries(
          Object.entries(rest).map(([key, value]) => {
            // Find the question matching the key
            const question = subject.onlyQuestions.find(
              (q) => q.questionNumber === key
            );
            // If found, replace key with questionStatement
            return [question ? question.questionStatement : key, value];
          })
        );

        // Return the transformed object
        return {
          ...transformedRest,
        };
      });

      createExcelThroughJson(
        data,
        subject.subjectName,
        `${this.createdFeedback.formTitle}`,
        `${this.createdFeedback.department} | ${this.createdFeedback.courseYear} | ${this.createdFeedback.formDescription}`
      );
    },
    async fetchFaculties() {
      this.isResponseLoading = true;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          department: this.createdFeedback.department,
        };
        // Fetch Faculties
        this.students =
          await this.semesterUserRepositoryInstance.getAllFacultiesOfADepartment(
            objToPush
          );
        if (this.students?.length > 0) {
          const facultyDetailsMap = new Map();
          this.facultiesNameWithUid.forEach((faculty) => {
            facultyDetailsMap.set(faculty.uId, {
              studentName: faculty.fullName,
              email: faculty.email,
              uId: faculty.uId,
            });
          });
          this.students = this.students.map((student) => ({
            ...student,
            ...facultyDetailsMap.get(student.uId),
          }));
          await this.getResponse();
          this.isResponseLoading = false;
        } else {
          showStatus("Faculties are not present", 2000, "error", this);
        }
      } catch (e) {
        console.error(e);
      }
    },
    filteredStudents(feedbackResponse) {
      if (this.createdFeedback.formType === "Program Feedback") {
        this.students = this.allStudents;
        this.feedbacksResponse = feedbackResponse;
      } else {
        const selectedSubjectId =
          this.allQuestions[this.selectedVal]?.subjectId || null;

        if (selectedSubjectId) {
          this.students = this.allStudents.filter((student) =>
            student.subjects.includes(selectedSubjectId)
          );

          this.feedbacksResponse =
            feedbackResponse?.filter(
              (feedback) => feedback.subjectId === selectedSubjectId
            ) || [];
        } else {
          this.students = [];
          this.feedbacksResponse = [];
        }

        this.selectedSubjectId = selectedSubjectId;
      }
    },
    async handleQuestionData(feedbackResponse) {
      this.studentLoader = true;
      this.filteredStudents(feedbackResponse);

      this.optionQuestions = [];
      this.studentResponses = [];

      const questions =
        this.createdFeedback?.formType === "Course Feedback"
          ? this.allQuestions[this.selectedVal].allQuestions
          : this.createdFeedback.allQuestions;

      if (this.createdFeedback.stakeholder === "Students") {
        let studentResponses = this.storeStudentWiseResponse(
          questions,
          this.studentResponses
        );
        this.studentResponses = studentResponses;
      } else {
        this.storeFacultyWiseResponse(questions);
      }

      this.studentLoader = false;
    },
    // async storeValues(allQuestions, optionQuestions) {
    //   for (let i = 0; i < allQuestions[0]?.question?.length; i++) {
    //     let templateQuestions = allQuestions[0].question[i];
    //     for (let j = 0; j < templateQuestions?.question?.length; j++) {
    //       optionQuestions.push({
    //         questionNumber: templateQuestions?.question[j].questionNumber,
    //         statement: templateQuestions?.question[j].questionStatement,
    //         questionId: templateQuestions?.question[j].questionId,
    //         type: [
    //           "Multiple Select",
    //           "Single Select",
    //           "Short Answer",
    //           "Long Answer",
    //           "Rating",
    //         ],
    //         scaleOrOptions: ["Options", "Scale"],
    //         scaleLowest: templateQuestions?.question[j]?.scales?.lowestNo || 0,
    //         scaleHighest:
    //           templateQuestions?.question[j]?.scales?.highestNo || 0,
    //         noOfOptions:
    //           templateQuestions?.question[j]?.scales?.noOfOptions || 0,
    //         scaleLabel: templateQuestions?.question[j]?.scales?.scale || [],
    //         selectedType:
    //           templateQuestions?.question[j]?.scales?.selectedType || "",
    //         selectedScaleOrOption:
    //           templateQuestions?.question[j]?.scales?.selectedScaleOrOption ||
    //           "",
    //       });
    //       for (
    //         let k = 0;
    //         k < templateQuestions?.question[j]?.question?.length;
    //         k++
    //       ) {
    //         optionQuestions.push({
    //           questionNumber:
    //             templateQuestions?.question[j]?.question[k]?.questionNumber,
    //           statement:
    //             templateQuestions?.question[j]?.question[k]?.questionStatement,
    //           questionId:
    //             templateQuestions?.question[j]?.question[k]?.questionId,
    //           type: [
    //             "Multiple Select",
    //             "Single Select",
    //             "Short Answer",
    //             "Long Answer",
    //             "Rating",
    //           ],
    //           scaleOrOptions: ["Options", "Scale"],
    //           scaleLowest:
    //             templateQuestions?.question[j]?.question[k]?.scales?.lowestNo ||
    //             0,
    //           scaleHighest:
    //             templateQuestions?.question[j]?.question[k]?.scales
    //               ?.highestNo || 0,
    //           noOfOptions:
    //             templateQuestions?.question[j]?.question[k]?.scales
    //               ?.noOfOptions || 0,
    //           scaleLabel:
    //             templateQuestions?.question[j]?.question[k]?.scales?.scale ||
    //             [],
    //           selectedType:
    //             templateQuestions?.question[j]?.question[k]?.scales
    //               ?.selectedType || "",
    //           selectedScaleOrOption:
    //             templateQuestions?.question[j]?.question[k]?.scales
    //               ?.selectedScaleOrOption || "",
    //         });
    //         for (
    //           let l = 0;
    //           l < templateQuestions?.question[j]?.question[k]?.question?.length;
    //           l++
    //         ) {
    //           optionQuestions.push({
    //             questionNumber:
    //               templateQuestions?.question[j]?.question[k]?.question[l]
    //                 ?.questionNumber,
    //             statement:
    //               templateQuestions?.question[j]?.question[k]?.question[l]
    //                 ?.questionStatement,
    //             questionId:
    //               templateQuestions?.question[j]?.question[k]?.question[l]
    //                 ?.questionId,
    //             type: [
    //               "Multiple Select",
    //               "Single Select",
    //               "Short Answer",
    //               "Long Answer",
    //               "Rating",
    //             ],
    //             scaleOrOptions: ["Options", "Scale"],
    //             scaleLowest:
    //               templateQuestions?.question[j]?.question[k]?.question[l]
    //                 ?.scales?.lowestNo || 0,
    //             scaleHighest:
    //               templateQuestions?.question[j]?.question[k]?.question[l]
    //                 ?.scales?.highestNo || 0,
    //             noOfOptions:
    //               templateQuestions?.question[j]?.question[k]?.question[l]
    //                 ?.scales?.noOfOptions || 0,
    //             scaleLabel:
    //               templateQuestions?.question[j]?.question[k]?.question[l]
    //                 ?.scales?.scale || [],
    //             selectedType:
    //               templateQuestions?.question[j]?.question[k]?.question[l]
    //                 ?.scales?.selectedType || "",
    //             selectedScaleOrOption:
    //               templateQuestions?.question[j]?.question[k]?.question[l]
    //                 ?.scales?.selectedScaleOrOption || "",
    //           });
    //         }
    //       }
    //     }
    //   }
    //   // return optionQuestions;
    //   return allQuestions;
    // },
    storeOverallResponse(allQuestions) {
      let tempQues = [...allQuestions];
      let studentRes = [
        {
          _id: "66978c05c4e2d6c1e51a8f1e",
          studentResponse: [
            {
              questionId: "72ad079f-e72a-4715-b55d-069ae1287e30",
              question: "Q11",
              questionNumber: "Q1",
              answer: "A",
            },
            {
              questionId: "8fbad554-f346-46aa-8393-0d8311a7d3f9",
              question: "Q11",
              questionNumber: "Q1",
              answer: "A",
            },
          ],
          candidateFullName: "",
          candidateEmailId: "",
          feedbackFormId: "66978c05c4e2d6c1e51a8ef0",
        },
        {
          _id: "66978c05c4e2d6c1e51a8f1e",
          studentResponse: [
            {
              questionId: "72ad079f-e72a-4715-b55d-069ae1287e30",
              question: "Q11",
              questionNumber: "Q1",
              answer: "V",
            },
            {
              questionId: "8fbad554-f346-46aa-8393-0d8311a7d3f9",
              question: "Q11",
              questionNumber: "Q1",
              answer: "A",
            },
          ],
          candidateFullName: "",
          candidateEmailId: "",
          feedbackFormId: "66978c05c4e2d6c1e51a8ef0",
        },
        {
          _id: "66978c05c4e2d6c1e51a8f1e",
          studentResponse: [
            {
              questionId: "72ad079f-e72a-4715-b55d-069ae1287e30",
              question: "Q11",
              questionNumber: "Q1",
              answer: "V",
            },
            {
              questionId: "8fbad554-f346-46aa-8393-0d8311a7d3f9",
              question: "Q11",
              questionNumber: "Q1",
              answer: "A",
            },
          ],
          candidateFullName: "",
          candidateEmailId: "",
          feedbackFormId: "66978c05c4e2d6c1e51a8ef0",
        },
      ];

      let scales = tempQues[0].question[0].question[0].scales.scale;
      this.mainHeaders.push({ text: "Scale", colspan: scales?.length });

      scales.forEach((scaleObj, index) => {
        this.subHeaders.push({ text: `${index + 1} (${scaleObj.scaleLabel})` });
      });

      // Iterate through each tempQues question
      tempQues?.map((obj1) => {
        obj1?.question?.map((obj2) => {
          obj2?.question?.map((obj3) => {
            let scaleCounts = {};
            let attempted = 0;
            let totalStudents = studentRes?.length;

            // Initialize scaleCounts for each scaleLabel
            obj3?.scales?.scale?.map((scaleObj) => {
              scaleCounts[scaleObj.scaleLabel] = 0;
            });

            // Iterate over student responses to count scale matches
            studentRes?.map((stud) => {
              stud?.studentResponse?.map((res) => {
                if (res.questionId === obj3.questionId) {
                  attempted++;
                  if (scaleCounts.hasOwnProperty(res.answer)) {
                    scaleCounts[res.answer]++;
                  }
                }
              });
            });

            let scaleArray = Object.keys(scaleCounts).map(
              (key) => `${scaleCounts[key]} / ${attempted}`
            );

            this.questions.push({
              statement: obj3.questionStatement,
              attempted: `${attempted} / ${totalStudents}`,
              scale: scaleArray,
            });
          });
        });
      });
    },
    storeStudentWiseResponse(allQuestions, studentResponses) {
      this.studentLoader = true;
      let tempStudents = [];
      let students = [];

      if (this.feedbacksResponse?.length > 0) {
        tempStudents = this.studentResponse(allQuestions);
        students = this.studentWiseResponseGenerated(allQuestions);

        const studentMap = new Map(
          students?.map((student) => [student.uId, student])
        );

        tempStudents?.forEach((tempStudent) => {
          const correspondingStudent = studentMap.get(tempStudent.uId);

          if (correspondingStudent) {
            correspondingStudent.prn = tempStudent.prn;
            Object.assign(tempStudent, correspondingStudent);
          }
        });

        studentResponses = [...tempStudents];
      } else {
        studentResponses = this.studentResponse(allQuestions);
      }

      return studentResponses;
    },
    storeFacultyWiseResponse(allQuestions) {
      let tempStudents = [];
      let students = [];

      allQuestions?.forEach((obj1) => {
        obj1?.question?.forEach((obj2) => {
          const currentQuestion = obj2.question; // Access current question once

          if (this.feedbacksResponse?.length > 0) {
            tempStudents = this.facultytResponse(currentQuestion);
            students = this.facultyWiseResponseGenerated(currentQuestion);

            const studentMap = new Map(
              students.map((student) => [student.uId, student])
            );

            tempStudents.forEach((tempStudent) => {
              const correspondingStudent = studentMap.get(tempStudent.uId);
              if (correspondingStudent) {
                correspondingStudent.prn = tempStudent.prn; // Update PRN
                Object.assign(tempStudent, correspondingStudent); // Replace the whole object in tempStudents
              }
            });

            this.studentResponses = [...tempStudents];
          } else {
            this.studentResponses = this.facultytResponse(currentQuestion);
          }
        });
      });
    },
    copyGenearatedLink() {
      navigator.clipboard.writeText(this.generatedLink);
      this.isCopied = true;
      if (this.isCopied) {
        this.generatedIcon = "mdi-check-circle";
      }
    },
    getSchema(optionQuestions) {
      let removeFields = [];
      this.schema = {
        Sheet1: {
          "Student Name": {
            validation: {
              type: "textLength",
            },
            variableName: "studentName",
          },
          PRN: {
            validation: {
              type: "textLength",
            },
            variableName: "collegePRNNo",
          },
          "Roll Number": {
            validation: {
              type: "textLength",
            },
            variableName: "rollNumber",
          },
          Email: {
            validation: {
              type: "textLength",
            },
            variableName: "email",
          },
          Semester: {
            validation: {
              type: "textLength",
            },
            variableName: "semester",
          },
          Division: {
            validation: {
              type: "textLength",
            },
            variableName: "division",
          },
          Phone: {
            validation: {
              type: "textLength",
            },
            variableName: "phone",
          },
        },
      };
      const questionSet = new Set(optionQuestions.map((q) => q.questionNumber));
      optionQuestions.forEach((item) => {
        const { questionNumber } = item;

        if (questionNumber.includes(".")) {
          item.selectedType = item.scales.selectedType || "";
          item.scaleLabel = item.scales.scale || [];
          removeFields.push(item); // Add child questions directly
        } else {
          // Check if there are no children for this parent question
          const hasChildren = [...questionSet].some((q) =>
            q.startsWith(questionNumber + ".")
          );
          if (!hasChildren) {
            let checked = questionNumber === "Section1";
            if (!checked) {
              item.selectedType = item.scales.selectedType || "";
              item.scaleLabel = item.scales.scale || [];
              removeFields.push(item); // Add the parent question if it has no children
            }
          }
        }
      });

      const addSchemaEntry = (typeAndNumber, validation) => {
        this.schema.Sheet1[typeAndNumber] = {
          validation,
          variableName: typeAndNumber,
        };
      };
      // Process removeFields to build schema
      removeFields.forEach(
        ({
          questionNumber,
          selectedType = "No type",
          scaleHighest,
          scaleLabel,
        }) => {
          const typeAndNumber = `${questionNumber}_(${selectedType})`;

          if (selectedType === "Rating") {
            const scaleArray = Array.from(
              { length: Number(scaleHighest) },
              (_, i) => `${i + 1}`
            );
            addSchemaEntry(typeAndNumber, { type: "list", values: scaleArray });
          } else if (selectedType === "Single Select") {
            const scaleArray = scaleLabel.map((label) => label.scaleLabel);
            addSchemaEntry(typeAndNumber, { type: "list", values: scaleArray });
          } else {
            addSchemaEntry(typeAndNumber, { type: "textLength" });
          }
        }
      );
    },
    async downloadResponsesExcelSample() {
      if (this.createdFeedback.formType === "Course Feedback") {
        this.getSchema(
          this.createdFeedback.courses[this.selectedVal].allQuestions
        );
      } else {
        this.getSchema(this.allQuestions);
      }
      this.excelLink = await generateFromJsonForDropdownAndPreFieldData(
        this.schema,
        this.students
      );
      const link = document.createElement("a");
      link.href = this.excelLink;
      link.target = "_blank"; // Open in a new tab

      // Trigger the download
      link.click();
    },
    async uploadResponsesFromExcel() {
      let allQues = [];
      if (this.createdFeedback.formType === "Course Feedback") {
        allQues = this.createdFeedback.courses[this.selectedVal].allQuestions;
      } else {
        allQues = this.allQuestions;
      }
      this.getSchema(allQues);
      const res = await generateJsonFromExcel(
        this.responsesExcelFile,
        this.schema,
        false
      );
      const { errors, data } = res;

      const emailLookup = new Map(
        this.students.map((student) => [student.email, student.uId])
      );

      data.Sheet1?.forEach((row) => {
        if (!row.studentResponse) {
          row.studentResponse = [];
        }

        Object.keys(row).forEach(async (key) => {
          // Check for keys containing an underscore
          if (key.includes("_")) {
            const questionNumber = key.split("_")[0];

            const allQue = allQues.find(
              (question) => question.questionNumber === questionNumber
            );
            if (allQue) {
              let res;
              let splitKey = key.match(/\(([^)]+)\)/);
              if (splitKey[1] === "Multiple Select") {
                res = row[key].split(",");
              } else {
                res = row[key];
              }

              row.studentResponse.push({
                questionId: allQue.questionId,
                answer: res,
                questionNumber: questionNumber,
              });
            }
          }

          // Handle email to uId mapping
          if (key === "email") {
            row.uId = emailLookup.get(row[key]); // Use the map for quick lookup
            row.subjectId = this.selectedSubjectId;
          }
        });
      });
      let objToPush = {
        feedbackFormId: this.createdFeedback.feedbackId,
        subjectId: this.selectedSubjectId,
        responses: res.data.Sheet1,
      };

      const responses =
        await this.feedbackResponseRepositoryInstance.createResponse(objToPush);

      if (responses === "OK") {
        showStatus("Response saved Sucessfully", 2000, "success", this);
      } else {
        showStatus("Response not saved", 2000, "error", this);
      }
      this.uploadResponsesViaExcel = false;
      await this.getResponse();
    },
    async getResponse() {
      try {
        let feedback =
          await this.feedbackResponseRepositoryInstance.getResponse({
            feedbackId: this.createdFeedback.feedbackId,
          });
        this.handleQuestionData(feedback.feedbacks);
      } catch (e) {
        console.error(e);
      }
    },
    studentWiseResponseGenerated(allQuestions) {
      // Helper function to create empty responses for sub-questions
      const createEmptySubQuestions = (question) => {
        if (question?.question?.length > 0) {
          return question?.question?.reduce((acc, subQuestion) => {
            acc[subQuestion.questionNumber] = null; // Set null for each sub-question
            return acc;
          }, {});
        }
        return null;
      };

      // Check if feedbacksResponse is an empty array
      if (!this.feedbacksResponse || this.feedbacksResponse?.length === 0) {
        // If empty, return placeholder students with basic fields and no question responses
        return this.students.map((student) => ({
          prn: student.collegePRNNo,
          name: student.studentName,
          uId: student.uId,
          submissionStatus: null, // No submission status if no feedback responses
          ...this.createEmptyQuestionResponse(allQuestions), // Empty responses for each question
        }));
      }
      // If feedbacksResponse has data, process it
      let studentsRes = this.feedbacksResponse.map((feedback) => {
        const responses = feedback.studentResponse.reduce((acc, response) => {
          const { questionNumber, answer } = response;

          // Check if it's a sub-question (e.g., Q2.1)
          const parentQuestion = questionNumber.split(".")[0]; // Extract parent (e.g., Q2 from Q2.1)

          // Convert answer based on its type
          let convertedAns = Array.isArray(answer)
            ? answer.filter((item) => typeof item === "string").join(", ")
            : answer;

          if (questionNumber.includes(".")) {
            // It's a sub-question
            if (typeof acc[parentQuestion] !== "object") {
              acc[parentQuestion] = {}; // Initialize the parent as an object if not already
            }

            acc[parentQuestion][questionNumber] = convertedAns; // Add sub-question under the parent
          } else {
            // It's a main question
            if (
              acc[parentQuestion] &&
              typeof acc[parentQuestion] === "object"
            ) {
              // If parent question is already an object (due to sub-questions), don't overwrite it
              acc[parentQuestion][questionNumber] = answer; // Add the main question directly under the parent
            } else {
              acc[questionNumber] = convertedAns; // Otherwise, directly set the main question's answer
            }
          }

          return acc;
        }, {});

        // Build the student object
        const student = {
          prn: feedback.collegePRNNo,
          name: feedback.candidateFullName,
          uId: feedback.uId,
          submissionStatus: feedback.createdAt || null, // Handle if submission status is null
        };
        // Merge the responses dynamically into the student object
        allQuestions?.forEach((question) => {
          if (question?.question?.length > 0) {
            // If sub-questions exist and there are no responses, populate null for each sub-question
            student[question.questionNumber] =
              responses[question.questionNumber] ||
              createEmptySubQuestions(question);
          } else {
            // If no sub-questions, just add the response or null
            student[question.questionNumber] =
              responses[question.questionNumber] || null;
          }
        });

        return student;
      });
      return studentsRes;
    },
    facultyWiseResponseGenerated(allQuestions) {
      const createEmptySubQuestions = (question) => {
        if (question?.question?.length > 0) {
          return question?.question?.reduce((acc, subQuestion) => {
            acc[subQuestion.questionNumber] = null;
            return acc;
          }, {});
        }
        return null;
      };

      let facultyRes = this.feedbacksResponse.map((feedback) => {
        const responses = feedback.studentResponse.reduce((acc, response) => {
          const { questionNumber, answer } = response;

          // Check if it's a sub-question (e.g., Q2.1)
          const parentQuestion = questionNumber.split(".")[0]; // Extract parent (e.g., Q2 from Q2.1)

          if (questionNumber.includes(".")) {
            // It's a sub-question
            if (typeof acc[parentQuestion] !== "object") {
              acc[parentQuestion] = {}; // Initialize the parent as an object if not already
            }
            acc[parentQuestion][questionNumber] = answer; // Add sub-question under the parent
          } else {
            // It's a main question
            if (
              acc[parentQuestion] &&
              typeof acc[parentQuestion] === "object"
            ) {
              // If parent question is already an object (due to sub-questions), don't overwrite it
              acc[parentQuestion][questionNumber] = answer; // Add the main question directly under the parent
            } else {
              acc[questionNumber] = answer; // Otherwise, directly set the main question's answer
            }
          }

          return acc;
        }, {});

        // Build the student object
        const student = {
          name: feedback.fullName,
          uId: feedback.uId,
          submissionStatus: feedback.createdAt || null, // Handle if submission status is null
        };

        // Merge the responses dynamically into the student object
        allQuestions.forEach((question) => {
          if (question?.question?.length > 0) {
            // If sub-questions exist and there are no responses, populate null for each sub-question
            student[question.questionNumber] =
              responses[question.questionNumber] ||
              createEmptySubQuestions(question);
          } else {
            // If no sub-questions, just add the response or null
            student[question.questionNumber] =
              responses[question.questionNumber] || null;
          }
        });

        return student;
      });
      return facultyRes;
    },
    createEmptyQuestionResponse(allQuestions) {
      const emptyResponse = {};

      allQuestions?.forEach((question) => {
        if (question?.question?.length > 0) {
          // If there are sub-questions, create an object for them
          emptyResponse[question.questionNumber] = question.question.reduce(
            (acc, subQuestion) => {
              acc[subQuestion.questionNumber] = null; // Set each sub-question to null
              return acc;
            },
            {}
          );
        } else {
          // If no sub-questions, just set the main question's response to null
          emptyResponse[question.questionNumber] = null;
        }
      });

      return emptyResponse;
    },
    studentResponse(allQuestions) {
      let students = [];
      if (this.createdFeedback.formType === "Course Feedback") {
        let subjectId = this.allQuestions[this.selectedVal].subjectId;

        students = this.students.filter((obj) =>
          obj.subjects.includes(subjectId)
        );
      } else {
        students = this.students;
      }

      const studentData = students.map((student) => ({
        prn: student.collegePRNNo,
        name: student.studentName,
        uId: student.uId,
        submissionStatus: null, // No submission status if no feedback responses
        ...this.createEmptyQuestionResponse(allQuestions), // Empty responses for each question
      }));
      return studentData;
    },
    facultytResponse(allQuestions) {
      const studentData = this.students.map((student) => ({
        name: student.fullName,
        uId: student.uId,
        submissionStatus: null, // No submission status if no feedback responses
        ...this.createEmptyQuestionResponse(allQuestions), // Empty responses for each question
      }));
      return studentData;
    },
  },
};
</script>

<style src="./activityResponses.css" scoped></style>

<template src="./examQuestions.html"></template>

<script>
import showStatus from "../../../NetworkManager/showStatus";
import NewExamRepository from "../../../Repository/NewExam";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion";
import SubjectRepository from "../../../Repository/Subject";
import NbaRepository from "../../../Repository/NBA";
import { v4 } from "uuid";
import TextTag from "../../../Components/styleGuide/TextTag.vue";

export default {
  name: "examQuestions",
  props: [
    // 'prop_department',
    // 'prop_course',
    "prop_selectedSubject",
    "prop_selectedSubjectId",
    "prop_tabNumber",
    "prop_allSubjects",
    "prop_subject",
    "prop_examTagDetails",
    // 'prop_typeDivGrpBatch',
    // 'prop_selectedDivision',
    // 'prop_selectedBatch',
    // 'prop_selectedGroup',
    // 'prop_selectedSemester',
    // 'prop_selectedFaculty'
  ],
  components: {
    TextTag,
  },
  data() {
    return {
      blue: "#050D92",
      backgroundColor: "#E7E7FFCF",
      orange: "#FF4F1F",
      black: "#000000",
      downArrow: "mdi-chevron-down",
      upArrow: "mdi-arrow-up",
      examsLoaded: true,
      isMobileView: false,
      department: "Test Department",
      examTypes: ["Assignment"], //'Theory', 'Online'
      examType: "",
      subjectNames: [],
      secondaryTabItems: ["Question Paper"], //, 'Rubrics', 'Model Answers'
      tabs: 0,
      tabs1: 0,
      tabs2: 0,
      search: "",
      selectedTab: 0,
      examName: "",
      descriptionOfExam: "",
      totalMarks: "",
      passingMarks: "",
      thresholdMarks: "",
      dateOfPublication: null,
      dateOfCompletion: null,
      timeOfPublication: null,
      timeOfCompletion: null,
      bufferEndDate: null,
      dopmodel: false,
      docmodel: false,
      topmodel: false,
      tocmodel: false,
      btaedmodel: false,
      showCanvasDialog: false,
      canvasContainer: null,
      showAttachment: false,
      mainCard: null,
      canvas: null,
      remarkForLateSubmission: "",
      createdQuestions: null,
      startDate: "",
      fileType: [],
      allFileTypes: ["Images", "PDF", "Audio", "Video"],
      menu2: false,
      calenderIcon: "mdi-calendar-month-outline",
      attainmentCheckbox: false,
      questionsCheckbox: false,
      rubricCheckbox: false,
      selectedItem: "",
      isForQuestionsTab: false,
      switch1: false,
      setsToCreate: [],
      createdSets: [
        {
          no: 1,
          setName: "set 1",
        },
      ],
      numberOfSets: 0,
      setsObjectForQuestions: {
        "set 1": [],
      },
      count: 0,
      uploadQuestionExcelDialog: false,
      uploadQuestionViseMarksViaExcel: false,
      quizQuestionExcelFile: null,
      questionMarksExcelFile: null,
      questionString: "",
      questionMarks: null,
      exam: null,
      examQuestions: [],
      isSetNameEmpty: false,
      activeSet: {
        no: 1,
        setName: "set 1",
      },
      progressIndicator1: 0,
      loading: false,
      newTempTab:
        this.questionsCheckbox || this.rubricCheckbox
          ? "Questions"
          : "Model Answers",
      mainTabNames: [
        "Basic Details",
        this.newTempTab,
        "Student Marks",
        "Insights",
      ],
      rules: {
        required: (value) => !!value || "This field is required",
        totalMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
        passingMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
      },
      date: "",
      subjectIds: [],
      questionsArray: [],
      sectionDialog: false,
      sectionType: "",
      createSetDialog: false,
      students: [],
      openQuestionsPreview: false,
      questionDialogue: false,
      orQuestionDialogue: false,
      subQuestionDialogue: false,
      sectionNo: 0,
      questionNo: 0,
      subQuestionNo: 0,
      questionItem: "",
      allQuestionObjectArray: [],
      arrayOfQuestionsToUpdate: [],
      selectedRadio: null,
      questionToGiveMarks: [],
      allQuestions: [],
      questionsValidationSuccess: true,
      seperatedSetQuestions: [],
      allSections: [],
      studentData: {},
      questionWiseMarksDialog: false,
      nbaData: [],
      sectionsOfSelectedSet: [],
      studentMarksArrayToUpdate: [],
      subQnIndex: null,
      questionWiseMarksExcelExtraMarks: [],
      excelErrors: [],
      schema: {},
      uploadOverallMarksViaExcel: false,
      uploadSectionMarksViaExcel: false,
      overallBlooms: [],
      overallCos: [],
      selectedCoType: null,
      selectedBloomType: null,
      attachmentFiles: [],
      subjectObjectiveFileLink: [],
      // setNameForQuestionStoring: 'set 1',
      allBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)",
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)",
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)",
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)",
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)",
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)",
        },
      ],
      bloomsNames: [],
      coNames: [],
      overallCosBloomsData: false,
      headers: [
        {
          text: "Sections",
          value: "sectionNames",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "Qn No",
          value: "questionNumber",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "Sub Qn No",
          value: "subQnNo",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "Question Statements and Section Names   ",
          value: "question",
          sortable: false,
          width: "400px",
          // minwidth: "300px",
          // minWidth: "30%",
          align: "center",
        },
        {
          text: "Marks",
          value: "questionMarks",
          width: "100px",
          align: "center",
        },

        {
          text: "",
          value: "delete",
          sortable: false,
          width: "5%",
          align: "left",
        },
      ],
      courseOutcomeData: [],
    };
  },
  async created() {
    for (let i = 0; i < this.allBlooms?.length; i++) {
      this.bloomsNames.push(this.allBlooms[i].name);
    }
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.examSubjectAndQuestionRepositoryInstance =
      new ExamSubjectAndQuestionRepository(this);
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.examId = this.$store.getters["liveData/get_examId"];
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.getExam();
      await this.getSubjectData();
      await this.fetchAssignmentQuestions();
      this.nbaData = await this.nbaRepositoryInstance.getCos({
        lmsInstituteId: this.selectedInstitute,
        semesterId: this.selectedSemester.semId,
        subjectId: this.prop_selectedSubjectId,
      });
      for (let i = 0; i < this.nbaData.data.length; i++) {
        this.coNames.push(this.nbaData.data[i].CONumber);
      }
      if (Array.isArray(this.nbaData.data)) {
        this.nbaData.data.forEach((nba, i) => {
          this.courseOutcomeData.push({
            Id: nba._id,
            coNumber: nba.CONumber,
            coName: nba.COName,
            coText: `CO ${nba.CONumber} - ${nba.COName}`,
          });
          this.sortByNumericValueInAscendingOrder(
            this.courseOutcomeData,
            "coNumber"
          );
          // this.coNames.push(nba.COName);
          // this.coNumbers.push(nba.CONumber);
        });
      }
    },
    sortByNumericValueInAscendingOrder(array, fieldToSortBy) {
      array.sort((a, b) => a[fieldToSortBy] - b[fieldToSortBy]);
      return array;
    },
    async getExam() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          examId: this.examId,
        };
        let res = await this.newExamRepositoryInstance.getExam(objToPush);
        this.exam = res.exam;
        if (
          this.exam.settings.isForAttainment === true &&
          !this.headers.some((item) => item.value === "cos")
        ) {
          this.headers.splice(
            5,
            0,
            {
              text: "CO’s mapped",
              value: "cos",
              sortable: false,
              width: "10px",
              maxWidth: "10px",
              align: "center",
            },
            {
              text: "Blooms Mapped",
              value: "blooms",
              sortable: false,
              width: "15%",
              align: "left",
            }
          );
        }
        // this.exam = res
      } catch (error) {
        console.error("error", error);
      }
    },
    async getSubjectData() {
      let response =
        await this.examSubjectAndQuestionRepositoryInstance.getExamMultipleSubjectQuestions(
          {
            instituteId: this.selectedInstitute,
            examId: this.examId,
            subjectIds: this.exam.subjectIds,
          }
        );
      this.selectedSubjects = response.result;
      let subject = this.selectedSubjects.find(
        (subject) => subject.subjectId === this.prop_selectedSubjectId
      );
      this.totalMarks = subject.totalMarks;
    },
    rowClass(item) {
      if (item.sectionNames) {
        return "sectionBackgrond";
      }
    },
    fieldBackgroundColors(item) {
      if (
        item.questionNumber === "OR" ||
        item.subQnNo === "OR" ||
        item.questionNumberAny
      ) {
      } else {
        return "#9205050D";
      }
    },
    async fetchAssignmentQuestions() {
      if (this.exam !== null) {
        const assignmentQuestions =
          await this.examSubjectAndQuestionRepositoryInstance.getExamSubjectQuestions(
            {
              instituteId: this.selectedInstitute,
              examId: this.examId,
              subjectId: this.prop_selectedSubjectId,
            }
          );
        this.createdQuestions = assignmentQuestions.result;
        if (
          this.createdQuestions.overallCOs?.length > 0 ||
          this.createdQuestions.overallBlooms?.length > 0
        ) {
          this.row = "radio-1";
          this.overallCosBloomsData = true;
        }
        this.overallCos = this.createdQuestions.overallCOs;
        this.overallBlooms = this.createdQuestions.overallBlooms;
      }
      if (this.createdQuestions?.allQuestions?.length > 0) {
        this.getAllSections();
        this.activeSet = {
          no: 1,
          setName: "set 1",
        };
        this.setActiveSet(this.activeSet);
        this.createdSets = [];
        this.setsObjectForQuestions = {};
        for (let i = 0; i < this.createdQuestions.allQuestions?.length; i++) {
          if (this.createdQuestions.allQuestions[i].questionType === "Set") {
            const questionStatement =
              this.createdQuestions.allQuestions[i].questionStatement;
            this.createdSets.push({
              no: i + 1,
              setName: questionStatement,
            });
            if (
              !Object.prototype.hasOwnProperty.call(
                this.setsObjectForQuestions,
                questionStatement
              )
            ) {
              this.setsObjectForQuestions[questionStatement] = [];
            }
            this.setsObjectForQuestions[questionStatement].push(
              ...this.getQuestionsOfSelectedSet(
                { setName: questionStatement, forShowingQuestions: true },
                null
              )
            );
          }
        }
        this.questionsArray = this.setsObjectForQuestions["set 1"];
      }
    },
    setActiveSet(set) {
      this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray;
      this.activeSet = set;
      this.questionsArray = this.setsObjectForQuestions[this.activeSet.setName];
    },
    createSets() {
      if (this.numberOfSets > 0) {
        if (this.numberOfSets > this.setsToCreate?.length) {
          const value = this.numberOfSets - this.setsToCreate?.length;
          for (let i = 0; i < value; i++) {
            this.setsToCreate.push({
              no: this.createdSets?.length + this.setsToCreate?.length + 1,
              setName: "",
            });
          }
        } else {
          const arr = [];
          for (let i = 0; i < this.numberOfSets; i++) {
            arr.push(this.setsToCreate[i]);
          }
          this.setsToCreate = arr;
        }
      }
    },
    saveSets() {
      this.isSetNameEmpty = false;
      this.setsToCreate.forEach((set) => {
        if (set.setName.trim() === "") this.isSetNameEmpty = true;
      });
      if (!this.isSetNameEmpty) {
        this.createSetDialog = false;
        this.createdSets.push(...this.setsToCreate);
      }
      for (let i = 0; i < this.setsToCreate?.length; i++) {
        this.setsObjectForQuestions[this.setsToCreate[i].setName] = [];
      }
    },
    async showQuestions() {
      await this.fetchAssignmentQuestions();
      if (this.createdQuestions !== null) {
        this.openQuestionsPreview = true;
      } else {
        showStatus(
          "Questions are not added yet for this exam",
          9000,
          "warning",
          this
        );
      }
    },
    deleteQuestion(index) {
      if (index >= 0 && index < this.questionsArray.length) {
        if (this.questionsArray[index].sectionNames) {
          this.questionsArray.splice(index, 1);
          for (let i = index; i < this.questionsArray.length; i++) {
            if (this.questionsArray[i].sectionNames) {
              break;
            } else {
              this.questionsArray.splice(i, 1);
              i--;
            }
          }
        } else if (this.questionsArray[index].questionNumber) {
          this.questionsArray.splice(index, 1);
          for (let i = index; i < this.questionsArray.length; i++) {
            if (
              this.questionsArray[i].questionNumber ||
              this.questionsArray[i].sectionNames
            ) {
              break;
            } else {
              // const qnNo = this.questionsArray[i].questionNumber
              this.questionsArray.splice(i, 1);
              // this.questionsArray[i].questionNumber = qnNo
              i--;
            }
          }
        } else if (this.questionsArray[index].subQnNo) {
          this.questionsArray.splice(index, 1);
          for (let i = index; i < this.questionsArray.length; i++) {
            if (this.questionsArray[i].subQnNo) {
              break;
            } else {
              this.questionsArray.splice(i, 1);
              i--;
            }
          }
        }
      }
      this.resetQueNo();
    },
    resetQueNo() {
      let secNo = 1;
      let qnNo = 1;
      let sQnNo = 1;
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i].sectionNames) {
          qnNo = 1;
          sQnNo = 1;
          this.questionsArray[i].questionNo = "Section" + secNo;
          this.questionsArray[i].sectionNames = "Section" + secNo;
          secNo++;
        } else if (
          this.questionsArray[i].questionNumber &&
          this.questionsArray[i].questionNumber !== "OR"
        ) {
          sQnNo = 1;
          this.questionsArray[i].questionNo = "Q" + qnNo;
          this.questionsArray[i].questionNumber = "Q" + qnNo;
          qnNo++;
        } else if (
          this.questionsArray[i].subQnNo &&
          this.questionsArray[i].subQnNo !== "OR" &&
          !this.questionsArray[i].questionNumberAny &&
          this.questionsArray[i].subQnNo.slice(0, 3) !== "Any"
        ) {
          this.questionsArray[i].subQnNo = "Q" + (qnNo - 1) + "." + sQnNo;
          sQnNo++;
        }
      }
    },
    addSection() {
      let sectionNames = ++this.sectionNo;
      for (let i = this.questionsArray?.length - 1; i >= 0; i--) {
        if (this.questionsArray[i]?.sectionNames) {
          sectionNames =
            this.questionsArray[i].sectionNames[
              this.questionsArray[i].sectionNames.length - 1
            ] -
            "" +
            1;
        }
      }
      this.questionNo = 0;
      const quesObject = {
        sectionNames: "Section" + sectionNames,
        question: "",
        sectionId: v4(),
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames,
      };
      this.questionsArray.push(quesObject);
      this.resetQueNo();
    },
    addAnyTypeQuestion() {
      const quesObject = {
        questionNumber: "Q" + ++this.questionNo,
        questionForSection: this.questionItem,
        anyCount: null,
        outOfCount: null,
        questionId: v4(),
        question: "Solve any_out of",
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames,
      };
      let foundSec = false;
      let breaked = false;
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.sectionNames === this.questionItem) {
          foundSec = true;
        } else if (
          this.questionsArray[i].sectionNames &&
          this.questionsArray[i].sectionNames !== this.questionItem &&
          foundSec
        ) {
          this.questionsArray.splice(i, 0, quesObject);
          breaked = true;
          break;
        }
      }
      if (!breaked) {
        this.questionsArray.push(quesObject);
      }
      // this.questionsArray.push(quesObject)
      this.resetQueNo();
    },
    dummy(item, index) {
      if (item.outOfCount && item.anyCount && item.questionMarks) {
        this.addSubquestionsOfAny(
          item.outOfCount,
          item.anyCount,
          item.questionMarks,
          index
        );
      }
    },
    addSubquestionsOfAny(outOfCount, anyCount, questionMarks, index) {
      const qMarks = questionMarks / anyCount;
      const quesObject = {
        subQnNo: "Any " + anyCount,
        anyQuestionId: v4(),
        questionNumberAny: "Any " + outOfCount,
        // questionNumber: 'Any ' + anyCount
      };
      this.questionsArray.splice(index, 0, quesObject);
      index++;

      for (let i = index; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.questionNo) {
          break;
        } else if (
          this.questionsArray[i].anyQuestionId ||
          this.questionsArray[i].anySubQuestionId
        ) {
          this.questionsArray.splice(i, 1);
          i--;
        }
      }

      // this.questionsArray.push(quesObject)
      let subQueCount = 0;
      for (let i = 0; i < outOfCount; i++) {
        // index++
        const obj = {
          question: "",
          anySubQuestionId: v4(),
          anyCount: anyCount,
          subQnNo: "Q" + this.questionNo + "." + ++subQueCount,
          anyQue: true,
          questionMarks: qMarks,
          cos: this.coNames,
          blooms: this.bloomsNames,
        };
        this.questionsArray.splice(index, 0, obj);
        this.resetQueNo();
      }
    },
    addNormalQuestion() {
      this.subQuestionNo = 0;
      const quesObject = {
        questionNumber: "Q" + ++this.questionNo,
        questionForSection: this.questionItem,
        question: "",
        questionId: v4(),
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames,
      };
      let foundSec = false;
      let breaked = false;
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.sectionNames === this.questionItem) {
          foundSec = true;
        } else if (
          this.questionsArray[i].sectionNames &&
          this.questionsArray[i].sectionNames !== this.questionItem &&
          foundSec
        ) {
          this.questionsArray.splice(i, 0, quesObject);
          breaked = true;
          break;
        }
      }
      if (!breaked) {
        this.questionsArray.push(quesObject);
      }
      // for (let i = 0; i < this.allQuestionObjectArray.length; i++) {
      //   if (this.allQuestionObjectArray[i][this.questionItem]) {
      //     this.allQuestionObjectArray[i][this.questionItem].push(quesObject)
      //   }
      // }
      // this.arrayOfQuestionsToUpdate = []
      // for (let j = 0; j < this.allQuestionObjectArray.length; j++) {

      // }
      // const sectionNames = Object.keys(this.allQuestionObjectArray)
      // for (let i = 0; i < sectionNames.length; i++) {
      //   this.questionsArray.push()
      // }
      // this.questionsArray.push(quesObject)
      this.resetQueNo();
    },
    addOrQuestion(nextIndex) {
      this.subQuestionNo = 0;
      const quesObject = {
        questionNumber: "Q" + ++this.questionNo,
        question: "",
        questionId: v4(),
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames,
      };
      // quesObject.questionNumber = this.questionsArray[this.questionItemIndex] ? 'Q' + (parseInt(this.questionsArray[this.questionItemIndex - 2]?.questionNumber[this.questionsArray[this.questionItemIndex - 2].questionNumber.length - 1]) + 1) : 'Q' + ++this.questionNo
      // this.questionsArray.splice(this.questionItemIndex, 0, quesObject)
      // for (let i = this.questionItemIndex + 1; i < this.questionsArray.length; i++) {
      //   const previousQuestion = this.questionsArray[i - 1];
      //   const currentQuestion = this.questionsArray[i];

      //   if (previousQuestion?.questionNumber === 'OR') {
      //     this.questionNo = parseInt(this.questionsArray[i - 2]?.questionNumber.slice(-1)) + 1;
      //     currentQuestion.questionNumber = 'Q' + (isNaN(this.questionNo) ? 1 : this.questionNo);
      //   } else {
      //     this.questionNo = parseInt(previousQuestion?.questionNumber.slice(-1)) + 1;
      //     currentQuestion.questionNumber = 'Q' + (isNaN(this.questionNo) ? 1 : this.questionNo);
      //   }
      // }
      // this.questionsArray.splice(this.questionItemIndex + 1, 0, quesObject)
      // this.questionsArray.push(quesObject)
      if (nextIndex) this.questionsArray.splice(nextIndex + 1, 0, quesObject);
      else this.questionsArray.push(quesObject);
      this.resetQueNo();
    },
    addInOrQuestion() {
      const quesObject = {
        questionNumber: "OR",
        questionId: v4(),
      };

      let nextIndex = null;
      let isQuestionFound = false;
      for (
        let i = this.questionItemIndex;
        i < this.questionsArray.length;
        i++
      ) {
        if (this.questionsArray[i].questionNumber) {
          this.questionsArray.splice(i, 0, quesObject);
          isQuestionFound = true;
          nextIndex = i;
          break;
        } else if (this.questionsArray[i].sectionNames) {
          this.questionsArray.splice(i - 1, 0, quesObject);
          isQuestionFound = true;
          nextIndex = i;
          break;
        }
      }
      if (!isQuestionFound) {
        this.questionsArray.push(quesObject);
      }

      // this.questionsArray.splice(this.questionItemIndex, 0, quesObject)
      this.resetQueNo();
      // this.questionsArray.push(quesObject)
      this.addOrQuestion(nextIndex);
    },
    addSubQuestion() {
      const quesObject = {
        question: "",
        subQuestionId: v4(),
        subQnNo: "Q" + this.questionNo + "." + ++this.subQuestionNo,
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames,
      };
      let foundQn = false;
      let breaked = false;
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (
          this.questionsArray[i]?.questionNo === this.questionItem &&
          i === this.questionItemIndex - 1
        ) {
          foundQn = true;
        } else if (
          (this.questionsArray[i].questionNo &&
            this.questionsArray[i].questionNo !== this.questionItem &&
            foundQn) ||
          (this.questionsArray[i].questionNumber && foundQn)
        ) {
          this.questionsArray.splice(i, 0, quesObject);
          breaked = true;
          break;
        }
      }
      if (!breaked) {
        this.questionsArray.push(quesObject);
      }
      // this.questionsArray.splice(this.questionItemIndex, 0, quesObject)
      this.resetQueNo();
    },
    addOrSubQuestion() {
      const quesObject = {
        subQnNo: "OR",
        subQuestionId: v4(),
      };
      this.questionsArray.splice(this.subQnIndex, 0, quesObject);
      const quesObject2 = {
        question: "",
        subQuestionId: v4(),
        subQnNo: "Q" + this.questionNo + "." + ++this.subQuestionNo,
        questionMarks: null,
        cos: this.coNames,
        blooms: this.bloomsNames,
      };
      this.questionsArray.splice(this.subQnIndex + 1, 0, quesObject2);
      // this.questionsArray.push(quesObject)
      // this.addSubQuestion()
      this.resetQueNo();
    },
    finalSetQuestions() {
      this.allQuestions = [];
      this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray;
      const sets = Object.keys(this.setsObjectForQuestions);
      for (let i = 0; i < sets.length; i++) {
        this.questionsArray = this.setsObjectForQuestions[sets[i]];
        let isBreaked = false;
        for (let j = 0; j < this.questionsArray.length; j++) {
          if (
            this.questionsArray[j]?.question === "" ||
            this.questionsArray[j]?.questionMarks === ""
          ) {
            isBreaked = true;
            showStatus(
              "Enter question statement or marks in " +
                (this.questionsArray[j].sectionNames
                  ? this.questionsArray[j].sectionNames
                  : this.questionsArray[j].questionNumber
                  ? this.questionsArray[j].questionNumber
                  : this.questionsArray[j].subQnNo),
              5000,
              "error",
              this
            );
            break;
          }
        }
        if (isBreaked) {
          this.questionsValidationSuccess = false;
          break;
        } else {
          this.questionsValidationSuccess = true;
          this.validateSectionWiseMarks(sets[i]);
        }
      }
      if (this.questionsValidationSuccess) {
        this.finalQuestionsSave();
      }
    },
    async finalQuestionsSave() {
      try {
        const objToPass = {
          subjectId: this.prop_selectedSubjectId,
          examId: this.examId,
          instituteId: this.selectedInstitute,
          marks: this.totalMarks,
          allQuestionsArray: this.allQuestions || [],
        };
        // this.questionsCheckbox ? objToPass.allQuestionsArray = this.allQuestions : objToPass.allQuestionsArray = []
        const data =
          await this.examSubjectAndQuestionRepositoryInstance.parseExcel(
            objToPass
          );
        if (data)
          showStatus(
            "Questions successfully added in exam.",
            2000,
            "success",
            this
          );
        this.prop_allSubjects.forEach((subject) => {});
      } catch (error) {
        console.error(error);
      }
    },
    validateSectionWiseMarks(set) {
      let totalSectionMarks = 0;
      if (this.questionsArray.length > 0) {
        for (let i = 0; i < this.questionsArray.length; i++) {
          if (this.questionsArray[i]?.sectionNames) {
            totalSectionMarks += this.questionsArray[i].questionMarks - "";
          }
        }
        if (totalSectionMarks !== this.totalMarks) {
          showStatus(
            "Section marks should be equal to the total marks of exam",
            5000,
            "error",
            this
          );
          this.questionsValidationSuccess = false;
        } else {
          this.validateQuestionUnderSectionWiseMarks(set);
        }
      } else {
        showStatus("Questions are not added yet", 5000, "error", this);
        this.questionsValidationSuccess = false;
      }
    },
    validateQuestionUnderSectionWiseMarks(set) {
      let totalQuestionUnderSectionMarks = 0;
      let sectionMarks = 0;
      let areQuestionPresent = false;
      let checkNext = true;
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.sectionNames) {
          if (
            totalQuestionUnderSectionMarks !== sectionMarks &&
            areQuestionPresent
          ) {
            showStatus(
              `Total questions under ${this.questionsArray[i].sectionNames} should be equal to the marks assigned to it`,
              5000,
              "error",
              this
            );
            checkNext = false;
            this.questionsValidationSuccess = false;
            break;
          }
          sectionMarks = this.questionsArray[i].questionMarks - "";
          totalQuestionUnderSectionMarks = 0;
          areQuestionPresent = false;
        } else if (
          this.questionsArray[i]?.questionNumber &&
          this.questionsArray[i]?.questionNumber !== "OR"
        ) {
          totalQuestionUnderSectionMarks +=
            this.questionsArray[i].questionMarks - "";
          areQuestionPresent = true;
        } else if (
          this.questionsArray[i]?.questionNumber &&
          this.questionsArray[i]?.questionNumber === "OR"
        ) {
          totalQuestionUnderSectionMarks -=
            this.questionsArray[i + 1].questionMarks - "";
        }
      }
      if (
        totalQuestionUnderSectionMarks !== sectionMarks &&
        areQuestionPresent
      ) {
        checkNext = false;
        showStatus(
          `Total questions under section should be equal to the marks assigned to it`,
          5000,
          "error",
          this
        );
        checkNext = false;
        this.questionsValidationSuccess = false;
      }
      if (checkNext) {
        this.validateSubQUestionUnderQuestionUnderSectionWiseMarks(set);
      }
    },
    validateSubQUestionUnderQuestionUnderSectionWiseMarks(set) {
      let totalSubQuestionUnderQuestionUnderSectionMarks = 0;
      let questionMarks = 0;
      let areSubQuestionsAvailable = false;
      let saveQuestions = true;
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (
          this.questionsArray[i]?.questionNumber &&
          this.questionsArray[i]?.questionNumber !== "OR"
        ) {
          if (
            totalSubQuestionUnderQuestionUnderSectionMarks !== questionMarks &&
            areSubQuestionsAvailable
          ) {
            showStatus(
              `Total sub questions marks under ${this.questionsArray[i].question} should be equal to the marks assigned to it`,
              5000,
              "error",
              this
            );
            saveQuestions = false;
            this.questionsValidationSuccess = false;
            break;
          }
          questionMarks = this.questionsArray[i].questionMarks - "";
          totalSubQuestionUnderQuestionUnderSectionMarks = 0;
          areSubQuestionsAvailable = false;
        } else if (
          this.questionsArray[i]?.subQnNo &&
          this.questionsArray[i]?.subQnNo !== "OR" &&
          !this.questionsArray[i]?.questionNumberAny &&
          !this.questionsArray[i]?.anySubQuestionId &&
          !this.questionsArray[i].anyStatement
        ) {
          totalSubQuestionUnderQuestionUnderSectionMarks +=
            this.questionsArray[i].questionMarks - "";
          areSubQuestionsAvailable = true;
        } else if (
          this.questionsArray[i]?.subQnNo &&
          this.questionsArray[i]?.subQnNo === "OR"
        ) {
          totalSubQuestionUnderQuestionUnderSectionMarks -=
            this.questionsArray[i + 1].questionMarks - "";
        }
      }
      if (
        totalSubQuestionUnderQuestionUnderSectionMarks !== questionMarks &&
        areSubQuestionsAvailable
      ) {
        saveQuestions = false;
        showStatus(
          `Total sub questions marks under question should be equal to the marks assigned to it`,
          5000,
          "error",
          this
        );
        saveQuestions = false;
        this.questionsValidationSuccess = false;
      }
      if (saveQuestions) {
        this.saveQuestions(set);
      }
    },
    isFloatingNumber(value) {
      return typeof value === "number" && value % 1 !== 0;
    },
    async saveQuestions(set) {
      this.questionToGiveMarks = this.questionsArray;
      this.allQuestions.push({
        setName: set,
      });
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i].sectionNames) {
          this.allQuestions.push({
            "Section No.": this.questionsArray[i].sectionNames,
            Section: this.questionsArray[i].question,
            Marks: this.questionsArray[i].questionMarks,
            Cos: this.questionsArray[i].selectedCos,
            Blooms: this.questionsArray[i].selectedBlooms,
            questionId: this.questionsArray[i].questionId,
          });
          continue;
        }
        if (
          this.questionsArray[i].questionNumber &&
          this.questionsArray[i].questionNumber !== "OR"
        ) {
          this.allQuestions.push({
            "Question No.": this.questionsArray[i].questionNumber,
            Question: this.questionsArray[i].question,
            Marks: this.questionsArray[i].questionMarks,
            Cos: this.questionsArray[i].selectedCos,
            Blooms: this.questionsArray[i].selectedBlooms,
            questionId: this.questionsArray[i].questionId,
          });
          continue;
        }
        if (
          this.questionsArray[i].questionNumber &&
          this.questionsArray[i].questionNumber === "OR"
        ) {
          this.allQuestions.push({
            "Question No.": "OR",
          });
          continue;
        }
        if (
          this.questionsArray[i].subQnNo &&
          this.questionsArray[i].subQnNo !== "OR"
        ) {
          this.allQuestions.push({
            "Sub-Question No.": this.questionsArray[i].subQnNo,
            "Sub-Question": this.questionsArray[i].question,
            Marks: this.isFloatingNumber(this.questionsArray[i].questionMarks)
              ? parseFloat(this.questionsArray[i].questionMarks.toFixed(2))
              : this.questionsArray[i].questionMarks,
            Cos: this.questionsArray[i].selectedCos,
            Blooms: this.questionsArray[i].selectedBlooms,
            questionId: this.questionsArray[i].questionId,
          });
          continue;
        }
        if (
          this.questionsArray[i].subQnNo &&
          this.questionsArray[i].subQnNo === "OR"
        ) {
          this.allQuestions.push({
            "Sub-Question No.": "OR",
          });
          continue;
        }
        if (this.questionsArray[i].questionNumberAny) {
          this.allQuestions.push({
            "Question No.": this.questionsArray[i].questionNumberAny,
          });
          continue;
        }
      }
    },
    getAllSections() {
      this.allSections = [];
      for (let i = 0; i < this.createdQuestions?.allQuestions.length; i++) {
        if (this.createdQuestions?.allQuestions[i].questionType === "Set") {
          for (
            let j = 0;
            j < this.createdQuestions.allQuestions[i].question.length;
            j++
          ) {
            this.allSections.push({
              questionId:
                this.createdQuestions.allQuestions[i].question[j].questionId,
              sectionName:
                this.createdQuestions.allQuestions[i].question[j]
                  .questionStatement,
              obtainedMarks: null,
              questionMarks:
                this.createdQuestions.allQuestions[i].question[j].questionMarks,
            });
          }
        }
      }
      for (let i = 0; i < this.students.length; i++) {
        this.students[i].allSections = this.allSections;
      }
    },
    getQuestionsOfSelectedSet(set, item) {
      this.studentData = item;
      let anyQuestionFound = false;
      let setQuestions = [];
      for (let i = 0; i < this.createdQuestions.allQuestions.length; i++) {
        if (
          this.createdQuestions.allQuestions[i].questionType === "Set" &&
          this.createdQuestions.allQuestions[i].questionStatement ===
            set.setName
        ) {
          setQuestions = this.createdQuestions.allQuestions[i].question;
        }
      }
      this.seperatedSetQuestions = [];

      for (let i = 0; i < setQuestions?.length; i++) {
        anyQuestionFound = false;
        this.seperatedSetQuestions.push({
          sectionNames: setQuestions[i].questionNumber,
          question: setQuestions[i].questionStatement,
          questionId: setQuestions[i].questionId,
          questionMarks: setQuestions[i].questionMarks,
          selectedCos: setQuestions[i]?.cos,
          cos: this.coNames,
          selectedBlooms: setQuestions[i].blooms,
          blooms: this.bloomsNames,
        });
        if (setQuestions[i].question?.length > 0) {
          const sectionQuestion = setQuestions[i].question;
          for (let j = 0; j < sectionQuestion?.length; j++) {
            if (sectionQuestion[j].questionType === "OrQuestion") {
              anyQuestionFound = false;
              this.seperatedSetQuestions.push({
                questionNumber: "OR",
              });
            }
            anyQuestionFound = false;
            this.seperatedSetQuestions.push({
              questionNumber: sectionQuestion[j]?.questionNumber,
              question:
                sectionQuestion[j]?.questionStatement === "Solve any_out of"
                  ? "Solve any (" +
                    sectionQuestion[j].NumberOfQuestionsToSolve +
                    ")"
                  : sectionQuestion[j].questionStatement,
              tryl: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              anyCount: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              outOfCount: sectionQuestion[j]?.question?.length,
              solveAnyCount:
                sectionQuestion[j]?.questionStatement === "Solve any_out of"
                  ? 0
                  : sectionQuestion[j]?.NumberOfQuestionsToSolve,
              questionId: sectionQuestion[j]?.questionId,
              questionMarks: sectionQuestion[j]?.questionMarks,
              selectedCos: sectionQuestion[j]?.cos,
              cos: this.coNames,
              selectedBlooms: sectionQuestion[j]?.blooms,
              blooms: this.bloomsNames,
            });
            if (sectionQuestion[j].NumberOfQuestionsToSolve) {
              anyQuestionFound = true;
              this.seperatedSetQuestions.push({
                subQnNo: "Any " + sectionQuestion[j].NumberOfQuestionsToSolve,
                anyCount: sectionQuestion[j].NumberOfQuestionsToSolve,
                anyStatement: true,
              });
            }
            if (sectionQuestion[j]?.question?.length > 0) {
              const subQuestion = sectionQuestion[j]?.question;
              for (let k = 0; k < subQuestion.length; k++) {
                if (subQuestion[k].questionType === "OrSubQuestion") {
                  this.seperatedSetQuestions.push({
                    subQnNo: "OR",
                    anyStatement: false,
                  });
                }
                if (anyQuestionFound) {
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anySubQuestion: true,
                    anyStatement: true,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    selectedCos: subQuestion[k].cos,
                    cos: this.coNames,
                    selectedBlooms: subQuestion[k].blooms,
                    blooms: this.bloomsNames,
                  });
                } else {
                  anyQuestionFound = false;
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anyStatement: false,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    selectedCos: subQuestion[k].cos,
                    cos: this.coNames,
                    selectedBlooms: subQuestion[k].blooms,
                    blooms: this.bloomsNames,
                  });
                }
              }
            }
          }
        }
      }
      // this.questionWiseMarksDialog = true
      if (!set.forShowingQuestions && this.studentData?.answers) {
        const studentDataMap = new Map(
          this.studentData?.answers.map((data) => [data.questionId, data])
        );
        for (const setQuestion of this.seperatedSetQuestions) {
          const studentDataForQuestion = studentDataMap.get(
            setQuestion.questionId
          );
          if (studentDataForQuestion) {
            setQuestion.assignMarks = studentDataForQuestion.obtainedMarks;
          }
        }
        this.questionWiseMarksDialog = true;
      } else return this.seperatedSetQuestions;
    },
    getSetSections(selectedSet) {
      this.sectionsOfSelectedSet = [];
      for (
        let i = 0;
        i < this.setsObjectForQuestions[selectedSet.setName].length;
        i++
      ) {
        if (this.setsObjectForQuestions[selectedSet.setName][i].sectionNames) {
          this.sectionsOfSelectedSet.push(
            this.setsObjectForQuestions[selectedSet.setName][i].question
          );
        }
      }
    },
    goBack() {
      this.$router.push({
        name: "activityManageExamsRevamped",
        params: {
          prop_tabNumber: this.prop_tabNumber,
          // prop_department: this.prop_department,
          // prop_course: this.prop_course,
          // prop_selectedSubject: item.subjectName,
          // prop_typeDivGrpBatch: this.prop_typeDivGrpBatch,
          // prop_selectedDivision: this.prop_selectedDivision,
          // prop_selectedBatch: this.prop_selectedBatch,
          // prop_selectedGroup: this.prop_selectedGroup
        },
      });
    },
  },
};
</script>

<style src="./examQuestions.css"></style>

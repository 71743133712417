<template src="./activityFeedbackFormRevamped.html"></template>

<script>
import creationOfFeedbacks from "./activityCreationOfFeedbacks/activityCreationOfFeedbacks.vue";
import createFeedbackform from "./activityCreateFeedbackform/activityCreateFeedbackform.vue";
import FeedbackFormRepository from "../../Repository/Feedback";
import FeedbackResponseRepository from "../../Repository/FeedbackResponse";
import NbaRepository from "../../Repository/NBA";
import SemesterRepository from "../../Repository/Semester";
import SubjectRepository from "../../Repository/Subject";
import CustomSecondaryButton from "../../Components/styleGuide/Buttons/Secondary.vue";
import CustomPrimaryButton from "../../Components/styleGuide/Buttons/Button.vue";

export default {
  name: "activityFeedbackFormRevamped",
  components: {
    creationOfFeedbacks,
    createFeedbackform,
    CustomPrimaryButton,
    CustomSecondaryButton,
  },
  data() {
    return {
      backgroundColor: "#050D920D",
      textColor: "#707070",
      chevronIcon: "mdi-chevron-down",
      allLevels: ["Academic Year", "Batch"],
      batchData: [],
      allBatches: [],
      allSemesters: [],
      allSemestersArr: [],
      allAcademicYears: [],
      allCourseYears: [],
      selectedLevel: "Academic Year",
      selectedBatch: "",
      selectedBatchId: "",
      selectedSemester: {},
      selectedAcademicYear: "",
      selectedAcademicYearId: "",
      currentTab: 0,
      feedbackCount: 0,
      mainTabNames: [
        "Creation of Feedbacks",
        // "My responses",
        "Reports and Insights",
      ],
      allDepartments: [],
      topSelections: {},
      loading: false,
      allFeedacks: [],
      allDivisions: [],
      allCourses: [],
      selectedFeedbackFor: "",
      selectedTypeOfFeedback: "",
      typeOfFeedbacks: "",
      selectedDivision: [],
      selectedCourse: [],
      selectedCourseYear: "",
      selectedDepartment: "",
      feedbackSelections: {},
      allCoursesWithAllOption: [{ subjectName: "All", id: "all" }],
      feedbacksFor: [
        "Students",
        // "Faculty",
        // "Parents",
        // "Alumni",
        // "Employer/Recruiters",
        // "Guest",
        // "Specific",
      ],
      stakeHolderWiseFeedbackList: [
        {
          stackholder: "Students",
          feedbackFor: ["Course Feedback", "Program Feedback"],
        },
        {
          stackholder: "Faculty",
          feedbackFor: [
            "Program Feedback",
            // "Teachers effectiveness",
            // "Event feedback",
            "Other feedback",
          ],
        },
        {
          stackholder: "Parents",
          feedbackFor: [
            "Alumni feedback",
            "Parents feedback",
            "Event feedback",
            "Other feedback",
          ],
        },
        {
          stackholder: "Alumni",
          feedbackFor: ["Alumni feedback", "Event feedback", "Other feedback"],
        },
        {
          stackholder: "Employer/Recruiters",
          feedbackFor: [
            "Employer feedback",
            "Event feedback",
            "Other feedback",
          ],
        },
        {
          stackholder: "Guest",
          feedbackFor: [
            "Guest feedback",
            "Alumni feedback",
            "Event feedback",
            "Other feedback",
          ],
        },
        {
          stackholder: "Infrastructure feedback",
          feedbackFor: ["Event feedback", "Other feedback"],
        },
        {
          stackholder: "Specific",
          feedbackFor: [
            "Specific Students",
            "Specific Faculties",
            "Other feedback",
          ],
        },
      ],
    };
  },
  computed: {
    isAllSelected() {
      return this.selectedCourse.length === this.allCoursesWithAllOption.length;
    },
    selectAllIcon() {
      return this.isAllSelected
        ? "mdi-checkbox-marked"
        : "mdi-checkbox-blank-outline";
    },
  },
  async created() {
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.feedbackSelections =
      this.$store.getters["liveData/get_feedbackTopSelections"];
    this.feedbackFormRepositoryInstance = new FeedbackFormRepository(this);
    this.feedbackResponseRepositoryInstance = new FeedbackResponseRepository(
      this
    );
    await this.getBatches();

    let oldData = {
      _id: {
        $oid: "6401d45566de3c5a30700e35",
      },
      division: ["A"],
      nbaAttainmentLevels: ["yes", "no", "yesno"],
      questions: [
        {
          dialog: false,
          selectedCOs: [
            {
              Id: "652cc796e120c354e26f2cfa",
              coNumber: "2",
              coName:
                "Analyze the role of people, process and physical evidence in services mix",
            },
            {
              Id: "652cc7a2e120c354e26f2cfb",
              coNumber: "3",
              coName:
                "Apply the insights of segmentation, targeting, positioning and differentiation to real world offerings/e-services",
            },
          ],
          question: "What",
        },
        {
          dialog: false,
          selectedCOs: [
            {
              Id: "652cc7bdebeb88ba729188ca",
              coNumber: "5",
              coName:
                "Determine the various applications of services marketing",
            },
            {
              Id: "652cc7c8ebeb88ba729188cb",
              coNumber: "6",
              coName:
                "Design extended marketing mix for various services offerings",
            },
          ],
          question: "Which",
        },
        {
          dialog: false,
          selectedCOs: [
            {
              Id: "652cc78bebeb88ba729188c9",
              coNumber: "1",
              coName:
                "Understand basic concepts and framework of Services Marketing",
            },
          ],
          question: "who",
        },
      ],
      assignedCOArray: [],
      assignedPOArray: [],
      assignedPSOArray: [],
      instituteId: "e9f11b1c-eef3-4f90-baae-054ddc4ffb9b",
      semId: "d6788296-8776-4d93-81fc-fce41fd88314",
      department: "PGDM-Business Administration",
      courseYear: "FY 23-24",
      formType: "Course Exit Survey",
      formURL: "109462f6-5c0c-4d6b-8acf-42b90c75c0de",
      subjectId: "7b7a6a95-6e58-485e-8d4d-32fd2c8ba78e",
      subjectName: "Business Research Methods & Statistics",
      formTitle: "test 1",
      formDescription: "test 1",
      conductionDetails: "Upcoming",
      anonymous: true,
      startDate: "2024-05-24",
      endDate: "2024-05-24",
      isNBACriteriaAttainment: true,
      isPublish: true,
      outcomeMapping: "individual",
      threshold: 2,
      nbaStatus: true,
    };

    let newData = {
      _id: {
        $oid: "671781f94ef7d64ecc9df64a",
      },
      divisions: [],
      stakeHolderDetails: [
        {
          "Roll Number": "Compulsory",
        },
      ],
      status: "Published",
      courses: [
        {
          subjectId: "7b7a6a95-6e58-485e-8d4d-32fd2c8ba78e",
          subjectName: "Business Research Methods & Statistics",
          allQuestions: [
            {
              questionId: "7daa054d-23c3-47a7-81a3-1b1ae45d31fe",
              questionType: "Set",
              questionStatement: "set 1",
              question: [
                {
                  questionId: "8f0fc44f-2722-4629-800b-e92c96692503",
                  questionType: "Section",
                  questionStatement: "sec 1",
                  questionNumber: "Section1",
                  selectedCos: [],
                  selectedPos: [],
                  blooms: [],
                  question: [
                    {
                      questionId: "09fe11fb-f3aa-4332-859a-81fa64c56036",
                      questionType: "Question",
                      questionStatement: "CO1",
                      questionNumber: "Q1",
                      selectedCos: [],
                      selectedPos: [],
                      blooms: [],
                      question: [],
                      attachmentFiles: [],
                      scales: {
                        scale: [
                          {
                            scaleNumber: 1,
                            scaleLabel: "A",
                          },
                          {
                            scaleNumber: 2,
                            scaleLabel: "B",
                          },
                        ],
                        selectedType: "Single Select",
                        selectedScaleOrOption: "Scale",
                        lowestNo: "1",
                        highestNo: "2",
                      },
                    },
                    {
                      questionId: "b294280c-165e-4613-861b-801c9504f550",
                      questionType: "Question",
                      questionStatement:
                        "Understand the Levels and Types of Measurement Scales and the Scales of Measurement",
                      questionNumber: "Q2",
                      selectedCos: [],
                      selectedPos: [],
                      blooms: [],
                      question: [],
                      attachmentFiles: [],
                      scales: {
                        scale: [
                          {
                            scaleNumber: 1,
                            scaleLabel: "A",
                          },
                          {
                            scaleNumber: 2,
                            scaleLabel: "B",
                          },
                        ],
                        selectedType: "Multiple Select",
                        selectedScaleOrOption: "Options",
                        noOfOptions: "2",
                      },
                    },
                  ],
                  attachmentFiles: [],
                  scales: {
                    scale: [],
                    selectedType: "",
                    selectedScaleOrOption: "",
                  },
                },
              ],
            },
          ],
        },
        {
          subjectId: "f5f3b229-48ed-427a-8a31-6a108f18fcbe",
          subjectName: "Introduction to IT",
          allQuestions: [
            {
              questionId: "ce61c037-ec0d-4e1d-a3e7-34ee8e99012a",
              questionType: "Set",
              questionStatement: "set 1",
              question: [
                {
                  questionId: "0b64104f-c448-4cea-959d-e45f1bcb5bbf",
                  questionType: "Section",
                  questionStatement: "sec 2",
                  questionNumber: "Section1",
                  selectedCos: [],
                  selectedPos: [],
                  blooms: [],
                  question: [
                    {
                      questionId: "87b87e84-83f7-4faf-b88c-573082899f6e",
                      questionType: "Question",
                      questionStatement: "CO1",
                      questionNumber: "Q1",
                      selectedCos: [],
                      selectedPos: [],
                      blooms: [],
                      question: [],
                      attachmentFiles: [],
                      scales: {
                        scale: [
                          {
                            scaleNumber: 1,
                            scaleLabel: "A",
                          },
                          {
                            scaleNumber: 2,
                            scaleLabel: "B",
                          },
                        ],
                        selectedType: "Single Select",
                        selectedScaleOrOption: "Scale",
                        lowestNo: "1",
                        highestNo: "2",
                      },
                    },
                    {
                      questionId: "f812911b-3232-4c2e-bf5c-c4ebfe02d37b",
                      questionType: "Question",
                      questionStatement: "CO2",
                      questionNumber: "Q2",
                      selectedCos: [],
                      selectedPos: [],
                      blooms: [],
                      question: [],
                      attachmentFiles: [],
                      scales: {
                        scale: [
                          {
                            scaleNumber: 1,
                            scaleLabel: "A",
                          },
                          {
                            scaleNumber: 2,
                            scaleLabel: "B",
                          },
                          {
                            scaleNumber: 3,
                            scaleLabel: "C",
                          },
                        ],
                        selectedType: "Single Select",
                        selectedScaleOrOption: "Scale",
                        lowestNo: "1",
                        highestNo: "3",
                      },
                    },
                    {
                      questionId: "dbaf0821-59d5-4807-b7e4-1a95a6cb2704",
                      questionType: "Question",
                      questionStatement: "CO3",
                      questionNumber: "Q3",
                      selectedCos: [],
                      selectedPos: [],
                      blooms: [],
                      question: [],
                      attachmentFiles: [],
                      scales: {
                        scale: [
                          {
                            scaleNumber: 1,
                            scaleLabel: "A",
                          },
                          {
                            scaleNumber: 2,
                            scaleLabel: "B",
                          },
                        ],
                        selectedType: "Multiple Select",
                        selectedScaleOrOption: "Options",
                        noOfOptions: "2",
                      },
                    },
                  ],
                  attachmentFiles: [],
                  scales: {
                    scale: [],
                    selectedType: "",
                    selectedScaleOrOption: "",
                  },
                },
              ],
            },
          ],
        },
      ],
      instituteId: "e9f11b1c-eef3-4f90-baae-054ddc4ffb9b",
      semId: "d6788296-8776-4d93-81fc-fce41fd88314",
      department: "PGDM-Business Administration",
      courseYear: "FY 23-24",
      conductionDetails: "Backdated",
      startDate: "2024-10-10T00:00:00Z",
      endDate: "2024-10-11T00:00:00Z",
      feedbackId: "8f175da3-abf2-43f3-8037-bc46053c61e4",
      formTitle: "Course Feedback ",
      formDescription: "Course Feedback ",
      formType: "Course Feedback",
      formURL: "8f175da3-abf2-43f3-8037-bc46053c61e4",
      responseType: "Non Anonyms",
      selectedTypeOfSurvey: "COs",
      setting: {
        isForNBA: false,
        isSingleForAllCourse: false,
      },
      stakeholder: "Students",
      createdAt: {
        $date: "2024-10-22T10:44:09.680Z",
      },
      updatedAt: {
        $date: "2024-10-22T10:44:09.680Z",
      },
      __v: 0,
    };
    let newDataa = [
      {
        divisions: [],
        stakeHolderDetails: [
          {
            "Roll Number": "Compulsory",
          },
        ],
        status: "Published",
        courses: [
          {
            subjectId: "7b7a6a95-6e58-485e-8d4d-32fd2c8ba78e",
            subjectName: "Business Research Methods & Statistics",
            allQuestions: [
              {
                questionId: "7daa054d-23c3-47a7-81a3-1b1ae45d31fe",
                questionType: "Set",
                questionStatement: "set 1",
                question: [
                  {
                    questionId: "8f0fc44f-2722-4629-800b-e92c96692503",
                    questionType: "Section",
                    questionStatement: "sec 1",
                    questionNumber: "Section1",
                    selectedCos: [],
                    selectedPos: [],
                    blooms: [],
                    question: [
                      {
                        questionId: "09fe11fb-f3aa-4332-859a-81fa64c56036",
                        questionType: "Question",
                        questionStatement: "CO1",
                        questionNumber: "Q1",
                        selectedCos: [],
                        selectedPos: [],
                        blooms: [],
                        question: [],
                        attachmentFiles: [],
                        scales: {
                          scale: [
                            {
                              scaleNumber: 1,
                              scaleLabel: "A",
                            },
                            {
                              scaleNumber: 2,
                              scaleLabel: "B",
                            },
                          ],
                          selectedType: "Single Select",
                          selectedScaleOrOption: "Scale",
                          lowestNo: "1",
                          highestNo: "2",
                        },
                      },
                      {
                        questionId: "b294280c-165e-4613-861b-801c9504f550",
                        questionType: "Question",
                        questionStatement:
                          "Understand the Levels and Types of Measurement Scales and the Scales of Measurement",
                        questionNumber: "Q2",
                        selectedCos: [],
                        selectedPos: [],
                        blooms: [],
                        question: [],
                        attachmentFiles: [],
                        scales: {
                          scale: [
                            {
                              scaleNumber: 1,
                              scaleLabel: "A",
                            },
                            {
                              scaleNumber: 2,
                              scaleLabel: "B",
                            },
                          ],
                          selectedType: "Multiple Select",
                          selectedScaleOrOption: "Options",
                          noOfOptions: "2",
                        },
                      },
                    ],
                    attachmentFiles: [],
                    scales: {
                      scale: [],
                      selectedType: "",
                      selectedScaleOrOption: "",
                    },
                  },
                ],
              },
            ],
          },
          {
            subjectId: "f5f3b229-48ed-427a-8a31-6a108f18fcbe",
            subjectName: "Introduction to IT",
            allQuestions: [
              {
                questionId: "ce61c037-ec0d-4e1d-a3e7-34ee8e99012a",
                questionType: "Set",
                questionStatement: "set 1",
                question: [
                  {
                    questionId: "0b64104f-c448-4cea-959d-e45f1bcb5bbf",
                    questionType: "Section",
                    questionStatement: "sec 2",
                    questionNumber: "Section1",
                    selectedCos: [],
                    selectedPos: [],
                    blooms: [],
                    question: [
                      {
                        questionId: "87b87e84-83f7-4faf-b88c-573082899f6e",
                        questionType: "Question",
                        questionStatement: "CO1",
                        questionNumber: "Q1",
                        selectedCos: [],
                        selectedPos: [],
                        blooms: [],
                        question: [],
                        attachmentFiles: [],
                        scales: {
                          scale: [
                            {
                              scaleNumber: 1,
                              scaleLabel: "A",
                            },
                            {
                              scaleNumber: 2,
                              scaleLabel: "B",
                            },
                          ],
                          selectedType: "Single Select",
                          selectedScaleOrOption: "Scale",
                          lowestNo: "1",
                          highestNo: "2",
                        },
                      },
                      {
                        questionId: "f812911b-3232-4c2e-bf5c-c4ebfe02d37b",
                        questionType: "Question",
                        questionStatement: "CO2",
                        questionNumber: "Q2",
                        selectedCos: [],
                        selectedPos: [],
                        blooms: [],
                        question: [],
                        attachmentFiles: [],
                        scales: {
                          scale: [
                            {
                              scaleNumber: 1,
                              scaleLabel: "A",
                            },
                            {
                              scaleNumber: 2,
                              scaleLabel: "B",
                            },
                            {
                              scaleNumber: 3,
                              scaleLabel: "C",
                            },
                          ],
                          selectedType: "Single Select",
                          selectedScaleOrOption: "Scale",
                          lowestNo: "1",
                          highestNo: "3",
                        },
                      },
                      {
                        questionId: "dbaf0821-59d5-4807-b7e4-1a95a6cb2704",
                        questionType: "Question",
                        questionStatement: "CO3",
                        questionNumber: "Q3",
                        selectedCos: [],
                        selectedPos: [],
                        blooms: [],
                        question: [],
                        attachmentFiles: [],
                        scales: {
                          scale: [
                            {
                              scaleNumber: 1,
                              scaleLabel: "A",
                            },
                            {
                              scaleNumber: 2,
                              scaleLabel: "B",
                            },
                          ],
                          selectedType: "Multiple Select",
                          selectedScaleOrOption: "Options",
                          noOfOptions: "2",
                        },
                      },
                    ],
                    attachmentFiles: [],
                    scales: {
                      scale: [],
                      selectedType: "",
                      selectedScaleOrOption: "",
                    },
                  },
                ],
              },
            ],
          },
        ],
        instituteId: "e9f11b1c-eef3-4f90-baae-054ddc4ffb9b",
        semId: "d6788296-8776-4d93-81fc-fce41fd88314",
        department: "PGDM-Business Administration",
        courseYear: "FY 23-24",
        conductionDetails: "Backdated",
        startDate: "2024-10-10T00:00:00Z",
        endDate: "2024-10-11T00:00:00Z",
        feedbackId: "8f175da3-abf2-43f3-8037-bc46053c61e4",
        formTitle: "Course Feedback ",
        formDescription: "Course Feedback ",
        formType: "Course Feedback",
        formURL: "8f175da3-abf2-43f3-8037-bc46053c61e4",
        responseType: "Non Anonyms",
        selectedTypeOfSurvey: "COs",
        setting: {
          isForNBA: false,
          isSingleForAllCourse: false,
        },
        stakeholder: "Students",
      },
      {
        _id: {
          $oid: "670d13f37b88dca5d2823c9f",
        },
        divisions: ["A"],
        stakeHolderDetails: [
          {
            "Student Name": "Compulsory",
          },
          {
            Batch: "Compulsory",
          },
          {
            Department: "Compulsory",
          },
        ],
        status: "Published",
        allQuestions: [
          {
            questionId: "4982b53b-4e36-44f7-991d-061dd109dba5",
            questionType: "Set",
            questionStatement: "set 1",
            question: [
              {
                questionId: "a30f2679-33e4-49ff-ba6a-22adad20b6cb",
                questionType: "Section",
                questionStatement: "Q111",
                questionNumber: "Section1",
                selectedCos: [],
                selectedPos: ["PO2", "PO1", "PO3"],
                blooms: [],
                question: [
                  {
                    questionId: "57ed59be-3db1-4b72-add5-f7458f2c50ed",
                    questionType: "Question",
                    questionStatement: "what is Q1",
                    questionNumber: "Q1",
                    selectedCos: [],
                    selectedPos: ["PO1"],
                    blooms: [],
                    question: [
                      {
                        questionId: "3a4b5d23-eee9-45c2-a238-be27b9533709",
                        questionType: "SubQuestion",
                        questionStatement: "Q1 it is means",
                        questionNumber: "Q1.1",
                        selectedCos: [],
                        selectedPos: ["PO2"],
                        blooms: [],
                        question: [],
                        attachmentFiles: [],
                        scales: {
                          scale: [
                            {
                              scaleNumber: 1,
                              scaleLabel: "AA",
                            },
                            {
                              scaleNumber: 2,
                              scaleLabel: "BB",
                            },
                          ],
                          selectedType: "Single Select",
                          selectedScaleOrOption: "Scale",
                          lowestNo: "1",
                          highestNo: "2",
                        },
                      },
                    ],
                    attachmentFiles: [],
                    scales: {
                      scale: [
                        {
                          scaleNumber: 1,
                          scaleLabel: "AA",
                        },
                        {
                          scaleNumber: 2,
                          scaleLabel: "BB",
                        },
                      ],
                      selectedType: "Single Select",
                      selectedScaleOrOption: "Scale",
                      lowestNo: "1",
                      highestNo: "2",
                    },
                  },
                  {
                    questionId: "008ac4ec-48cc-4924-925d-8a4bc7dc3d39",
                    questionType: "Question",
                    questionStatement: "what is Q2",
                    questionNumber: "Q2",
                    selectedCos: [],
                    selectedPos: ["PO3"],
                    blooms: [],
                    question: [],
                    attachmentFiles: [],
                    scales: {
                      scale: [
                        {
                          scaleNumber: 1,
                          scaleLabel: "A",
                        },
                        {
                          scaleNumber: 2,
                          scaleLabel: "B",
                        },
                        {
                          scaleNumber: 3,
                          scaleLabel: "C",
                        },
                      ],
                      selectedType: "Multiple Select",
                      selectedScaleOrOption: "Options",
                      noOfOptions: "3",
                    },
                  },
                ],
                attachmentFiles: [],
              },
            ],
          },
        ],
        instituteId: "e9f11b1c-eef3-4f90-baae-054ddc4ffb9b",
        semId: "d6788296-8776-4d93-81fc-fce41fd88314",
        department: "PGDM-Business Administration",
        courseYear: "FY 23-24",
        conductionDetails: "Backdated",
        startDate: "2024-10-09T00:00:00Z",
        endDate: "2024-10-11T00:00:00Z",
        feedbackId: "3f6637e5-965b-4ef4-97ed-cce89d24cad8",
        formTitle: "Program FB using URL",
        formDescription: "Program FB using URL",
        formType: "Program Feedback",
        formURL: "3f6637e5-965b-4ef4-97ed-cce89d24cad8",
        responseType: "Non Anonyms",
        selectedTypeOfSurvey: "POs/PSOs",
        setting: {
          isForNBA: false,
          isSingleForAllCourse: false,
        },
        stakeholder: "Students",
      },
    ];
    if (Object.keys(this.feedbackSelections).length > 0) {
      await this.storeValues();
    }
  },
  methods: {
    script(oldData) {
      const {
        nbaAttainmentLevels,
        questions,
        instituteId,
        semId,
        department,
        courseYear,
        conductionDetails,
        startDate,
        endDate,
        _id,
        formTitle,
        formType,
        formURL,
        anonymous,
        threshold,
        nbaStatus,
        isPublish,
        subjectId,
        subjectName,
      } = oldData;

      // Define scales once based on nbaAttainmentLevels
      const scales = nbaAttainmentLevels.map((item, i) => ({
        scaleNo: i + 1,
        scaleLabel: item.trim(),
      }));

      // Transform each question in questions
      const arr = questions.map((old1, i) => ({
        questionId: i + 1,
        questionType: "Question",
        questionStatement: old1.question,
        questionNumber: i + 1,
        blooms: [],
        question: [],
        attachmentFiles: [],
        scales: {
          scale: scales,
          selectedType: "Single Select",
          selectedScaleOrOption: "Scale",
          lowestNo: "1",
          highestNo: scales.length.toString(),
        },
        selectedCos: old1.selectedCOs?.map((item) => `CO${item.coNumber}`),
        selectedPos: old1.selectedPos?.map((item) => `PO${item.poNumber}`),
      }));

      // Define the primary question object structure
      const obj = {
        questionId: 1,
        questionType: "Set",
        questionStatement: "set 1",
        question: [
          {
            questionId: 2,
            questionType: "Section",
            questionStatement: "sec 1",
            questionNumber: "Section1",
            question: arr,
          },
        ],
      };

      // Build courseObject with only necessary properties
      const courseObject = {
        instituteId,
        semId,
        department,
        courseYear,
        conductionDetails,
        startDate,
        endDate,
        feedbackId: _id,
        formTitle,
        formType:
          formType === "Course Exit Survey"
            ? "Course Feedback"
            : "Program Feedback",
        formURL,
        responseType: anonymous ? "Anonymous" : "Non Anonyms",
        selectedTypeOfSurvey: formType === "Course Exit Survey" ? "COs" : "POs",
        threshold,
        setting: {
          isForNBA: !!nbaStatus,
          isSingleForAllCourse: false,
        },
        stakeholder: "Students",
        status: isPublish ? "Published" : "Draft",
        ...(formType === "Course Exit Survey"
          ? {
              courses: [
                {
                  subjectId,
                  subjectName,
                  allQuestions: [obj],
                },
              ],
            }
          : { allQuestions: [obj] }),
      };
    },
    finalScript(oldDataArray) {
      oldDataArray.forEach((oldData, index) => {
        const {
          nbaAttainmentLevels,
          questions,
          instituteId,
          semId,
          department,
          courseYear,
          conductionDetails,
          startDate,
          endDate,
          _id,
          formTitle,
          formType,
          formURL,
          anonymous,
          threshold,
          nbaStatus,
          isPublish,
          subjectId,
          subjectName,
        } = oldData;

        const scales = nbaAttainmentLevels.map((item, i) => ({
          scaleNo: i + 1,
          scaleLabel: item.trim(),
        }));

        const arr = questions.map((old1, i) => ({
          questionId: i + 1,
          questionType: "Question",
          questionStatement: old1.question,
          questionNumber: i + 1,
          blooms: [],
          question: [],
          attachmentFiles: [],
          scales: {
            scale: scales,
            selectedType: "Single Select",
            selectedScaleOrOption: "Scale",
            lowestNo: "1",
            highestNo: scales.length.toString(),
          },
          selectedCos: old1.selectedCOs?.map((item) => `CO${item.coNumber}`),
          selectedPos: old1.selectedPos?.map((item) => `PO${item.poNumber}`),
        }));

        const obj = {
          questionId: 1,
          questionType: "Set",
          questionStatement: "set 1",
          question: [
            {
              questionId: 2,
              questionType: "Section",
              questionStatement: "sec 1",
              questionNumber: "Section1",
              question: arr,
            },
          ],
        };

        const courseObject = {
          instituteId,
          semId,
          department,
          courseYear,
          conductionDetails,
          startDate,
          endDate,
          feedbackId: _id,
          formTitle,
          formType:
            formType === "Course Exit Survey"
              ? "Course Feedback"
              : "Program Feedback",
          formURL,
          responseType: anonymous ? "Anonymous" : "Non Anonyms",
          selectedTypeOfSurvey:
            formType === "Course Exit Survey" ? "COs" : "POs",
          threshold,
          setting: {
            isForNBA: !!nbaStatus,
            isSingleForAllCourse: false,
          },
          stakeholder: "Students",
          status: isPublish ? "Published" : "Draft",
          ...(formType === "Course Exit Survey"
            ? {
                courses: [
                  {
                    subjectId,
                    subjectName,
                    allQuestions: [obj],
                  },
                ],
              }
            : { allQuestions: [obj] }),
        };
      });
    },
    async storeValues() {
      this.selectedLevel =
        this.feedbackSelections.batch !== undefined ? "Batch" : "Academic Year";
      if (this.selectedLevel === "Batch") {
        this.selectedBatch = this.feedbackSelections.batch.batchName;
        this.handleBatch();
      } else {
        this.selectedAcademicYear =
          this.feedbackSelections.academic.academicName;
        this.handleAcademicYear();
      }
      this.selectedFeedbackFor = this.feedbackSelections.selectedFeedbackFor;
      this.selectedTypeOfFeedback =
        this.feedbackSelections.selectedTypeOfFeedback;
      this.handleSelectedFeedbacks();
      this.selectedSemester = this.feedbackSelections.semester;
      this.handleSemester();
      this.selectedDepartment = this.feedbackSelections.department;
      this.selectedCourseYear = this.feedbackSelections.courseYear;
      if (
        this.feedbackSelections.selectedTypeOfFeedback !== "Course Feedback"
      ) {
        this.getDivisions();
        this.selectedDivision = this.feedbackSelections.division;
        this.handleDivision();
      } else {
        this.getSubjects();
        this.selectedCourse = this.feedbackSelections.course;
        this.handleCourse();
      }
      await this.saveSelections();
    },
    handleTemplatesSettings() {
      this.$router.push({
        name: "activityInstitutesManagment",
        params: {
          activeTab: 3,
        },
      });
    },
    handleAYBatch() {
      this.loading = false;
      this.selectedAcademicYear = "";
      this.selectedAcademicYearId = "";
      this.selectedBatch = "";
      this.selectedBatchId = "";
      this.selectedSemester = {};
      this.allSemesters = [];
      this.allSemestersArr = [];
      this.topSelections = [];
      this.feedbackSelections = {};
      this.selectedTypeOfFeedback = "";
      this.selectedFeedbackFor = "";
      this.selectedDepartment = "";
      this.selectedCourseYear = "";
      this.selectedCourse = "";
      this.selectedDivision = "";
    },
    handleAcademicYear() {
      this.loading = false;
      this.selectedSemester = "";
      this.selectedDepartment = "";
      this.selectedCourseYear = "";
      this.selectedCourse = "";
      this.allCourseYears = [];
      this.allCourses = [];
      this.allSemesters = [];
      this.allSemestersArr = [];

      this.batchData.map((item) => {
        const selectedItem = item.batch.find(
          (item1) => item1.academicYear === this.selectedAcademicYear
        );
        if (selectedItem) {
          if (this.allCourseYears.indexOf(selectedItem.courseYear) === -1) {
            this.allCourseYears.push(selectedItem.courseYear);
          }
          this.selectedAcademicYearId = selectedItem.academicId;
          selectedItem.semesters.map((item2) => {
            if (this.allSemesters.indexOf(item2.semName) === -1) {
              this.allSemesters.push(item2.semName);
              if (item2.semName !== "") {
                this.allSemestersArr.push(item2);
              }
            }
          });
        }
      });
    },
    handleBatch() {
      this.loading = false;
      this.allSemesters = [];
      this.allSemestersArr = [];
      const selectedItem = this.batchData.find(
        (item) => item.batchName === this.selectedBatch
      );
      if (selectedItem) {
        this.selectedBatchId = selectedItem._id;
        selectedItem.batch.forEach((item1) => {
          if (this.allCourseYears.indexOf(item1.courseYear) === -1) {
            this.allCourseYears.push(item1.courseYear);
          }
          item1.semesters.forEach((item2) => {
            if (!this.allSemesters.includes(item2.semName)) {
              this.allSemesters.push(item2.semName);
              this.allSemestersArr.push(item2);
            }
          });
        });
      }
    },
    async handleSemester() {
      this.loading = false;
      this.selectedDepartment = "";
      this.selectedCourseYear = "";
      this.selectedCourse = "";

      const selectedItem = this.allSemestersArr.find(
        (item) => item.semName === this.selectedSemester.semName
      );
      this.topSelections = {
        selected: this.selectedLevel,
        departments: this.allDepartments,
        semester: selectedItem,
        courses: this.allCourseYears,
      };
      if (this.selectedLevel === "Academic Year") {
        this.topSelections.academic = {
          academicName: this.selectedAcademicYear,
          academicId: this.selectedAcademicYearId,
        };
      }
      if (this.selectedLevel === "Batch") {
        this.topSelections.batch = {
          batchName: this.selectedBatch,
          batchId: this.selectedBatchId,
        };
      }
      this.$store.commit(
        "liveData/set_feedbackTopSelections",
        this.topSelections
      );
    },
    handleSelectedFeedbacks() {
      // this.isShow = false;
      // this.allFeedacks = [];
      // this.selectedDepartment = "";
      // this.selectedCourseYear = "";
      // this.selectedCourse = "";
      // this.selectedDivision = "";
      // this.selectedTypeOfFeedback = "";

      let selectedFeebacks = this.stakeHolderWiseFeedbackList.find(
        (item) => item.stackholder === this.selectedFeedbackFor
      );
      this.typeOfFeedbacks = selectedFeebacks?.feedbackFor;
    },
    handleTypeOfFeedback() {
      this.isShow = false;
      this.loading = false;
      this.allFeedacks = [];
      this.selectedDepartment = "";
      this.selectedCourseYear = "";
      this.selectedCourse = "";
      this.selectedDivision = "";
      this.selectedDepartment = "";
      this.selectedCourseYear = "";
      this.selectedCourse = "";
      this.selectedDivision = "";
      this.feedbackSelections = {};
    },
    handleDepartment() {
      this.isShow = false;
      this.allFeedacks = [];
      this.selectedCourseYear = "";
      this.selectedCourse = "";
      this.selectedDivision = "";
    },
    handleCourseYear() {
      // this.isShow = false;
      this.allFeedacks = [];
      this.selectedCourse = "";
      this.selectedDivision = "";
      this.getSubjects();
      this.getDivisions();
    },
    handleCourse() {
      this.allFeedacks = [];
      this.isShow =
        this.selectedFeedbackFor !== "" &&
        this.selectedTypeOfFeedback !== "" &&
        this.selectedDepartment !== "" &&
        this.selectedCourseYear !== "" &&
        this.selectedCourse.length > 0;
    },
    handleDivision() {
      this.allFeedacks = [];
      this.isShow =
        this.selectedFeedbackFor !== "" &&
        this.selectedTypeOfFeedback !== "" &&
        this.selectedDepartment !== "" &&
        this.selectedCourseYear !== "" &&
        this.selectedDivision.length > 0;
    },
    async getSubjects() {
      try {
        this.allCourses = [];
        this.allSubjects =
          await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
            {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedDepartment,
              courseYear: this.selectedCourseYear,
            }
          );
        this.allCourses = this.allSubjects;
        this.allCoursesWithAllOption.push(...this.allSubjects);
      } catch (e) {
        console.error(e);
      }
    },
    async getDivisions() {
      try {
        this.allDivisions = [];
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          semName: this.selectedSemester.semName,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
        };
        const semestersList =
          await this.semesterRepositoryInstance.getSemestersForACourseYear(
            objToPush
          );
        this.allDivisions = semestersList.map((item) => item.division);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async getDivisionBatches() {
      try {
        let batches = [];
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivisions,
        };
        batches = await this.semesterRepositoryInstance.getBatchesDetails(
          objToPush
        );
        this.allBatches = batches.batchesDetails.map((item) => item.batchName);
      } catch (e) {
        console.error(e);
      }

      this.loading = false;
    },
    async getBatches() {
      this.allDepartments = [];
      this.allBatches = [];
      this.allAcademicYears = [];
      this.batchData = [];
      this.selectedBatch = "";
      this.selectedAcademicYear = "";
      try {
        this.batchData = await this.nbaRepositoryInstance.getBatchesOfInstitute(
          {
            lmsInstituteId: this.selectedInstitute,
          }
        );

        this.batchData.forEach((item) => {
          if (!this.allBatches.includes(item.batchName)) {
            this.allBatches.push(item.batchName);
          }

          if (!this.allDepartments.includes(item.department)) {
            this.allDepartments.push(item.department);
          }

          item.batch.forEach((item1) => {
            if (!this.allAcademicYears.includes(item1.academicYear)) {
              this.allAcademicYears.push(item1.academicYear);
            }
          });
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getFeedbacks() {
      try {
        let feedbackIds = [];
        this.allFeedacks = [];
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.topSelections.semester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          formType: this.selectedTypeOfFeedback,
          stakeholder: this.selectedFeedbackFor,
        };
        if (this.selectedTypeOfFeedback === "Course Feedback") {
          objToPush.subjectIds = this.selectedCourse.map(
            (obj) => obj.subjectId
          );
        }
        if (this.selectedTypeOfFeedback === "Program Feedback") {
          objToPush.divisions = this.selectedDivision;
        }
        this.allFeedacks =
          await this.feedbackFormRepositoryInstance.getAllFeedbacksAsPerSelections(
            objToPush
          );

        this.allFeedacks?.forEach((obj) => {
          feedbackIds.push(obj.feedbackId);
          if (obj.conductionDetails === "Upcoming") {
            const { date: localStartDate, time: localStartTime } =
              this.convertDateTimeToUTCFormat(obj.startDate);
            const { date: localEndDate, time: localEndTime } =
              this.convertDateTimeToUTCFormat(obj.endDate);

            obj.localStartDate = localStartDate;
            obj.localStartTime = localStartTime;
            obj.localEndDate = localEndDate;
            obj.localEndTime = localEndTime;
          } else {
            obj.localStartDate = obj.startDate;
            obj.localEndDate = obj.endDate;
          }
          const status = this.getStatus(
            obj.localStartDate,
            obj.localStartTime,
            obj.localEndDate,
            obj.localEndTime,
            obj.status
          );
          obj.conduction = status;
        });
        this.getConductedFeedbackCount(feedbackIds);
        return this.allFeedacks;
      } catch (e) {
        console.error(e);
      }
    },
    async getConductedFeedbackCount(feedbackIds) {
      try {
        const objToPush = {
          feedbackIds: feedbackIds,
        };
        let feedbackCount =
          await this.feedbackResponseRepositoryInstance.getConductedFeedbackCount(
            objToPush
          );
        this.feedbackCount = feedbackCount.feedbacks;
      } catch (e) {
        console.error(e);
      }
    },
    async saveSelections() {
      this.allFeedacks = []
      this.feedbackSelections.academic = this.topSelections.academic;
      this.feedbackSelections.batch = this.topSelections.batch;
      this.feedbackSelections.selectedFeedbackFor = this.selectedFeedbackFor;
      this.feedbackSelections.selectedTypeOfFeedback =
        this.selectedTypeOfFeedback;
      this.feedbackSelections.semester = this.topSelections.semester;
      this.feedbackSelections.department = this.selectedDepartment;
      this.feedbackSelections.courseYear = this.selectedCourseYear;

      this.selections = {
        topSelection: {
          selected: this.topSelections.selected,
          academic: this.topSelections.academic,
          batch: this.topSelections.batch,
        },
        selectedFeedbackFor: this.selectedFeedbackFor,
        selectedTypeOfFeedback: this.selectedTypeOfFeedback,
        semester: this.topSelections.semester,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        selection: this.selectedOption,
      };

      if (this.selectedTypeOfFeedback === "Course Feedback") {
        let selectedCourse = [];
        this.selectedCourse?.map((item) => {
          selectedCourse.push({
            subjectName: item.subjectName,
            subjectId: item.subjectId,
          });
        });
        this.selections.course = this.selectedCourse;
        this.feedbackSelections.course = this.selectedCourse;
        this.feedbackSelections.selectedSubjectIds = selectedCourse;
      }
      if (this.selectedTypeOfFeedback === "Program Feedback") {
        this.selections.division = this.selectedDivision;
        this.feedbackSelections.division = this.selectedDivision;
        this.feedbackSelections.divisions = this.selectedDivision;
      }
      await this.getFeedbacks();
      this.$store.commit(
        "liveData/set_feedbackTopSelections",
        this.feedbackSelections
      );
      this.loading = true;
    },
    convertDateTimeToUTCFormat(localDateOrUTC, localTime = null) {
      try {
        let dateObj;

        if (localTime) {
          // Combine the local date and time if both are provided
          const localDateTime = `${localDateOrUTC} ${localTime}`;
          dateObj = new Date(localDateTime);

          // Check if the Date object is valid
          if (isNaN(dateObj.getTime())) {
            throw new Error(
              "Invalid date or time format. Please use 'YYYY-MM-DD' for the date and 'HH:MM' for the time."
            );
          }

          // Convert the Date object to a UTC string
          const utcDateTime = dateObj.toISOString();

          // Format the UTC string to the desired format
          return utcDateTime.replace(/\.\d{3}Z$/, "Z");
        } else {
          // If only one argument is passed, assume it is in UTC and parse it
          dateObj = new Date(localDateOrUTC);

          // Check if the Date object is valid
          if (isNaN(dateObj.getTime())) {
            throw new Error(
              "Invalid date format. Please use 'YYYY-MM-DDTHH:MM:SSZ' or similar format."
            );
          }

          // Convert the UTC date to local date and time
          const localDate = dateObj.toLocaleDateString("en-CA"); // Format: YYYY-MM-DD
          const localTime = dateObj.toLocaleTimeString("en-US", {
            hour12: false,
          }); // Format: HH:MM:SS

          return { date: localDate, time: localTime };
        }
      } catch (error) {
        return `Error: ${error.message}`;
      }
    },
    getStatus(startDate, startTime, endDate, endTime, conductionDetails) {
      const now = new Date();
      let start, end;

      if (startTime && endTime) {
        // If both startTime and endTime are provided, include time in comparison
        start = new Date(`${startDate}T${startTime}`);
        end = new Date(`${endDate}T${endTime}`);
      } else {
        // If only dates are provided, consider dates only (set time to midnight)
        start = new Date(startDate);
        end = new Date(endDate);
        end.setHours(23, 59, 59, 999); // Ensure the entire end date is included
      }

      if (now > end) {
        return conductionDetails === "Draft" ? conductionDetails : "Finished";
      } else if (now >= start && now <= end) {
        return conductionDetails === "Draft" ? conductionDetails : "Ongoing";
      } else {
        return conductionDetails;
      }
    },
  },
};
</script>

<style src="./activityFeedbackFormRevamped.css" scoped></style>

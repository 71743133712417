<template src="./activityQuestions.html"></template>

<script>
import { v4 } from "uuid";
import showStatus from "../../../NetworkManager/showStatus";
import Feedback from "../../../Repository/Feedback.js";

export default {
  name: "activityQuestions",
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      black: "#000000",
      progressIndicator: 0,
      uploadQuestionsViaExcel: false,
      copyQuestionsModal: false,
      deleteQuestionsModal: false,
      copyFromSubject: "",
      isSaved: false,
      showAttachment: true,
      deleteErrorMessage: "",
      questionsArray: [],
      headers: [
        {
          text: "Sections",
          value: "sectionNames",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "Question No",
          value: "questionNumber",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "Sub Qn No",
          value: "subQnNo",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "Question",
          value: "question",
          sortable: false,
          width: "400px",
          align: "center",
        },
        // {
        //   text: "Attachment",
        //   value: "attachmentFiles",
        //   sortable: false,
        //   width: "100px",
        //   align: "center",
        // },
        {
          text: "Map CO",
          value: "cos",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "",
          value: "delete",
          sortable: false,
          width: "5%",
          align: "left",
        },
      ],
      sectionNo: 0,
      questionNo: 0,
      allQuestions: [],
      questionsValidationSuccess: true,
      setsObjectForQuestions: {
        "set 1": [],
      },
      allTypeOfQuestions: [
        "Short Answer",
        "Long Answer",
        "Rating",
        "Single select",
        "Multiple select",
      ],
      allScaleOrOptions: ["Scale", "Options"],
      startDate: "",
      createdSets: [
        {
          no: 1,
          setName: "set 1",
        },
      ],
      students: [],
      subQuestionNo: 0,
      questionItem: "",
      questionToGiveMarks: [],
      seperatedSetQuestions: [],
      allSections: [],
      studentData: {},
      attachmentFiles: [],
      coNames: [],
      feedbackSelections: {},
      selectedTemplate: {},
      activeSet: {
        no: 1,
        setName: "set 1",
      },
      feedbackId: "",
      feedback: {},
      createdFeedback: {},
      selectedSubject: 0,
      selectedVal: 0,
      tempIndex: 0,
      isCourseFB: "",
      isNext: "",
      isTab: "",
      allPOs: [],
      copiedSubjectDetail: {},
      isEdit: false,
      isFirstVisit: null,
      isDataSaved: undefined,
      deleteIndex: "",
      deleteQuestionsArray: [],
      headersForQuestions: [],
    };
  },
  props: [
    "props_selectedFeedback",
    "props_isTab",
    "props_isTabActive",
    "props_isScaleChange",
    "props_isNextClick",
  ],
  async created() {
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.createdFeedback = this.$store.getters["liveData/get_createdFeedback"];
    this.allPos = this.$store.getters["liveData/get_allPosForFeedback"];
    this.feedbackRepositoryInstance = new Feedback(this);
    this.feedbackSelections =
      this.$store.getters["liveData/get_feedbackTopSelections"];
    this.isTab = this.props_isTab;
    this.isEdit = this.props_selectedFeedback._id !== undefined ? true : false;

    this.isCourseFB = this.createdFeedback?.formType === "Course Feedback";

    if (
      this.createdFeedback?.feedbackId !== undefined ||
      this.createdFeedback?.isTemplateSelected
    ) {
      const tempQuestionsSource =
        this.props_selectedFeedback.formType === "Course Feedback"
          ? this.props_selectedFeedback.courses
          : this.props_selectedFeedback.allQuestions;

      if (tempQuestionsSource !== undefined) {
        this.createdFeedback.tempQuestions = JSON.parse(
          JSON.stringify(tempQuestionsSource)
        );
      }

      this.storeQuestionArray();
      this.isFirstVisit = this.props_isTabActive;
    }
  },
  watch: {
    selectedSubject(newVal) {
      this.selectedVal = newVal;
    },
    async props_isNextClick() {
      if (this.props_isNextClick > 0 && this.props_isScaleChange) {
        this.createdFeedback =
          this.$store.getters["liveData/get_createdFeedback"];

        this.createdFeedback.feedbackId = this.createdFeedback.formURL;
      }

      // If scale has changed, fetch and update feedback
      if (this.props_isScaleChange) {
        const feedback = await this.getFeedbackById();
        feedback.tempQuestions = feedback.courses || feedback.allQuestions;

        this.createdFeedback = feedback;
        this.storeQuestionArray();
        this.updateSelectedFeedback(false);
        this.$store.commit("liveData/set_createdFeedback", feedback);
      }
    },
    async props_isTab() {
      // Skip if it's the first visit
      if (this.isFirstVisit || this.isFirstVisit === null) {
        this.isFirstVisit = false;
        return;
      }

      // If scale has changed, fetch and update feedback
      if (this.props_isScaleChange) {
        const feedback = await this.getFeedbackById();
        feedback.tempQuestions = feedback.courses || feedback.allQuestions;

        this.createdFeedback = feedback;
        this.storeQuestionArray();
        this.updateSelectedFeedback(false);
        this.$store.commit("liveData/set_createdFeedback", feedback);
      }
    },
  },
  methods: {
    updateSelectedFeedback(value) {
      this.$emit("update-selected-feedback", value);
    },
    handleShowAttachment(subject) {
      if (subject.showAttachment && this.headers.length === 6) {
        subject.headers[4] = {
          text: "Attachment",
          value: "attachmentFiles",
          sortable: false,
          width: "100px",
          align: "center",
        };
      } else {
        subject.headers = subject.headers.filter(
          (header) => header.value !== "attachmentFiles"
        );
      }
    },
    //***************************store questions data***************************
    async storeQuestionArray() {
      if (this.createdFeedback?.formType === "Course Feedback") {
        this.createdFeedback?.courses?.map((subject) => {
          let questionsArray = [];
          subject.headers = this.headers;
          subject.showAttachment = false;
          let array = this.storeQuestionData(
            subject.allQuestions,
            questionsArray
          );
          subject["questionsArray"] = array;
        });
      } else {
        this.questionsArray = [];
        let array = this.storeQuestionData(
          this.createdFeedback.allQuestions,
          this.questionsArray
        );
        this.questionsArray = array;
      }
    },
    storeQuestionData(allQuestions, questionsArray) {
      if (allQuestions?.length > 0) {
        this.getAllSections(allQuestions);
        this.activeSet = {
          no: 1,
          setName: "set 1",
        };
        this.setActiveSet(this.activeSet, questionsArray);
        this.createdSets = [];
        this.setsObjectForQuestions = {};
        for (let i = 0; i < allQuestions?.length; i++) {
          if (allQuestions[i].questionType === "Set") {
            const questionStatement = allQuestions[i].questionStatement;
            this.createdSets.push({
              no: i + 1,
              setName: questionStatement,
            });
            if (
              !Object.prototype.hasOwnProperty.call(
                this.setsObjectForQuestions,
                questionStatement
              )
            ) {
              this.setsObjectForQuestions[questionStatement] = [];
            }
            this.setsObjectForQuestions[questionStatement].push(
              ...this.getQuestionsOfSelectedSet(
                { setName: questionStatement, forShowingQuestions: true },
                null,
                allQuestions
              )
            );
          }
        }
        questionsArray = this.setsObjectForQuestions["set 1"];
      }

      return questionsArray;
    },
    setActiveSet(set, questionsArray) {
      this.setsObjectForQuestions[this.activeSet.setName] = questionsArray;
      this.activeSet = set;
      questionsArray = this.setsObjectForQuestions[this.activeSet.setName];
    },
    getAllSections(allQuestions) {
      this.allSections = [];
      for (let i = 0; i < allQuestions.length; i++) {
        if (allQuestions[i].questionType === "Set") {
          for (let j = 0; j < allQuestions[i].question.length; j++) {
            this.allSections.push({
              questionId: allQuestions[i].question[j].questionId,
              sectionName: allQuestions[i].question[j].questionStatement,
              scales: allQuestions[i].question[j].scales,
            });
          }
        }
      }
      for (let i = 0; i < this.students.length; i++) {
        this.students[i].allSections = this.allSections;
      }
    },
    getQuestionsOfSelectedSet(set, item, allQuestions) {
      this.studentData = item;
      let anyQuestionFound = false;
      let setQuestions = [];
      for (let i = 0; i < allQuestions.length; i++) {
        if (
          allQuestions[i].questionType === "Set" &&
          allQuestions[i].questionStatement === set.setName
        ) {
          setQuestions = allQuestions[i].question;
        }
      }
      this.seperatedSetQuestions = [];

      for (let i = 0; i < setQuestions?.length; i++) {
        anyQuestionFound = false;
        this.seperatedSetQuestions.push({
          sectionNames: setQuestions[i].questionNumber,
          question: setQuestions[i].questionStatement,
          questionId: setQuestions[i].questionId,
          cos: setQuestions[i]?.cos,
          selectedCos: setQuestions[i]?.selectedCos,
          selectedPos: setQuestions[i]?.selectedPos,
          selectedBlooms: setQuestions[i].blooms,
          blooms: this.bloomsNames,
          scales: setQuestions.scales,
        });
        if (setQuestions[i].question?.length > 0) {
          const sectionQuestion = setQuestions[i].question;
          for (let j = 0; j < sectionQuestion?.length; j++) {
            if (sectionQuestion[j].questionType === "OrQuestion") {
              anyQuestionFound = false;
              this.seperatedSetQuestions.push({
                questionNumber: "OR",
              });
            }
            anyQuestionFound = false;
            this.seperatedSetQuestions.push({
              questionNumber: sectionQuestion[j]?.questionNumber,
              question:
                sectionQuestion[j]?.questionStatement === "Solve any_out of"
                  ? "Solve any (" +
                    sectionQuestion[j].NumberOfQuestionsToSolve +
                    ")"
                  : sectionQuestion[j].questionStatement,
              tryl: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              anyCount: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              outOfCount: sectionQuestion[j]?.question?.length,
              scaleOrOptions: sectionQuestion[j].scaleOrOptions,
              type: sectionQuestion[j].type,
              solveAnyCount:
                sectionQuestion[j]?.questionStatement === "Solve any_out of"
                  ? 0
                  : sectionQuestion[j]?.NumberOfQuestionsToSolve,
              questionId: sectionQuestion[j]?.questionId,
              cos: sectionQuestion[j]?.cos,
              selectedCos: sectionQuestion[j]?.selectedCos,
              selectedPos: sectionQuestion[j]?.selectedPos,
              selectedBlooms: sectionQuestion[j]?.blooms,
              scales: sectionQuestion[j].scales,
              blooms: this.bloomsNames,
            });
            if (sectionQuestion[j].NumberOfprops_isTabQuestionsToSolve) {
              anyQuestionFound = true;
              this.seperatedSetQuestions.push({
                subQnNo: "Any " + sectionQuestion[j].NumberOfQuestionsToSolve,
                anyCount: sectionQuestion[j].NumberOfQuestionsToSolve,
                anyStatement: true,
              });
            }
            if (sectionQuestion[j]?.question?.length > 0) {
              const subQuestion = sectionQuestion[j]?.question;
              for (let k = 0; k < subQuestion.length; k++) {
                if (subQuestion[k].questionType === "OrSubQuestion") {
                  this.seperatedSetQuestions.push({
                    subQnNo: "OR",
                    anyStatement: false,
                  });
                }
                if (anyQuestionFound) {
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anySubQuestion: true,
                    anyStatement: true,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    cos: subQuestion[k]?.cos,
                    selectedCos: subQuestion[k]?.selectedCos,
                    selectedPos: subQuestion[k].selectedPos,
                    selectedBlooms: subQuestion[k].blooms,
                    scales: subQuestion[k].scales,
                    blooms: this.bloomsNames,
                  });
                } else {
                  anyQuestionFound = false;
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anyStatement: false,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    cos: subQuestion[k]?.cos,
                    selectedCos: subQuestion[k]?.selectedCos,
                    selectedPos: subQuestion[k].selectedPos,
                    selectedBlooms: subQuestion[k].blooms,
                    scales: subQuestion[k].scales,
                    blooms: this.bloomsNames,
                  });
                }
              }
            }
          }
        }
      }
      this.isNext = false;
      if (!set.forShowingQuestions && this.studentData?.answers) {
        const studentDataMap = new Map(
          this.studentData?.answers.map((data) => [data.questionId, data])
        );
        for (const setQuestion of this.seperatedSetQuestions) {
          const studentDataForQuestion = studentDataMap.get(
            setQuestion.questionId
          );
        }
      } else return this.seperatedSetQuestions;
    },
    //***************************end*******************************************
    rowClass(item) {
      if (item.sectionNames) {
        return "sectionBackgrond";
      }
    },
    fieldBackgroundColors(item) {
      if (
        item.questionNumber === "OR" ||
        item.subQnNo === "OR" ||
        item.questionNumberAny
      ) {
      } else {
        return "#9205050D";
      }
    },
    resetQueNo(questionsArray) {
      let secNo = 1;
      let qnNo = 1;
      let sQnNo = 1;
      for (let i = 0; i < questionsArray.length; i++) {
        if (questionsArray[i].sectionNames) {
          qnNo = 1;
          sQnNo = 1;
          questionsArray[i].questionNo = "Section" + secNo;
          questionsArray[i].sectionNames = "Section" + secNo;
          secNo++;
        } else if (
          questionsArray[i].questionNumber &&
          questionsArray[i].questionNumber !== "OR"
        ) {
          sQnNo = 1;
          questionsArray[i].questionNo = "Q" + qnNo;
          questionsArray[i].questionNumber = "Q" + qnNo;
          qnNo++;
        } else if (
          questionsArray[i].subQnNo &&
          questionsArray[i].subQnNo !== "OR" &&
          !questionsArray[i].questionNumberAny &&
          questionsArray[i].subQnNo.slice(0, 3) !== "Any"
        ) {
          questionsArray[i].subQnNo = "Q" + (qnNo - 1) + "." + sQnNo;
          sQnNo++;
        }
      }
    },
    addSection(questionsArray) {
      let sectionNames = ++this.sectionNo;
      for (let i = questionsArray?.length - 1; i >= 0; i--) {
        if (questionsArray[i]?.sectionNames) {
          sectionNames =
            questionsArray[i].sectionNames[
              questionsArray[i].sectionNames.length - 1
            ] -
            "" +
            1;
        }
      }
      this.questionNo = 0;
      const quesObject = {
        sectionNames: "Section" + sectionNames,
        question: "",
        sectionId: v4(),
      };
      questionsArray.push(quesObject);
      this.resetQueNo(questionsArray);
      this.tempIndex++;
    },
    addNormalQuestion(item, questionsArray) {
      this.subQuestionNo = 0;
      this.questionItem = item.sectionNames;
      const quesObject = {
        questionNumber: "Q" + ++this.questionNo,
        questionForSection: item.sectionNames,
        question: "",
        questionId: v4(),
        cos: this.coNames,
      };
      let foundSec = false;
      let breaked = false;
      for (let i = 0; i < questionsArray.length; i++) {
        if (questionsArray[i]?.sectionNames === item.sectionNames) {
          foundSec = true;
        } else if (
          questionsArray[i].sectionNames &&
          questionsArray[i].sectionNames !== item.sectionNames &&
          foundSec
        ) {
          questionsArray.splice(i, 0, quesObject);
          breaked = true;
          break;
        }
      }
      if (!breaked) {
        questionsArray.push(quesObject);
      }
      this.resetQueNo(questionsArray);

      this.tempIndex++;
    },
    addSubQuestion(index, item, questionsArray) {
      this.questionItem = item.questionNo;
      this.questionItemIndex = index + 1;

      const quesObject = {
        question: "",
        subQuestionId: v4(),
        subQnNo: "Q" + item.questionNo + "." + ++this.subQuestionNo,
        cos: this.coNames,
      };
      let foundQn = false;
      let breaked = false;
      for (let i = 0; i < questionsArray.length; i++) {
        if (
          questionsArray[i]?.questionNo === this.questionItem &&
          i === this.questionItemIndex - 1
        ) {
          foundQn = true;
        } else if (
          (questionsArray[i].questionNo &&
            questionsArray[i].questionNo !== this.questionItem &&
            foundQn) ||
          (questionsArray[i].questionNumber && foundQn)
        ) {
          questionsArray.splice(i, 0, quesObject);
          breaked = true;
          break;
        }
      }
      if (!breaked) {
        questionsArray.push(quesObject);
      }
      this.resetQueNo(questionsArray);
      this.tempIndex++;
    },
    deleteQuestion(index, questionsArray) {
      if (index >= 0 && index < questionsArray.length) {
        if (questionsArray[index].sectionNames) {
          questionsArray.splice(index, 1);
          for (let i = index; i < questionsArray.length; i++) {
            if (questionsArray[i].sectionNames) {
              break;
            } else {
              questionsArray.splice(i, 1);
              i--;
            }
          }
        } else if (questionsArray[index].questionNumber) {
          questionsArray.splice(index, 1);
          for (let i = index; i < questionsArray.length; i++) {
            if (
              questionsArray[i].questionNumber ||
              questionsArray[i].sectionNames
            ) {
              break;
            } else {
              questionsArray.splice(i, 1);
              i--;
            }
          }
        } else if (questionsArray[index].subQnNo) {
          questionsArray.splice(index, 1);
        }
      }

      this.resetQueNo(questionsArray);
      this.tempIndex++;
      this.deleteQuestionsModal = false;
    },
    //***************************Save questions data***************************
    async createQuestionArray() {
      let finalQuestionArray = [];
      if (this.createdFeedback?.formType === "Course Feedback") {
        finalQuestionArray = await Promise.all(
          this.createdFeedback?.courses?.map(async (subject, i) => {
            const array = await this.finalSetQuestions(
              subject.questionsArray,
              subject.allQuestions
            );
            return {
              subjectId: subject.subjectId,
              subjectName: subject.subjectName,
              threshold: subject.threshold,
              allQuestions: [array[array.length - 1]], // Consider only first question
            };
          })
        );
      } else {
        this.allQuestions = [];
        finalQuestionArray = await this.finalSetQuestions(
          this.questionsArray,
          this.allQuestions
        );
      }
      if (this.createdFeedback.isTemplateSelected) {
        this.storeTemplateQuestionData(finalQuestionArray);
      } else {
        await this.storeExistingQuestionData(finalQuestionArray);
      }
    },
    async finalSetQuestions(questionsArray, allQuestions) {
      this.setsObjectForQuestions[this.activeSet.setName] = questionsArray;
      const sets = Object.keys(this.setsObjectForQuestions);
      for (let i = 0; i < sets.length; i++) {
        questionsArray = this.setsObjectForQuestions[sets[i]];
        let isBreaked = false;
        for (let j = 0; j < questionsArray.length; j++) {
          if (questionsArray[j]?.question === "") {
            isBreaked = true;
            showStatus(
              "Enter question statement " +
                (questionsArray[j].sectionNames
                  ? questionsArray[j].sectionNames
                  : questionsArray[j].questionNumber
                  ? questionsArray[j].questionNumber
                  : questionsArray[j].subQnNo),
              5000,
              "error",
              this
            );
            break;
          }
        }
        if (isBreaked) {
          this.questionsValidationSuccess = false;
          break;
        } else {
          this.questionsValidationSuccess = true;
          this.saveQuestions(sets[i], questionsArray, allQuestions);
        }
      }
      if (this.questionsValidationSuccess) {
        let questions = await this.questionsObject(
          questionsArray,
          allQuestions
        );
        return questions;
      }
    },
    async saveQuestions(set, questionsArray, allQuestions) {
      this.questionToGiveMarks = questionsArray;
      allQuestions.push({
        setName: set,
      });
      for (let i = 0; i < questionsArray.length; i++) {
        if (questionsArray[i].sectionNames) {
          allQuestions.push({
            "Section No.": questionsArray[i].sectionNames,
            Section: questionsArray[i].question,
            selectedCos: questionsArray[i].selectedCos,
            selectedPos: questionsArray[i].selectedPos,
            Blooms: questionsArray[i].selectedBlooms,
          });
          continue;
        }
        if (
          questionsArray[i].questionNumber &&
          questionsArray[i].questionNumber !== "OR"
        ) {
          allQuestions.push({
            "Question No.": questionsArray[i].questionNumber,
            Question: questionsArray[i].question,
            selectedCos: questionsArray[i].selectedCos,
            selectedPos: questionsArray[i].selectedPos,
            Blooms: questionsArray[i].selectedBlooms,
            scaleOrOptions: questionsArray[i].scaleOrOptions,
            type: questionsArray[i].type,
          });
          continue;
        }
        if (
          questionsArray[i].questionNumber &&
          questionsArray[i].questionNumber === "OR"
        ) {
          allQuestions.push({
            "Question No.": "OR",
          });
          continue;
        }
        if (questionsArray[i].subQnNo && questionsArray[i].subQnNo !== "OR") {
          allQuestions.push({
            "Sub-Question No.": questionsArray[i].subQnNo,
            "Sub-Question": questionsArray[i].question,
            selectedCos: questionsArray[i].selectedCos,
            selectedPos: questionsArray[i].selectedPos,
            Blooms: questionsArray[i].selectedBlooms,
            scaleOrOptions: questionsArray[i].scaleOrOptions,
            type: questionsArray[i].type,
          });
          continue;
        }
        if (questionsArray[i].subQnNo && questionsArray[i].subQnNo === "OR") {
          allQuestions.push({
            "Sub-Question No.": "OR",
          });
          continue;
        }
        if (questionsArray[i].questionNumberAny) {
          allQuestions.push({
            "Question No.": questionsArray[i].questionNumberAny,
          });
          continue;
        }
      }
    },
    async questionsObject(questionsArray, allQuestionsArr) {
      try {
        let isNextOrSection = false;
        let allQuestions = [];
        let isNextOrSubSection = false;
        let isNextOrQuestion = false;
        let isNextOrSubQuestion = false;
        let section;
        let set;
        let subSection;
        let question;
        let subQuestion;

        for (let i = 0; i < questionsArray?.length; i++) {
          if (
            Array.isArray(questionsArray[i].attachmentFiles) &&
            questionsArray[i].attachmentFiles?.length > 0
          ) {
            const files = questionsArray[i].attachmentFiles.filter(
              (e) => !e.url
            );
            if (Array.isArray(files) && files?.length > 0) {
              const uploadedFiles =
                await this.uploadToDigitalOceanInstance.upload(
                  files,
                  this.userData.uId
                );
              questionsArray[i].attachmentFiles = uploadedFiles.filter(
                (e) => e !== null || e !== undefined
              );
              allQuestionsArr[i + 1]["attachmentFiles"] = questionsArray[i]
                .attachmentFiles
                ? questionsArray[i].attachmentFiles
                : [];
            } else {
              allQuestionsArr[i + 1]["attachmentFiles"] =
                questionsArray[i].attachmentFiles;
            }
          }
        }
        for (let i = 0; i < allQuestionsArr.length; i++) {
          const questions = allQuestionsArr[i];
          if (questions.setName) {
            allQuestions.push({
              questionId: questions.questionId ? questions.questionId : v4(),
              questionType: "Set",
              questionStatement: questions?.setName,
              question: [],
            });
          }
          if (questions?.Section && !isNextOrSection) {
            section = {
              questionId: questions.questionId ? questions.questionId : v4(),
              questionType: "Section",
              questionStatement: questions?.Section,
              questionNumber: questions["Section No."],
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            allQuestions[allQuestions.length - 1]?.question.push(section);
          }
          if (
            questions["Question No."] &&
            typeof questions["Question No."] === "string"
          ) {
            let anyQuestion = questions["Question No."].trim();

            if (anyQuestion.length >= 3 && anyQuestion.slice(0, 3) === "Any") {
              let numberOfQuestionsToSolve = parseInt(
                anyQuestion.slice(-1),
                10
              );
              const lastSection = allQuestions[allQuestions.length - 1];
              if (
                lastSection &&
                (lastSection.questionType === "Section" ||
                  lastSection.questionType === "OrSection")
              )
                lastSection.NumberOfQuestionsToSolve = numberOfQuestionsToSolve;
            }
          }
          if (
            questions["Sub-Question No."] &&
            typeof questions["Sub-Question No."] === "string"
          ) {
            let subQuestion = questions["Sub-Question No."].trim();

            if (subQuestion.length >= 3 && subQuestion.slice(0, 3) === "Any") {
              let numberOfQuestionsToSolve = parseInt(
                subQuestion.slice(-1),
                10
              );
              const lastSection = allQuestions[allQuestions.length - 1];
              const lastSubSectionQuestion =
                lastSection?.question[lastSection?.question.length - 1];
              const lastQuestion =
                lastSubSectionQuestion?.question[
                  lastSubSectionQuestion?.question.length - 1
                ];
              if (
                lastQuestion &&
                (lastQuestion.questionType === "Question" ||
                  lastQuestion.questionType === "OrQuestion")
              )
                lastQuestion.NumberOfQuestionsToSolve =
                  numberOfQuestionsToSolve;
            }
          }
          if (questions["Section No."] === "OR") {
            isNextOrSection = true;
          }
          if (questions.Section && isNextOrSection) {
            isNextOrSection = false;
            section = {
              questionId: questions.questionId ? questions.questionId : v4(),
              questionType: "OrSection",
              questionStatement: questions?.Section,
              questionNumber: questions["Section No."],
              scaleOrOptions: questions?.scaleOrOptions,
              type: questions?.type,
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            allQuestions[allQuestions.length - 1]?.question.push(section);
          }
          if (questions["Question No."] === "OR") {
            isNextOrQuestion = true;
          }
          if (questions.Question && !isNextOrQuestion) {
            question = {
              questionId: questions.questionId ? questions.questionId : v4(),
              questionType: "Question",
              questionStatement: questions.Question,
              questionNumber: questions["Question No."],
              scaleOrOptions: questions?.scaleOrOptions,
              type: questions?.type,
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            const lastSection = allQuestions[allQuestions.length - 1];
            const lastSubSectionQuestion =
              lastSection?.question[lastSection?.question.length - 1];
            if (
              lastSubSectionQuestion &&
              lastSubSectionQuestion.questionType === "Section"
            )
              lastSubSectionQuestion.question.push(question);
            else if (lastSection && lastSection.questionType === "Set")
              lastSection.question.push(question);
            else allQuestions.push(question);
          }

          if (questions.Question && isNextOrQuestion) {
            isNextOrQuestion = false;
            question = {
              questionId: questions.questionId ? questions.questionId : v4(),
              questionType: "OrQuestion",
              questionStatement: questions?.Question,
              questionNumber: questions["Question No."],
              scaleOrOptions: questions?.scaleOrOptions,
              type: questions?.type,
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            const lastSection = allQuestions[allQuestions.length - 1];
            const lastSubSectionQuestion =
              lastSection?.question[lastSection?.question.length - 1];
            if (
              lastSubSectionQuestion &&
              lastSubSectionQuestion.questionType === "Section"
            )
              lastSubSectionQuestion.question.push(question);
            else if (lastSection && lastSection.questionType === "Set")
              lastSection.question.push(question);
            else allQuestions.push(question);
          }
          if (questions["Sub-Question No."] === "OR") {
            isNextOrSubQuestion = true;
          }
          if (questions["Sub-Question"] && !isNextOrSubQuestion) {
            subQuestion = {
              questionId: questions.questionId ? questions.questionId : v4(),
              questionType: "SubQuestion",
              questionStatement: questions["Sub-Question"],
              questionNumber: questions["Sub-Question No."],
              scaleOrOptions: questions?.scaleOrOptions,
              type: questions?.type,
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            const lastSection = allQuestions[allQuestions.length - 1];
            const lastSubSectionQuestion =
              lastSection?.question[lastSection?.question.length - 1];
            const lastQuestion =
              lastSubSectionQuestion?.question[
                lastSubSectionQuestion?.question.length - 1
              ];
            if (
              lastQuestion &&
              (lastQuestion.questionType === "Question" ||
                lastQuestion.questionType === "OrQuestion")
            )
              lastQuestion.question.push(subQuestion);
            else if (
              lastSubSectionQuestion &&
              lastSubSectionQuestion.questionType === "Section"
            )
              lastSubSectionQuestion.question.push(subQuestion);
            else if (lastSection && lastSection?.questionType === "Set")
              lastSection.question.push(subQuestion);
          }
          if (questions["Sub-Question"] && isNextOrSubQuestion) {
            isNextOrSubQuestion = false;
            subQuestion = {
              questionId: questions.questionId ? questions.questionId : v4(),
              questionType: "OrSubQuestion",
              questionStatement: questions["Sub-Question"],
              questionNumber: questions["Sub-Question No."],
              scaleOrOptions: questions?.scaleOrOptions,
              type: questions?.type,
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            const lastSection = allQuestions[allQuestions.length - 1];
            const lastSubSectionQuestion =
              lastSection?.question[lastSection?.question.length - 1];
            const lastQuestion =
              lastSubSectionQuestion?.question[
                lastSubSectionQuestion?.question.length - 1
              ];
            if (
              lastQuestion &&
              (lastQuestion.questionType === "Question" ||
                lastQuestion.questionType === "OrQuestion")
            )
              lastQuestion.question.push(subQuestion);
            else if (
              lastSubSectionQuestion &&
              lastSubSectionQuestion.questionType === "Section"
            )
              lastSubSectionQuestion.question.push(subQuestion);
            else if (lastSection && lastSection?.questionType === "Set")
              lastSection.question.push(subQuestion);
          }
        }
        this.filledQuestions = allQuestions;
        return allQuestions;
      } catch (error) {
        console.error(error);
      }
    },
    async storeExistingQuestionData(allQuestions) {
      let tempQuestions = [];
      if (this.isTab && this.createdFeedback?.feedbackId !== undefined) {
        const feedback = await this.getFeedbackById();
        if (this.createdFeedback?.formType === "Course Feedback") {
          tempQuestions = feedback.coursesWiseQuestions;
        } else {
          tempQuestions = feedback.allQuestions;
        }
        this.isNext = false;
      } else {
        tempQuestions = this.createdFeedback.tempQuestions;
      }

      if (this.createdFeedback?.formType === "Course Feedback") {
        this.createdFeedback["coursesWiseQuestions"] = allQuestions;
        allQuestions?.map((Nobj1) => {
          tempQuestions?.map((Oobj1) => {
            if (Oobj1.subjectId === Nobj1.subjectId) {
              this.handleExistingQuestion(
                Oobj1.allQuestions,
                Nobj1.allQuestions
              );
            }
          });
        });
      } else {
        this.createdFeedback["allQuestions"] = allQuestions;
        this.handleExistingQuestion(tempQuestions, allQuestions);
      }
      this.addQuestionToFeedback();
    },
    storeTemplateQuestionData(allQuestions) {
      let templateData = this.createdFeedback.selectedTemplate.allQuestions;

      if (this.createdFeedback?.formType === "Course Feedback") {
        this.createdFeedback["coursesWiseQuestions"] = allQuestions;
        allQuestions?.map((nObj1) => {
          this.handleExistingQuestionForTemplate(
            templateData,
            nObj1.allQuestions
          );
        });
      } else {
        this.createdFeedback["allQuestions"] = allQuestions;
        this.handleExistingQuestionForTemplate(templateData, allQuestions);
      }
      this.addQuestionToFeedback();
    },
    checkQuestionArePresent(allQuestions) {
      if (allQuestions[0].question.length === 0 && this.isSaved) {
        showStatus("Please fill the Questions", 2000, "error", this);
        return false;
      } else if (
        allQuestions[0]?.question[0]?.question?.length === 0 &&
        this.isSaved
      ) {
        showStatus("Please fill the Questions", 2000, "error", this);
        return false;
      } else {
        return true;
      }
    },
    validateQuestions() {
      if (this.createdFeedback?.formType === "Course Feedback") {
        const result = this.createdFeedback?.coursesWiseQuestions?.every(
          (obj) => this.checkQuestionArePresent(obj.allQuestions)
        );

        return result;
      } else {
        const result = this.checkQuestionArePresent(
          this.createdFeedback.allQuestions
        );
        return result;
      }
    },
    async addQuestionToFeedback() {
      try {
        const isValid = this.validateQuestions();
        if (isValid) {
          let res = {};
          const objToPush = {
            instituteId: this.createdFeedback.instituteId,
            semId: this.createdFeedback.semId,
            conductionDetails: this.createdFeedback.conductionDetails,
            courseYear: this.createdFeedback.courseYear,
            divisions: this.createdFeedback.divisions,
            department: this.createdFeedback.department,
            startDate: this.createdFeedback.startDate,
            endDate: this.createdFeedback.endDate,
            extendedDate: this.createdFeedback.extendedDateTime,
            stakeholder: this.createdFeedback.stakeholder,
            formDescription: this.createdFeedback.formDescription,
            formTitle: this.createdFeedback.formTitle,
            formType: this.createdFeedback.formType,
            formURL: this.createdFeedback.formURL,
            responseType: this.createdFeedback.responseType,
            selectedTypeOfSurvey: this.createdFeedback.selectedTypeOfSurvey,
            setting: this.createdFeedback.setting,
            stakeHolderDetails: this.createdFeedback.stakeHolderDetails,
          };
          if (this.createdFeedback?.formType === "Course Feedback") {
            // Remove the unwanted fields from this.createdFeedback
            this?.createdFeedback?.coursesWiseQuestions?.forEach((course) => {
              delete course.optionQuestions; // Remove tempArr field
            });
            objToPush["courses"] = this.createdFeedback.coursesWiseQuestions;
          } else {
            objToPush["allQuestions"] = this.createdFeedback.allQuestions;
          }
          if (this.isSaved) {
            if (this.createdFeedback.feedbackId !== undefined) {
              objToPush["feedbackId"] = this.createdFeedback.feedbackId;
              res = await this.feedbackRepositoryInstance.updateFeedback(
                objToPush
              );
            } else {
              objToPush["feedbackId"] = this.createdFeedback.formURL;
              res = await this.feedbackRepositoryInstance.createFeedback(
                objToPush
              );
            }
            if (res.status === 200) {
              objToPush["coursesWiseQuestions"] =
                res.feedback.courses || res.allQuestions;
              objToPush["tempQuestions"] =
                res.feedback.courses || res.allQuestions;

              this.$store.commit("liveData/set_createdFeedback", objToPush);
              showStatus("Successfully saved .", 2000, "success", this);
              this.isDataSaved = true;
              this.isNext = false;
              this.isSaved = false;
              this.isTab = false;
            } else {
              showStatus("Something went wrong", 2000, "error", this);
            }
          } else {
            objToPush["feedbackId"] = this.isDataSaved
              ? this.createdFeedback.formURL
              : this.createdFeedback.feedbackId;
            // objToPush["feedbackId"] =
            //   this.createdFeedback.feedbackId !== undefined
            //     ? this.createdFeedback.feedbackId
            //     : this.createdFeedback.formURL;
            objToPush["coursesWiseQuestions"] =
              objToPush.courses || objToPush.allQuestions;
            objToPush["coursesForScale"] =
              objToPush.courses || objToPush.allQuestions;

            await this.goToNextPage(objToPush);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    handleExistingQuestion(oldQuestions, newQuestions) {
      const updateQuestions = (newQuestionsArray, oldQuestionsArray) => {
        newQuestionsArray?.forEach((newQuestion) => {
          const matchingOldQuestion = oldQuestionsArray?.find(
            (oldQuestion) =>
              oldQuestion.questionType === newQuestion.questionType &&
              oldQuestion.questionNumber === newQuestion.questionNumber
          );

          if (matchingOldQuestion) {
            newQuestion.questionId = matchingOldQuestion.questionId;
            newQuestion.scales = matchingOldQuestion.scales;

            if (
              newQuestion.question?.length &&
              matchingOldQuestion.question?.length
            ) {
              updateQuestions(
                newQuestion.question,
                matchingOldQuestion.question
              );
            }
          }
        });
      };

      newQuestions?.forEach((newSet) => {
        const matchingOldSet = oldQuestions?.find(
          (oldSet) => oldSet.questionType === newSet.questionType
        );

        if (matchingOldSet) {
          updateQuestions(newSet.question, matchingOldSet.question);
        }
      });
    },
    handleExistingQuestionForTemplate(oldQuestions, newQuestions) {
      const updateQuestions = (newQuestionsArray, oldQuestionsArray) => {
        newQuestionsArray?.forEach((newQuestion) => {
          const matchingOldQuestion = oldQuestionsArray?.find(
            (oldQuestion) =>
              oldQuestion.questionType === newQuestion.questionType &&
              oldQuestion.questionNumber === newQuestion.questionNumber
          );

          if (matchingOldQuestion) {
            newQuestion.scales = matchingOldQuestion.scales;

            if (
              newQuestion.question?.length &&
              matchingOldQuestion.question?.length
            ) {
              updateQuestions(
                newQuestion.question,
                matchingOldQuestion.question
              );
            }
          }
        });
      };

      newQuestions?.forEach((newSet) => {
        const matchingOldSet = oldQuestions?.find(
          (oldSet) => oldSet.questionType === newSet.questionType
        );

        if (matchingOldSet) {
          updateQuestions(newSet.question, matchingOldSet.question);
        }
      });
    },
    async getFeedbackById() {
      try {
        if (this.createdFeedback.feedbackId) {
          let feedback =
            await this.feedbackRepositoryInstance.getFeedbackDetailsByFeedbackId(
              { feedbackId: this.createdFeedback.feedbackId }
            );
          return feedback[0];
        }
      } catch (e) {
        console.error(e);
      }
    },
    async goToNextPage(objToPush) {
      this.$store.commit("liveData/set_createdFeedback", objToPush);
      let isFilled = false;
      const questions = this.isCourseFB
        ? this.createdFeedback.courses[0].allQuestions
        : this.createdFeedback.allQuestions;

      isFilled = questions.length > 0;
      this.progressIndicator = isFilled ? 100 : 0;
      this.isDataSaved = false;

      if (this.isNext) {
        if (isFilled) {
          this.$emit("nextButtonClick");
        } else {
          showStatus("please fill the data", 9000, "error", this);
        }
      }
    },
    handleDeleteQuestionModal(index, questionsArray) {
      this.deleteIndex = index;
      this.deleteQuestionsArray = questionsArray;

      if (
        questionsArray[index].sectionNames &&
        questionsArray[index].question
      ) {
        this.deleteErrorMessage = `Deleting this ${questionsArray[index]?.question} section will also delete all related questions and sub-questions. Do you want to continue?`;
      } else if (
        questionsArray[index].subQnNo &&
        questionsArray[index].question
      ) {
        this.deleteErrorMessage = `Are you sure you want to delete this ${questionsArray[index]?.question} question?`;
      } else {
        this.deleteErrorMessage = `Deleting this ${questionsArray[index]?.question} question will also delete all related sub-questions. Do you want to continue?`;
      }

      this.deleteQuestionsModal = true;
    },
    handleCopyQuestionsModal(subject) {
      this.copyQuestionsModal = true;
      this.copiedSubjectDetail = subject;
    },
    handleCopyFromSubject() {
      if (this.copyFromSubject?.questionsArray?.length) {
        this.copiedSubjectDetail.questionsArray =
          this.copyFromSubject.questionsArray.flatMap((obj) => {
            const updatedObj = { ...obj };
            const newItems = [];

            if (updatedObj.sectionId) {
              updatedObj.sectionId = v4();
              newItems.push({ ...updatedObj });
            }
            if (updatedObj.questionId) {
              updatedObj.questionId = v4();
              newItems.push({ ...updatedObj });
            }
            if (updatedObj.subQuestionId) {
              updatedObj.subQuestionId = v4();
              newItems.push({ ...updatedObj });
            }

            return newItems;
          });

        showStatus(
          `${this.copyFromSubject.subjectName} data copied successfully.`,
          2000,
          "success",
          this
        );
      } else {
        showStatus(`No data is present to copy.`, 2000, "error", this);
      }
      this.copyQuestionsModal = false;
      this.copiedSubjectDetail = {};
      this.copyFromSubject = "";
    },
  },
};
</script>

<style src="./activityQuestions.css" scoped></style>

<template src="./activityScale.html"></template>

<script>
import NewFeedbackFormRepository from "../../../../Repository/Feedbackform";
import showStatus from "../../../../NetworkManager/showStatus";

export default {
  name: "activityScale",
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      lowestNo: "",
      highestNo: "",
      scales: [],
      tableHeaders: [
        { text: "Question Number", value: "questionNumber", width: "5px" },
        { text: "Statement", value: "statement", width: "30px" },
        // { text: 'Question', value: 'questionStatement' },
        { text: "Type", value: "type", width: "10px" },
        { text: "scale/options", value: "scaleOrOptions", width: "10px" },
        { text: "Scale Lowest", value: "scaleLowest", width: "10px" },
        { text: "Scale highest", value: "scaleHighest", width: "10px" },
        { text: "No of Options", value: "noOfOptions", width: "10px" },
        {
          text: "Scale label / Option labels",
          value: "scaleLabel",
          width: "140px",
        },
      ],
      optionTableHeader: [
        "Question No",
        "Statement",
        "Type",
        "No. of Options",
        "Options",
      ],
      isScaleChange: undefined,
      templateData: null,
      templateId: null,
      scaleQuestions: [],
      optionQuestions: [],
    };
  },
  created() {
    this.NewFeedbackFormRepositoryInstance = new NewFeedbackFormRepository(
      this
    );
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.templateData =
      this.$store.getters["liveData/get_templateTabDependencies"];
    this.storeValues();
  },
  methods: {
    checkType(item) {
      this.clearData(item);
      if (item.selectedType === "Multiple Select") {
        item.selectedScaleOrOption = "Options";
      } else if (item.selectedType === "Single Select") {
        item.selectedScaleOrOption = "Scale";
      } else if (item.selectedType === "Rating") {
        item.selectedScaleOrOption = "Scale";
      }
    },
    clearData(item) {
      item.scaleHighest = 0;
      item.scaleLowest = 0;
      item.noOfOptions = 0;
      item.scaleLabel = [];
    },
    storeValues() {
      this.templateId = this.templateData.templateId;
      this.isScaleChange = this.templateData.isScaleChange;
      if (this.templateData?.scales !== undefined) {
        this.lowestNo = this.templateData?.scales?.lowestNo;
        this.highestNo = this.templateData?.scales?.highestNo;
        this.scales = this.templateData?.scales.scales;
      }
      this.optionQuestions = [];
      for (let i = 0; i < this.templateData.allQuestions?.length; i++) {
        let templateQuestions = this.templateData.allQuestions[i];
        for (let j = 0; j < templateQuestions?.question?.length; j++) {
          for (
            let k = 0;
            k < templateQuestions?.question[j]?.question?.length;
            k++
          ) {
            this.optionQuestions.push({
              questionNumber:
                templateQuestions?.question[j]?.question[k]?.questionNumber,
              statement:
                templateQuestions?.question[j]?.question[k]?.questionStatement,
              questionId:
                templateQuestions?.question[j]?.question[k]?.questionId,
              type: [
                "Multiple Select",
                "Single Select",
                // "Short Answer",
                // "Long Answer",
                // "Rating",
              ],
              scaleOrOptions: ["Options", "Scale"],
              scaleLowest:
                templateQuestions?.question[j]?.question[k]?.scales?.lowestNo ||
                0,
              scaleHighest:
                templateQuestions?.question[j]?.question[k]?.scales
                  ?.highestNo || 0,
              noOfOptions:
                templateQuestions?.question[j]?.question[k]?.scales
                  ?.noOfOptions || 0,
              scaleLabel:
                templateQuestions?.question[j]?.question[k]?.scales?.scale ||
                [],
              selectedType:
                templateQuestions?.question[j]?.question[k]?.scales
                  ?.selectedType || "",
              selectedScaleOrOption:
                templateQuestions?.question[j]?.question[k]?.scales
                  ?.selectedScaleOrOption || "",
            });
          }
        }
      }
    },
    addOptions(item, i) {
      let arr = [];
      let length;
      let selectedNo;
      if (item?.selectedScaleOrOption === "Options") {
        length = Number(item?.noOfOptions);
        selectedNo = item?.noOfOptions;
      }
      if (
        item?.selectedScaleOrOption === "Scale" &&
        item?.scaleHighest > item?.scaleLowest
      ) {
        length = Number(item?.scaleHighest);
        selectedNo = item?.scaleHighest;
      }

      if (selectedNo && (selectedNo >= item?.scaleLabel?.length || 0)) {
        for (let i = item?.scaleLabel?.length || 0; i < length; i++) {
          arr.push({
            scaleNumber: i + 1,
            scaleLabel: "",
          });
        }
        item.scaleLabel = item.scaleLabel.concat(arr);
        this.$set(this.optionQuestions, i, item);
      } else {
        item.scaleLabel = item.scaleLabel.slice(0, length);
        this.$set(this.optionQuestions, i, item);
      }
    },
    updateQuestions(questions, optionQuestions) {
      // Recursively iterate through questions
      questions.forEach((question) => {
        // Update scales if questionId matches
        optionQuestions.forEach((option) => {
          if (option.questionId === question.questionId) {
            question.scales = {
              scale: option.scaleLabel,
              selectedType: option.selectedType,
              selectedScaleOrOption: option.selectedScaleOrOption,
            };

            if (option.selectedScaleOrOption === "Scale") {
              question.scales.lowestNo = option.scaleLowest;
              question.scales.highestNo = option.scaleHighest;
            } else if (option.selectedScaleOrOption === "Options") {
              question.scales.noOfOptions = option.noOfOptions;
            }
          }
        });

        // Recursively update nested questions if they exist
        if (question.question && Array.isArray(question.question)) {
          this.updateQuestions(question.question, optionQuestions);
        }
      });
    },
    storeScaleOrOptions() {
      if (Array.isArray(this.templateData.allQuestions)) {
        this.updateQuestions(
          this.templateData.allQuestions,
          this.optionQuestions
        );
      }
      this.addQuestionToFeedback();
    },
    async addQuestionToFeedback() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          templateId: this.templateId,
          allQuestions: this.templateData.allQuestions,
        };
        const response =
          await this.NewFeedbackFormRepositoryInstance.createTemplates(
            this.templateData
          );
        if (response.status.status === 200) {
          showStatus("Successfully saved.", 2000, "success", this);
        } else {
          showStatus("Something went wrong", 2000, "error", this);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style src="./activityScale.css"></style>

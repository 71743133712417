<template src="./activityGenerateLink.html"></template>

<script>
import courses from "../../../ApiNames/courses.js";
import showStatus from "../../../NetworkManager/showStatus.js";
import Feedback from "../../../Repository/Feedback.js";
import UserRepository from "../../../Repository/User.js";
import SemesterUserRepository from "../../../Repository/SemesterUser.js";
import RedisRepository from "../../../Repository/StoreDataToRedis.js";
import moment from "moment";
import FeedbackResponse from "../../../Repository/FeedbackResponse.js";

export default {
  name: "activityGenerateLink",
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      progressIndicator: 0,
      selectedInstitute: "",
      uploadQuestionsViaExcel: false,
      generatedIcon: "mdi-content-copy",
      isCopied: false,
      createdFeedback: {},
      allCosPos: [],
      uIds: [],
      emailToSend: [],
      feedbackURL: "",
      isPublish: true,
      isPublishFB: false,
      sendEmailModal: false,
      isDraft: null,
      isBackdated: null,
      isvalid: null,
      isSuccesed: null,
      isSendEmail: false,
      senderMail: "",
      dataToSendEmail: [],
      studentsWithResponse: [],
      senderMailPassword: "",
      selected: [],
      headers: [
        { text: "PRN No", value: "collegePRNNo" },
        { text: "Student Name", value: "studentName" },
        { text: "Status", value: "submissionStatus" },
      ],
    };
  },
  props: [
    "props_isTab",
    "props_selectedFeedback",
    "props_selections",
    "props_isNextClick",
    "props_conduction",
  ],
  async created() {
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.senderMail = this.$store.getters["liveData/get_senderMail"];
    this.senderMailPassword =
      this.$store.getters["liveData/get_senderMailPassword"];
    this.feedbackRepositoryInstance = new Feedback(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.redisRepositoryInstance = new RedisRepository(this);
    this.feedbackResponseRepositoryInstance = new FeedbackResponse(this);
    this.createdFeedback = this.$store.getters["liveData/get_createdFeedback"];
    this.allCosPos = this.$store.getters["liveData/get_allPosForFeedback"];
    this.isBackdated =
      this.createdFeedback?.conductionDetails === "Backdated" ? true : false;
    this.isDraft = this.createdFeedback?.status === "Draft" ? true : false;
    this.feedbackURL = `https://feedbacks.studiumtech.in/${this.createdFeedback.formURL}`;
    // this.feedbackURL = `https://alpha-student-feedback-git-featfeedbackurl-studium-tech-pvt-ltd.vercel.app/${this.createdFeedback.formURL}`;
    if (
      this.props_selectedFeedback === undefined &&
      this.createdFeedback.conductionDetails === "Backdated"
    ) {
      this.isPublish = true;
    }
    
    const { stakeholder, formType, courses, allQuestions } =
      this.createdFeedback;

    if (stakeholder === "Students") {
      this.allQuestions =
      formType === "Program Feedback" ? allQuestions || [] : courses;
      
      await this.fetchStudents();
    } else {
      await this.fetchFaculties();
    }
    this.isvalid = this.validateQuestions();
  },
  watch: {
    isCopied: function resetIcon() {
      setTimeout(() => {
        this.isCopied = false;
        this.generatedIcon = "mdi-content-copy";
      }, 2000);
    },
    async props_isTab() {
      this.createdFeedback =
        this.$store.getters["liveData/get_createdFeedback"];
      this.isBackdated =
        this.createdFeedback?.conductionDetails === "Backdated" ? true : false;
      this.isvalid = this.validateQuestions();

      const { stakeholder, formType, courses, allQuestions } =
        this.createdFeedback;

      if (stakeholder === "Students") {
        this.allQuestions =
          formType === "Program Feedback" ? allQuestions || [] : courses;

        await this.fetchStudents();
      } else {
        await this.fetchFaculties();
      }
    },
    async props_isNextClick() {
      this.createdFeedback =
        this.$store.getters["liveData/get_createdFeedback"];
      this.isBackdated =
        this.createdFeedback?.conductionDetails === "Backdated" ? true : false;
      this.isvalid = this.validateQuestions();
      const { stakeholder, formType, courses, allQuestions } =
        this.createdFeedback;

      if (stakeholder === "Students") {
        this.allQuestions =
          formType === "Program Feedback" ? allQuestions || [] : courses;

        await this.fetchStudents();
      } else {
        await this.fetchFaculties();
      }
    },
  },
  methods: {
    validateQuestions() {
      const checkQuestions = (questions) =>
        questions?.length > 0 &&
        questions.every(
          (q) =>
            q.questionStatement?.trim() !== "" && // Check if questionStatement is not empty
            q.scales?.scale?.length > 0 // Check if scales.scale is not empty
        );

      if (this.createdFeedback?.formType === "Course Feedback") {
        return this.createdFeedback?.courses?.every((obj) =>
          checkQuestions(obj.allQuestions)
        );
      }
      return checkQuestions(this.createdFeedback?.allQuestions);
    },
    hasEmptyCos() {
      if (this.createdFeedback.formType === "Course Feedback") {
        return this.allCosPos?.some((course) => course.cos.length === 0);
      } else {
        return this.allCosPos?.length === 0;
      }
    },
    publishCourses() {
      if (this.hasEmptyCos()) {
        this.isPublishFB = true;
        this.isSuccesed = false;
      } else {
        this.isPublishFB = true;
        this.isSuccesed = true;
      }
    },
    copyGenearatedLink() {
      navigator.clipboard.writeText(this.feedbackURL);
      this.isCopied = true;
      if (this.isCopied) {
        this.generatedIcon = "mdi-check-circle";
      }
    },
    async fetchStudents() {
      this.isResponseLoading = true;
      try {
        let facultyId = [];
        let students = [];

        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.createdFeedback.semId,
          department: this.createdFeedback.department,
          courseYear: this.createdFeedback.courseYear,
        };
        if (this.createdFeedback.formType === "Program Feedback") {
          objToPush.multiDIvision = this.createdFeedback.divisions;
          students =
            await this.semesterUserRepositoryInstance.getStudentsOfADivision(
              objToPush
            );
        } else {
          objToPush.subjectIds = this.createdFeedback.courses?.map(
            (item) => item.subjectId
          );
          students =
            await this.semesterUserRepositoryInstance.getStudentsOfSubjects(
              objToPush
            );
        }
        if (students.length > 0) {
          facultyId = students.map((student) => student.uId);

          this.facultiesNameWithUid =
            await this.userRepositoryInstance.getFullNameOfUsers({
              uIds: facultyId,
            });

          const facultyDetailsMap = new Map();
          this.facultiesNameWithUid.forEach((faculty) => {
            facultyDetailsMap.set(faculty.uId, {
              studentName: faculty.fullName,
              collegePRNNo: faculty.collegePRNNo,
              email: faculty.email,
              uId: faculty.uId,
            });
          });

          this.allStudents = students
            .filter(
              (student) => facultyDetailsMap.get(student.uId)?.collegePRNNo
            )
            .map((student) => ({
              ...student,
              ...facultyDetailsMap.get(student.uId),
            }));
        } else {
          showStatus("Students are not present", 2000, "error", this);
        }

        this.isResponseLoading = false;
        await this.getResponse();

        this.sendEmail();
      } catch (err) {
        console.error(err);
        this.allStudents = [];
      }
    },
    async fetchFaculties() {
      this.isResponseLoading = true;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          department: this.createdFeedback.department,
        };
        // Fetch Faculties
        this.students =
          await this.semesterUserRepositoryInstance.getAllFacultiesOfADepartment(
            objToPush
          );
        if (this.students?.length > 0) {
          const facultyDetailsMap = new Map();
          this.facultiesNameWithUid.forEach((faculty) => {
            facultyDetailsMap.set(faculty.uId, {
              studentName: faculty.fullName,
              email: faculty.email,
              uId: faculty.uId,
            });
          });
          this.students = this.students.map((student) => ({
            ...student,
            ...facultyDetailsMap.get(student.uId),
          }));
          this.isResponseLoading = false;
        } else {
          showStatus("Faculties are not present", 2000, "error", this);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getResponse() {
      try {
        let feedback =
          await this.feedbackResponseRepositoryInstance.getResponse({
            feedbackId: this.createdFeedback.feedbackId,
          });

        const uIdSet = new Set();

        const uniqueArr = feedback.feedbacks.filter((obj) => {
          if (!uIdSet.has(obj.uId)) {
            uIdSet.add(obj.uId);
            return true;
          }
          return false;
        });
        // studentsWithResponse
        this.dataToSendEmail = this.allStudents.filter(
          (obj) => !uIdSet.has(obj.uId)
        );
        this.selected = this.dataToSendEmail;
      } catch (e) {
        console.error(e);
      }
    },
    async sendEmail() {
      try {
        this.dataToSendEmail.map((obj) => {
          if (this.createdFeedback.formType === "Course Feedback") {
            this.emailToSend.push({
              host: "smtp.gmail.com",
              senderMail:
                this.senderMail !== ""
                  ? this.senderMail
                  : "no.reply@studiumtech.in",
              senderMailPassword:
                this.senderMailPassword !== ""
                  ? this.senderMailPassword
                  : "keeagdcbijxenstz",
              subject: `${this.createdFeedback.formType} Link for ${this.createdFeedback.formTitle}`,
              body: `
                  <html>
                    <body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
                      <div style="max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ddd; border-radius: 8px; background-color: #f9f9f9;">
                        <h3 style="color: #555;">Dear ${obj.studentName},</h3>
                        <p>We would appreciate it if you could take a moment to share your feedback using the link below:</p>
                        <p>
                          <a href="${
                            this.feedbackURL
                          }" target="_blank" style="color: #1a73e8; text-decoration: none;">
                            Feedback Link
                          </a>
                        </p>
                        <p>Your input is important to us and will help improve your learning experience. Please complete the form by <strong>${moment(
                          this.createdFeedback.endDate
                        ).format("DD-MM-YYYY")}</strong>.</p>
                        <p>Thank you for your time and valuable insights!</p>
                        <p style="margin-top: 20px;">Best regards,</p>
                        <p><strong>Studium Tech Pvt. Ltd</strong></p>
                      </div>
                    </body>
                  </html>
                `,
              instituteId: obj.instituteId,
              email: obj.email,
            });
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    async publishFeedback() {
      try {
        let res = {};
        const objToPush = {
          instituteId: this.createdFeedback.instituteId,
          semId: this.createdFeedback.semId,
          conductionDetails: this.createdFeedback.conductionDetails,
          courseYear: this.createdFeedback.courseYear,
          divisions: this.createdFeedback.divisions,
          department: this.createdFeedback.department,
          startDate: this.createdFeedback.startDate,
          endDate: this.createdFeedback.endDate,
          extendedDate: this.createdFeedback.extendedDateTime,
          stakeholder: this.createdFeedback.stakeholder,
          formDescription: this.createdFeedback.formDescription,
          formTitle: this.createdFeedback.formTitle,
          formType: this.createdFeedback.formType,
          formURL: this.createdFeedback.formURL,
          responseType: this.createdFeedback.responseType,
          threshold: this.createdFeedback.threshold,
          selectedTypeOfSurvey: this.createdFeedback.selectedTypeOfSurvey,
          setting: this.createdFeedback.setting,
          stakeHolderDetails: this.createdFeedback.stakeHolderDetails,
          isSendEmail: this.isSendEmail || false,
          emailToSend: this.isSendEmail ? this.selected : [],
          status: "Published",
        };
        
        if (this.createdFeedback?.formType === "Course Feedback") {
          const courses = this.createdFeedback.courses;
          courses?.forEach((course) => {
            delete course.optionQuestions;
          });

          objToPush["courses"] = courses;
        } else {
          objToPush["allQuestions"] = this.createdFeedback.allQuestions;
        }
        if (this.createdFeedback.feedbackId !== undefined) {
          objToPush["feedbackId"] = this.createdFeedback.feedbackId;
          res = await this.feedbackRepositoryInstance.updateFeedback(objToPush);
        } else {
          res = await this.feedbackRepositoryInstance.createFeedback(objToPush);
        }
        if (res.status === 200) {
          this.$store.commit("liveData/set_createdFeedback", res.feedback);
          if (!this.isSendEmail) {
            showStatus("Successfully saved.", 2000, "success", this);
            this.$router.push("/activityFeedbackFormRevamped");
          } else {
            this.sendEmailModal = false;
            showStatus("Remainder Send.", 2000, "success", this);
          }
          this.isNext = false;
        } else {
          showStatus("Something went wrong", 2000, "error", this);
        }
        this.createdFeedback =
          this.$store.getters["liveData/get_createdFeedback"];
      } catch (e) {
        console.error(e);
      }
    },
    async goToNextPage() {
      this.$emit("nextButtonClick");
    },
  },
};
</script>

<style src="./activityGenerateLink.css" scoped></style>

const fs = require("fs");

const fileOps = {
  jsonFileToJsonObject(filePath) {
    let jsonData = {};
    try {
      // Read the file synchronously
      const data = fs.readFileSync(filePath, "utf-8");

      // Parse the JSON data
      jsonData = JSON.parse(data);
      console.log("JSON data:", jsonData);
    } catch (err) {
      console.error("Error reading or parsing the file:", err);
    }
    return jsonData;
  },
};

export default fileOps;

import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/FeedbackResponse";

class FeedbackResponse {
  constructor(context) {
    this.context = context;
  }
  async createResponse(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createResponse,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
  async getResponse(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.getRequest(
      ApiNames.getResponse,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
  async getConductedFeedbackCount(data) {
    this.context.$store.commit("liveData/set_loading", true);
    console.log('data>>>>>>>>>>>>>>', data);
    const response = await networkManager.getRequest(
      ApiNames.getConductedFeedbackCount,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
}
export default FeedbackResponse;

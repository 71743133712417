
<template src="./activityAnalysis.html"></template>

<script>
import VueApexCharts from "vue-apexcharts";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import UserRepository from "../../../Repository/User";
import FeedbackResponse from "../../../Repository/FeedbackResponse.js";

export default {
  name: "activityAnalysis",
  components: {
    apexcharts: VueApexCharts,
  },
  computed: {
    chartSeries() {
      return [
        {
          name: "Responses",
          data: [this.totalExpected, this.totalObtained],
        },
      ];
    },
  },
  watch: {
    selectedSubject(newVal) {
      this.selectedVal = newVal;
      this.createdFeedback.courses[newVal].allQuestions;
      this.createdFeedback.courses[newVal].onlyQuestions =
        this.createdFeedback.courses[newVal].allQuestions;
      this.getResponse();
    },
  },
  data() {
    return {
      totalExpected: 0,
      totalObtained: 0,
      totalUnobtained: 0,
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: ["Total Expected", "Total Obtained"],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "25%",
          },
        },
        colors: ["#060E9A", "#0DA5A5"],
        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: "#e7e7e7",
        },
      },
      series: [],
      barChartOptions: {},
      progress: 0,
      selectedSubject: 0,
      selectedVal: 0,
      feedbacksResponse: [],
      students: [],
      allQuestions: [],
      allStudents: [],
      facultiesNameWithUid: [],
      totalQuestions: 0,
      formCompositionAnalysis: {},
      isResponseLoading: false,
      isLoading: false,
    };
  },
  async created() {
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.feedbackResponseRepositoryInstance = new FeedbackResponse(this);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.createdFeedback = this.$store.getters["liveData/get_createdFeedback"];
    this.feedbackTopSelections =
      this.$store.getters["liveData/get_feedbackTopSelections"];

    if (!this.createdFeedback?.feedbackId) return;

    const { stakeholder, formType, courses, allQuestions } =
      this.createdFeedback;

    if (stakeholder === "Students") {
      this.allQuestions =
        formType === "Program Feedback" ? allQuestions || [] : courses;

      await this.fetchStudents();
    } else {
      await this.fetchFaculties();
    }
  },
  methods: {
    async fetchStudents() {
      this.isResponseLoading = true;
      try {
        let facultyId = [];
        let students = [];

        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.feedbackTopSelections.semester.semId,
          department: this.createdFeedback.department,
          courseYear: this.createdFeedback.courseYear,
        };
        if (this.createdFeedback.formType === "Program Feedback") {
          objToPush.multiDIvision = this.createdFeedback.divisions;
          
          students =
            await this.semesterUserRepositoryInstance.getStudentsOfADivision(
              objToPush
            );
        } else {
          objToPush.subjectIds = this.createdFeedback.courses?.map(
            (item) => item.subjectId
          );
          students =
            await this.semesterUserRepositoryInstance.getStudentsOfSubjects(
              objToPush
            );
        }
        if (students.length > 0) {
          facultyId = students.map((student) => student.uId);

          this.facultiesNameWithUid =
            await this.userRepositoryInstance.getFullNameOfUsers({
              uIds: facultyId,
            });

          const facultyDetailsMap = new Map();
          this.facultiesNameWithUid.forEach((faculty) => {
            facultyDetailsMap.set(faculty.uId, {
              studentName: faculty.fullName,
              collegePRNNo: faculty.collegePRNNo,
              email: faculty.email,
              uId: faculty.uId,
            });
          });

          this.allStudents = students
            .filter(
              (student) => facultyDetailsMap.get(student.uId)?.collegePRNNo
            )
            .map((student) => ({
              ...student,
              ...facultyDetailsMap.get(student.uId),
            }));
        } else {
          showStatus("Students are not present", 2000, "error", this);
        }
        await this.getResponse();
        this.isResponseLoading = false;
      } catch (err) {
        console.error(err);
        this.allStudents = [];
      }
    },
    async fetchFaculties() {
      this.isResponseLoading = true;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          department: this.createdFeedback.department,
        };
        // Fetch Faculties
        this.students =
          await this.semesterUserRepositoryInstance.getAllFacultiesOfADepartment(
            objToPush
          );
        if (this.students.length > 0) {
          const facultyDetailsMap = new Map();
          this.facultiesNameWithUid.forEach((faculty) => {
            facultyDetailsMap.set(faculty.uId, {
              studentName: faculty.fullName,
              email: faculty.email,
              uId: faculty.uId,
            });
          });
          this.students = this.students.map((student) => ({
            ...student,
            ...facultyDetailsMap.get(student.uId),
          }));
          await this.getResponse();
          this.isResponseLoading = false;
        } else {
          showStatus("Faculties are not present", 2000, "error", this);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getResponse() {
      try {
        let feedback =
          await this.feedbackResponseRepositoryInstance.getResponse({
            feedbackId: this.createdFeedback.feedbackId,
          });
        await this.filteredStudents(feedback.feedbacks);
      } catch (e) {
        console.error(e);
      }
    },
    async filteredStudents(feedbackResponse) {
      if (this.createdFeedback.formType === "Program Feedback") {
        this.students = this.allStudents;
        this.feedbacksResponse = feedbackResponse;
      } else {
        const selectedSubjectId =
          this.allQuestions[this.selectedVal]?.subjectId || null;

        if (selectedSubjectId) {
          this.students = this.allStudents.filter((student) =>
            student.subjects.includes(selectedSubjectId)
          );
          this.feedbacksResponse =
            feedbackResponse?.filter(
              (feedback) => feedback.subjectId === selectedSubjectId
            ) || [];
        } else {
          this.students = [];
          this.feedbacksResponse = [];
        }
        this.selectedSubjectId = selectedSubjectId;
      }
      await this.calculateAnalysis(this.feedbacksResponse, this.students);
    },
    async calculateAnalysis(feedbacksResponse, students) {
      this.isLoading = true;
      // #1 Responses Analysis
      this.totalExpected = students.length;
      this.totalObtained = feedbacksResponse.length;
      this.totalUnobtained = this.totalExpected - this.totalObtained;

      if (this.createdFeedback.formType === "Program Feedback") {
        // #2 Form Composition Analysis
        this.totalQuestions = this.allQuestions.length;

        const counts = this.calculateQuestionCounts(this.allQuestions);
        this.updateCountsWithPercentageAndColor(counts);
        this.formCompositionAnalysis = counts;
        // #3 Responses Analysis
        this.responseAnalysis(feedbacksResponse, this.allQuestions);
      } else {
        // #2 Form Composition Analysis
        const questions =
          this.allQuestions[this.selectedVal]?.allQuestions || [];

        this.totalQuestions = questions?.length || 0;

        const counts = this.calculateQuestionCounts(questions);
        this.updateCountsWithPercentageAndColor(counts);
        this.formCompositionAnalysis = counts;

        // #3 Responses Analysis
        this.responseAnalysis(feedbacksResponse, questions);
      }
      this.isLoading = false;
    },
    calculateQuestionCounts(questions) {
      return questions.reduce((acc, item) => {
        const type = item.scales.selectedType;
        acc[type] = (acc[type] || 0) + 1;
        return acc;
      }, {});
    },
    updateCountsWithPercentageAndColor(counts) {
      Object.keys(counts)?.forEach((type) => {
        counts[type] = {
          count: counts[type],
          percentage:
            ((counts[type] / this.totalQuestions) * 100).toFixed(2) + "%",
          color: this.generateRandomColor(),
        };
      });
    },
    responseAnalysis(feedbacksResponse, questions) {
      let allQuestionNumbers = [];
      questions.map((obj) => {
        const type = obj.scales.selectedType;
        if (type === "Multiple Select" || type === "Single Select") {
          allQuestionNumbers.push(obj.questionNumber);
        }
      });

      // #barChartOptions
      this.series = this.generateResponseAnalysis(
        this.createdFeedback.formType,
        feedbacksResponse,
        questions
      );
      this.barChartOptions = {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            borderRadiusApplication: "end",
            columnWidth: "40%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: allQuestionNumbers,
        },
        colors: ["#060E9A", "#0DA5A5", "#FF7800", "#006438"],
        legend: {
          show: false,
        },
      };
    },
    generateResponseAnalysis(selectedType, feedbackResponse, questions) {
      // Map each question to its series data
      const series = questions.map(
        ({ questionNumber, scales: { scale }, scales: { selectedType } }) => {
          const scaleLabels = scale.map((s) => s.scaleLabel); // Extract scale labels
          const scaleData = scaleLabels.map(() => 0); // Initialize counts for each scale

          // Count occurrences of each scale option
          feedbackResponse.forEach(({ studentResponse }) => {
            const response = studentResponse.find(
              (resp) => resp.questionNumber === questionNumber
            );

            if (response) {
              if (selectedType === "Single Select") {
                // For Single Select, consider answer as a string
                const index = scaleLabels.indexOf(response.answer);
                if (index !== -1) scaleData[index]++;
              } else if (
                selectedType === "Multiple Select" &&
                Array.isArray(response.answer)
              ) {
                // For Multiple Select, consider answer as an array
                response.answer.forEach((ans) => {
                  const index = scaleLabels.indexOf(ans);
                  if (index !== -1) scaleData[index]++;
                });
              }
            }
          });

          return { data: scaleData }; // Return series entry for the question
        }
      );

      const maxLength = Math.max(...series.map(({ data }) => data.length)); // Determine max data length

      // filling missing values with null
      return Array.from({ length: maxLength }, (_, i) => ({
        data: series.map(({ data }) => data[i] ?? null),
      }));
    },
    generateRandomColor() {
      try {
        const randomNumber = Math.floor(Math.random() * 16777215);

        const randomColor = randomNumber.toString(16).padStart(6, "0");

        return `#${randomColor}`;
      } catch (error) {
        console.error("Error generating random color:", error);
        return "#000000";
      }
    },
  },
};
</script>
<style src="./activityAnalysis.css" scoped></style>

<template>
  <div :style="parentStyle" class="icon-wrapper">
    <v-icon
      :color="color"
      :disabled="disabled"
      :ripple="ripple"
      :style="iconStyle"
      @click="onClick"
      class="base-icon"
    >
      {{ validIcon }}
    </v-icon>
  </div>
</template>
  
  <script>
import { theme } from "../../../../Constants/Utils/Theme";
import { icons } from "../../../../Constants/Utils/Icons";

export default {
  name: "BaseIcon",
  props: {
    icon: {
      type: String,
      required: true,
      default: icons.borderNoneVariant,
    },
    color: {
      type: String,
      default: theme.primary, // Default color
    },
    ripple: {
      type: Boolean,
      default: false, // Enable ripple effect by default
    },
    iconStyle: {
      type: Object,
      default: () => ({}), // Custom styles for v-icon
    },
    parentStyle: {
      type: Object,
      default: () => ({}), // Custom styles for the parent div
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validIcon: this.validateIcon(this.icon),
    };
  },
  watch: {
    icon(newIcon) {
      this.validIcon = this.validateIcon(newIcon);
    },
  },
  methods: {
    validateIcon(icon) {
      const path = "/Constants/Utils/Icons";
      const error = `${icon} is not a registered icon. Please register it here: ${path}.`;

      if (!icon) {
        console.error(error);
        return icons.borderNoneVariant;
      }

      // Determine if the icon is a material design icon (mdi) or a custom icon
      const iconValues = Object.values(icons);
      const iconKeys = Object.keys(icons);

      // Check if the icon is a valid "mdi" icon or a custom key from the icons object
      const isMdiIcon = icon.includes("mdi");
      const isValidMdiIcon = isMdiIcon && iconValues.includes(icon);
      const isValidCustomIcon = !isMdiIcon && iconKeys.includes(icon);

      if (isValidMdiIcon) {
        return icon;
      } else if (isValidCustomIcon) {
        return icons[icon];
      } else {
        console.error(error);
        return icons.borderNoneVariant; // Default fallback icon
      }
    },
    onClick(event) {
      this.$emit("click", event); // Emit click event to parent
    },
  },
};
</script>
  
  <style scoped>
.icon-wrapper {
  display: inline-block;
  /* Allow the wrapper to behave like inline element */
}

.base-icon {
  cursor: pointer;
}
</style>
  
<template src="./activityCourseAnalysisRevamped.html"></template>
<script>
import VueApexCharts from "vue-apexcharts";
import SemesterUserRepository from "../../Repository/SemesterUser";
import UserRepository from "../../Repository/User";
import CoursesRepository from "../../Repository/courses";
import CourseRepository from "../../Repository/courses";
import UserAuthentication from "../../Repository/UserAuthentication";
import { createExcelThroughJson } from "../../utils/excel";
export default {
  name: "activityCourseAnalysisRevamped",
  props: ["prop_subject", "prop_selectedSemester"],
  components: {
    apexcharts: VueApexCharts,
  },
  data() {
    return {
      activeScreenTab: "Student Wise",
      downloadExcelLoading: false,
      chartOptions: {
        series: [0, 0, 1],
        colors: ["#04BDBD", "rgba(255, 202, 12, 0.80)", "#FF6F6F"],
        labels: ["Completed", "Ongoing", "Not Started"],
        chart: {
          type: "pie",
          animations: {
            enabled: false, // Disable animations
          },
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            dataLabels: {
              offset: 0,
            },
          },
        },
      },
      headers: [
        { text: "Roll No.", value: "rollNo" },
        { text: "Student Name", value: "firstName" },
        { text: "Progress", value: "progressPercentage" },
        { text: "Status", value: "status" },
        { text: "Contact Number", value: "mobile" },
        { text: "Email ID", value: "email" },
        { text: "Action", value: "actions" },
      ],
      userData: [],
      uidArr: [],
      //Module-Wise
      activeModule: 0,
      subsectionStatusModal: false,
      activeSubsectionModal: {},
      completionModalArr: [],
      filteredCompletionModalArr: [],
      seacrhStudentField: "",
      isSubsectionModalLoading: true,
      completionProgress: {},
      courseCompletionProgress: [0, 0, 1],
      //Feedback
      totalReviews: 0,
      ratingSegregation: {},
      averageRating: 0,
      ratingSegregationPercentage: {},
      ratingModal: false,
      searchRatingStudent: null,
      idMapToUser: {},
      feedbackModalArr: [],
      feedbackModalFilteredArr: [],
      allSemUsers: [],
      showCourseNotPublishedModal: false,
    };
  },
  async created() {
    this.$store.commit(
      "liveData/set_selectedActivityName",
      this.prop_subject.courseName
    );
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.coursesRepositoryInstance = new CoursesRepository(this);
    this.CourseRepositoryInstance = new CourseRepository(this);
    this.userAuthenticationRepositoryInstance = new UserAuthentication(this);
    if (this.prop_subject.sections.length === 0) {
      this.showCourseNotPublishedModal = true;
      this.prop_subject.sections.subsections = [];
    }
    this.getStudentsData();
    this.getCourseRatingDetails();
  },
  methods: {
    async changeActiveScreenTab(screen) {
      this.activeScreenTab = screen;
    },
    async getStudentsData() {
      const semUsers =
        await this.semesterUserRepositoryInstance.getSemesterUsersOfASubject({
          instituteId: this.prop_subject.instituteId,
          semId: this.prop_selectedSemester.semId,
          subjectId: this.prop_subject.subjectId,
        });
      this.allSemUsers = semUsers
      // console.log('this.allSemUsers', this.allSemUsers);
      let uIds = [];
      for (let i = 0; i < semUsers.length; i++) {
        uIds.push(semUsers[i].uId);
      }
      this.uidArr = uIds;
      const users = await this.userRepositoryInstance.getUsers({ uIds: uIds });
      let tempSubsections = 0;
      for (let i = 0; i < this.prop_subject.sections.length; i++) {
        let section = this.prop_subject.sections[i];
        if (
          section.isSectionPublished &&
          section.subSections &&
          Array.isArray(section.subSections)
        ) {
          section.subSections.forEach((subsec) => {
            if (subsec.isSubsectionPublished) tempSubsections += 1;
          });
        }
      }
      const { data } =
        await this.coursesRepositoryInstance.getProgressPercentageOfStudentsInCourse(
          {
            users: uIds,
            courseId: this.prop_subject.subjectId,
            totalSubsections: tempSubsections,
          }
        );

      users.forEach((usr) => {
        this.idMapToUser[usr.uId] = usr.firstName + " " + usr.lastName;
      });
      console.log('datadata', data, tempSubsections);
      this.completionProgress = data;
      Object.entries(this.completionProgress).forEach(([key, completion]) => {
        if (Number(completion) > 100) {
          this.completionProgress[key] = 100;
        }
      });
      Object.values(data).forEach((val) => {
        if (val == 100) this.courseCompletionProgress[0] += 1;
        else if (val != 0) this.courseCompletionProgress[1] += 1;
        else this.courseCompletionProgress[2] += 1;
      });
      this.courseCompletionProgress[2] -= 1;
      this.chartOptions.series = this.courseCompletionProgress;
      this.userData = users;
    },
    async goToCourseMaterial(user) {
      this.$router.push({
        name: "activityCourseMaterial",
        params: {
          prop_subject: this.prop_subject,
          prop_user: user,
          prop_instituteId: this.prop_subject.instituteId,
          prop_selectedSemester: this.prop_selectedSemester,
          prop_department: this.prop_subject.department,
          prop_courseYear: this.prop_subject.courseYear,
          prop_subjectId: this.prop_subject.subjectId,
          prop_courseName: this.prop_subject.courseName,
        },
      });
    },
    async getStatus(id) {
      if (this.completionProgress[id] === 0) return "Not Started";
      else if (this.completionProgress[id] != 100) return "Ongoing";
      else return "Completed";
    },
    async getSubsectionCompletionStatus(subsection) {
      this.isSubsectionModalLoading = true;
      try {
        const { data } =
          await this.coursesRepositoryInstance.getSubsectionCompletionStatus({
            users: this.uidArr,
            subSectionId: subsection.subSectionId,
            courseId: this.prop_subject.subjectId,
          });
        let targetArr = [];
        this.userData.forEach((user) => {
          targetArr.push({
            id: user.uId,
            isSectionCompleted: data[user.uId] ?? false,
            name: user.firstName + " " + user.lastName,
          });
        });
        this.completionModalArr = targetArr;
        this.filteredCompletionModalArr = targetArr;
        this.seacrhStudentField = null;
      } catch (e) {
        console.error(e);
        this.completionModalArr = [];
        this.filteredCompletionModalArr = [];
      } finally {
        this.isSubsectionModalLoading = false;
        this.subsectionStatusModal = true;
      }
    },
    // async handleDownloadExcel(subsection) {
    //   this.downloadExcelLoading = true
    //   try {
    //     const { data } =
    //       await this.coursesRepositoryInstance.getSubsectionCompletionStatus({
    //         users: this.uidArr,
    //         subSectionId: subsection.subSectionId,
    //         courseId: this.prop_subject.subjectId,
    //       });
    //     let targetArr = [];
    //     this.userData.forEach((user) => {
    //       targetArr.push({
    //         id: user.uId,
    //         isSectionCompleted: data[user.uId] ?? false,
    //         name: user.firstName + " " + user.lastName,
    //       });
    //     });
    //     this.completionModalArr = targetArr;
    //     this.filteredCompletionModalArr = targetArr;
    //     let temp = [];
    //     this.filteredCompletionModalArr.map((obj) => {
    //       if (obj.isSectionCompleted) {
    //         temp.push(obj);
    //       }
    //     });
    //     let objForUids = {
    //       uIds: [],
    //     };
    //     for (let i = 0; i < temp.length; i++) {
    //       objForUids.uIds.push(temp[i].id);
    //     }
    //     const fullNames = await this.userRepositoryInstance.getFullNameOfUsers(
    //       objForUids
    //     );
    //     let cnt = 0;
    //     const resp =await this.userAuthenticationRepositoryInstance.getUserAuthenticationsByUIds(objForUids)
    //     for(let i=0; i<temp.length; i++){
    //       let id = temp[i].id;
    //       let idx = resp.find((obj)=>obj.uId === id)
    //       if(idx){
    //         temp[i].userName = idx.email
    //       }
    //     }
    //     for(let i=0; i<temp.length; i++){
    //       let id = temp[i].id;
    //       let idx = fullNames.find((obj)=>obj.uId === id)
    //       if(idx){
    //         temp[i].email = idx.email
    //       }
    //     }

    //     const objToPush = {
    //       instituteId: this.prop_subject.instituteId,
    //       semId: this.prop_subject.semId,
    //       department: this.prop_subject.department,
    //       courseYear: this.prop_subject.courseYear,
    //       subjectId: this.prop_subject.subjectId,
    //       courseName: this.prop_subject.courseName,
    //       // uId: temp[0].id,
    //     };
    //     let x = 0;
    //     let filteredArray = [];
    //     for (let i = 0; i < temp.length; i++) {
    //       let obj = { ...objToPush };
    //       obj.uId = temp[i].id;
    //       const response =
    //         await this.CourseRepositoryInstance.getSyncCoursesDataOfSingleStudent(
    //           obj
    //         );
    //       let selectedSubsection = [];
    //       let tempSection = response.data.courseDetails.sections;
    //       for (let k = 0; k < tempSection.length; k++) {
    //         if (
    //           tempSection[k].subSections &&
    //           Array.isArray(tempSection[k].subSections)
    //         ) {
    //           for (let j = 0; j < tempSection[k].subSections.length; j++) {
    //             if (
    //               tempSection[k].subSections[j].subSectionId ==
    //               this.activeSubsectionModal.subSectionId
    //             ) {
    //               selectedSubsection = tempSection[k].subSections[j];
    //               let obj1 = {
    //                 First_name: temp[i].name.split(" ")[0],
    //                 Last_name: temp[i].name.split(" ")[1],
    //                 department: this.prop_subject.department,
    //                 Email_address:temp[i].email,
    //                 userName:temp[i].userName,
    //                 attempt_1:
    //                   selectedSubsection.studentAnswers?.[0]?.studentMarks ??
    //                   "-",
    //                 attempt_2:
    //                   selectedSubsection.studentAnswers?.[1]?.studentMarks ??
    //                   "-",
    //                 attempt_3:
    //                   selectedSubsection.studentAnswers?.[2]?.studentMarks ??
    //                   "-",
    //                 maximum_Marks: Math.max(
    //                   selectedSubsection.studentAnswers?.[0]?.studentMarks ?? 0,
    //                   selectedSubsection.studentAnswers?.[1]?.studentMarks ?? 0,
    //                   selectedSubsection.studentAnswers?.[2]?.studentMarks ?? 0
    //                 ),
    //               };

    //               filteredArray.push(obj1);
    //             }
    //           }
    //         }
    //       }
    //     }

    //     createExcelThroughJson(filteredArray, "quiz_report", "", "");
    //     this.downloadExcelLoading = false
    //   } catch (error) {
    //     console.error("error", error);
    //   }
    // },
    findDivisionById(id) {
      let div = ''
      if (this.allSemUsers.length > 0) {
        this.allSemUsers.map((info) => {
          if (info.uId === id) {
            div = info.division
          }
        })
      }
      return ''
    },
    async handleDownloadExcel(subsection) {
      this.downloadExcelLoading = true;
      try {
        const { data } =
          await this.coursesRepositoryInstance.getSubsectionCompletionStatus({
            users: this.uidArr,
            subSectionId: subsection.subSectionId,
            courseId: this.prop_subject.subjectId,
          });
        let targetArr = [];
        this.userData.forEach((user) => {
          targetArr.push({
            id: user.uId,
            isSectionCompleted: data[user.uId] ?? false,
            name: user.firstName + " " + user.lastName,
          });
        });
        this.completionModalArr = targetArr;
        this.filteredCompletionModalArr = targetArr;
        let temp = [];
        this.filteredCompletionModalArr.map((obj) => {
          if (obj.isSectionCompleted) {
            temp.push(obj);
          }
        });
        let objForUids = {
          uIds: [],
        };
        for (let i = 0; i < temp.length; i++) {
          objForUids.uIds.push(temp[i].id);
        }

        let fullNames, resp;
        try {
          fullNames = await this.userRepositoryInstance.getFullNameOfUsers(
            objForUids
          );
          resp =
            await this.userAuthenticationRepositoryInstance.getUserAuthenticationsByUIds(
              objForUids
            );
          for (let i = 0; i < temp.length; i++) {
            let id = temp[i].id;
            let idx = resp.find((obj) => obj.uId === id);
            if (idx) {
              temp[i].userName = idx.userName;
            }
          }
          for (let i = 0; i < temp.length; i++) {
            let id = temp[i].id;
            let idx = this.allSemUsers.find((obj) => obj.uId === id);
            if (idx) {
              temp[i].division = idx.division;
            }
          }
          for (let i = 0; i < temp.length; i++) {
            let id = temp[i].id;
            let idx = fullNames.find((obj) => obj.uId === id);
            if (idx) {
              temp[i].email = idx.email;
            }
          }
        } catch (error) {
          console.error(
            "Error fetching full names or user authentications:",
            error
          );
          return;
        }

        let filteredArray = [];
        for (let i = 0; i < temp.length; i++) {
          let objToPush = {
            instituteId: this.prop_subject.instituteId,
            semId: this.prop_subject.semId,
            department: this.prop_subject.department,
            courseYear: this.prop_subject.courseYear,
            subjectId: this.prop_subject.subjectId,
            courseName: this.prop_subject.courseName,
          };
          let obj = { ...objToPush };
          obj.uId = temp[i].id;
          // obj.division = this.findDivisionById(temp[i].id);
          try {
            const response =
              await this.CourseRepositoryInstance.getSyncCoursesDataOfSingleStudent(
                obj
              );
            let selectedSubsection = [];
            let tempSection = response.data.courseDetails.sections;
            for (let k = 0; k < tempSection.length; k++) {
              if (
                tempSection[k].subSections &&
                Array.isArray(tempSection[k].subSections)
              ) {
                for (let j = 0; j < tempSection[k].subSections.length; j++) {
                  if (
                    tempSection[k].subSections[j].subSectionId ==
                    this.activeSubsectionModal.subSectionId
                  ) {
                    selectedSubsection = tempSection[k].subSections[j];
                    let obj1 = {
                      First_name: temp[i].name.split(" ")[0],
                      Last_name: temp[i].name.split(" ")[1],
                      department: this.prop_subject.department,
                      Email_address: temp[i].email,
                      userName: temp[i].userName,
                      division: temp[i].division,
                      attempt_1:
                        selectedSubsection.studentAnswers?.[0]?.studentMarks ??
                        "-",
                      attempt_2:
                        selectedSubsection.studentAnswers?.[1]?.studentMarks ??
                        "-",
                      attempt_3:
                        selectedSubsection.studentAnswers?.[2]?.studentMarks ??
                        "-",
                      maximum_Marks: Math.max(
                        selectedSubsection.studentAnswers?.[0]?.studentMarks ??
                        0,
                        selectedSubsection.studentAnswers?.[1]?.studentMarks ??
                        0,
                        selectedSubsection.studentAnswers?.[2]?.studentMarks ??
                        0
                      ),
                    };
                    filteredArray.push(obj1);
                  }
                }
              }
            }
          } catch (error) {
            console.error("Error fetching course data:", error);
          }
        }

        createExcelThroughJson(filteredArray, "quiz_report", "", "");
        this.downloadExcelLoading = false;
      } catch (error) {
        console.error("error", error);
      }
    },

    handleFilterStudents() {
      if (this.seacrhStudentField !== null && this.seacrhStudentField !== "") {
        let tempFilteredArr = this.completionModalArr.filter((student) => {
          let test = student.name.toLocaleUpperCase();
          let inp = this.seacrhStudentField.toLocaleUpperCase();
          return test.startsWith(inp);
        });
        this.filteredCompletionModalArr = tempFilteredArr;
      } else this.filteredCompletionModalArr = this.completionModalArr;
    },
    handleFilterRatingStudents() {
      if (
        this.searchRatingStudent !== null &&
        this.searchRatingStudent !== ""
      ) {
        let tempFilteredArr = this.feedbackModalArr.filter((student) => {
          let test = student.toLocaleUpperCase();
          let inp = this.searchRatingStudent.toLocaleUpperCase();
          return test.startsWith(inp);
        });
        this.feedbackModalFilteredArr = tempFilteredArr;
      } else this.feedbackModalFilteredArr = this.feedbackModalArr;
    },
    openFeedbackStudentsModal(id) {
      let resArr = [];
      this.ratingSegregation[id].forEach((id) => {
        id.name = this.idMapToUser[id.uId];
        resArr.push(id);
      });
      this.feedbackModalArr = resArr;
      this.feedbackModalFilteredArr = resArr;
      this.ratingModal = true;
    },
    async getCourseRatingDetails() {
      const { data } =
        await this.coursesRepositoryInstance.getCourseRatingDetails({
          courseId: this.prop_subject.subjectId,
        });
      this.averageRating = data.averageRating;
      this.ratingSegregation = data.ratingSegregation;
      Object.keys(data.ratingSegregation).forEach((key) => {
        this.ratingSegregationPercentage[key] =
          (data.ratingSegregation[key].length / data.totalRatings) * 100;
      });
      this.totalReviews = data.totalRatings;
    },
    gotoactivityCourseQuizResult(uid) {
      this.$router.push({
        name: "activityQuizResult",
        params: {
          prop_subject: this.prop_subject,
          prop_uId: uid,
          prop_subSectionId: this.activeSubsectionModal.subSectionId,
          prop_selectedSemester: this.prop_selectedSemester,
        },
      });
    },
    gotoactivityCourseAssignmentCheck(uid, name) {
      this.$router.push({
        name: "activityCourseAssignmentCheck",
        params: {
          prop_subject: this.prop_subject,
          prop_uId: uid,
          prop_subSectionId: this.activeSubsectionModal.subSectionId,
          prop_selectedSemester: this.prop_selectedSemester,
          prop_userName: name,
        },
      });
    },
    gotoactivityCourseSubsectionFeedback(uid, name) {
      this.$router.push({
        name: "activityCourseSubsectionFeedback",
        params: {
          prop_subject: this.prop_subject,
          prop_uId: uid,
          prop_subSectionId: this.activeSubsectionModal.subSectionId,
          prop_selectedSemester: this.prop_selectedSemester,
          prop_userName: name,
        },
      });
    },
    goBack() {
      this.$router.push({
        name: "activityCourses2",
        params: {
          prop_selectedDepartment: this.prop_subject.department,
          prop_selectedSemester: this.prop_selectedSemester,
          prop_selectedCourseYear: this.prop_subject.courseYear,
        },
      });
    },
  },
};
</script>
<style src="./activityCourseAnalysisRevamped.css" scoped></style>
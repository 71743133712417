import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/Semester'

class Semester {
  constructor (context) {
    this.context = context
  }

  async createSemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createSemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semester
  }

  async createDepartment (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createDepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semester
  }

  async getSemesterNames(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getNamesOfSemesters, data)
    // console.log('response', response);
    this.context.$store.commit('liveData/set_loading', false)
    return response.namesOfSemesters
  }
  async getBatchesDetails(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getBatchesDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getMultipleSemesters(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getMultipleSemesters, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesters
  }
  async getSemestersBySemId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.getSemestersBySemId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesters
  }
  async getStudentsOfAInstitute (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStudentsOfAInstitute, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getStatus (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getStatus, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.status
  }
  async getCoursebySemesters(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getCoursebySemesters, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesters
  }
  async getClassbySemesters(data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getClassbySemesters, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getEditingPermissionOfASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getEditingPermissionOfASemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.permission
  }

  async getDivisionsOfASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getDivisionsOfASemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.divisions
  }

  async getDatesOfADivisionOfASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getDatesOfADivisionOfASemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return { startDate: response.startDate, endDate: response.endDate }
  }

  async getShiftOfADivisionOfASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getShiftOfADivisionOfASemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.shiftId
  }

  async getHolidaysOfADepartmentOfASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getHolidaysOfADepartmentOfASemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.holidays
  }

  async getNumberOfBatchesOfADivisionInASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getNumberOfBatchesOfADivisionOfASemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.numberOfBatches
  }

  async getClassTeacherOfADivisionOfASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getClassTeacherOfADivisionOfASemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.classTeacher
  }

  async getSemestersForACourseYear (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemestersForACourseYear, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.semesters
  }

  async addBatchesDetailsForDivision (data) {
    console.log(data)
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.addBatchesDetailsForDivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async removeBatchesDetailsForDivision (data) {
    console.log(data)
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.removeBatchesDetailsForDivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async updateDatesOfADivisionInASemester (data) {
    console.log(data)
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSemesterDatesForADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return { startDate: response.startDate, endDate: response.endDate }
  }

  async updateShiftOfADivisionInASemester (data) {
    console.log(data)
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateShiftForADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return { shiftId: response.shiftId }
  }
  async updateSemDetails (data) {
    // console.log(data)
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSemDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateSemesterCourseYearResultDetails (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateSemesterCourseYearResultDetails, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async updateHolidaysOfADepartmentOfASemester (data) {
    console.log(data)
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateHolidaysForADepartment, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.holidays
  }

  async updateNumberOfBatchesOfADivisionOfASemester (data) {
    console.log(data)
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateNumberOfBatchesForADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.numberOfBatches
  }

  async updateClassTeacherOfADivisionOfASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.putRequest(ApiNames.updateClassTeacherForADivision, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.classTeacher
  }

  async getNameOfASemester (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getNameOfASemester, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.nameOfSemester
  }

  async getNamesOfSemesters (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getNamesOfSemesters, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.namesOfSemesters
  }
  async getSemesters (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSemesters, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}

export default Semester

export default {
    createSettings: '/feedbackform/create',
    updateSettings: '/feedbackform/update',
    getSettings: '/feedbackform/getSettings',
    getTemplates: '/feedbackform/getTemplates',
    getTemplatesByTypeAndStakeholder: '/feedbackform/getTemplatesByTypeAndStakeholder',
    getSettingsBySettingId: '/feedbackform/getSettingsBySettingId',
    createTemplates: '/feedbackform/createTemplate',
    updateTemplates: '/feedbackform/updateTemplate',
    deleteTemplate: '/feedbackform/deleteTemplate',
}
export const icons = {
  delete: 'mdi-delete',
  deleteOutline: 'mdi-delete-outline',
  plus: 'mdi-plus',
  copy: 'mdi-content-copy',
  refresh: 'mdi-refresh',
  bell: 'mdi-bell',
  bellOutline: 'mdi-bell-outline',
  bellOff: 'mdi-bell-off',
  bellRingOutline: 'mdi-bell-ring-outline',
  close: 'mdi-close',
  account: 'mdi-account',
  accountOutline: 'mdi-account-outline',
  download: 'mdi-download',
  chevronDown: 'mdi-chevron-down',
  chevronRight: 'mdi-chevron-right',
  chevronLeft : 'mdi-chevron-left',
  chevronUp : 'mdi-chevron-up',
  lockOpenOutline: 'mdi-lock-open-outline',
  finance: 'mdi-finance',
  magnify: 'mdi-magnify',
  calendarMonthOutline: 'mdi-calendar-month-outline',
  arrowRightThin: 'mdi-arrow-right-thin',
  arrowRight: 'mdi-arrow-right',
  arrowLeft: 'arrow-left',
  arrowLeftThin: 'arrow-left-thin',
  pencil: 'mdi-pencil',
  pencilOutline: 'mdi-pencil-outline',
  attachment: 'mdi-paperclip',
  save: 'mdi-content-save',
  fileOutline: 'mdi-file-outline',
  file: 'mdi-file',
  eye: 'mdi-eye',
  eyeOutline: 'mdi-eye-outline',
  checkCircle:'mdi-check-circle',
  borderNoneVariant: 'mdi-border-none-variant', //Default for SoloIcon component
};

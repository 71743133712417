import { render, staticRenderFns } from "./activityQuestionTemplate.html?vue&type=template&id=624d536b&external"
import script from "./activityQuestionTemplate.vue?vue&type=script&lang=js"
export * from "./activityQuestionTemplate.vue?vue&type=script&lang=js"
import style0 from "./activityQuestionTemplate.css?vue&type=style&index=0&prod&scope=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
// this is the main class of the router which will contain all the routes

// Import statements to get all required libraries
import Vue from "vue";
import Router from "vue-router"; // library for creating Router object
import validateTravel from "./routerGuard";

// Import statements to get all required components for routing
import activityWelcome from "../Activities/activityWelcome/activityWelcome.vue";
import activityHome from "../Activities/activityHome/activityHome";
import activitySetup from "../Activities/activitySetup/activitySetup";
import activityProfile from "../Activities/activityProfile/activityProfile";
import activitySelectInstituteNSemester from "../Activities/activitySelectInstituteNSemester/activitySelectInstituteNSemester";
import activityInstitutesBasicDetails from "../Activities/activityInstitutesBasicDetails/activityInstitutesBasicDetails";
import activityReferenceAcademicDetails from "../Activities/activityReferenceAcademicDetails/activityReferenceAcademicDetails";
import activityReferenceInfrastructure from "../Activities/activityReferenceInfrastructure/activityReferenceInfrastructure";
import activityReferenceShiftDetails from "../Activities/activityReferenceShiftDetails/activityReferenceShiftDetails";
import activityManageRoles from "../Activities/activityManageRoles/activityManageRoles";
import activityViewSemesters from "../Activities/activityViewSemesters/activityViewSemesters";
import activityCreateSemester from "../Activities/activityCreateSemester/activityCreateSemester";
import activityEditSemester from "../Activities/activityEditSemester/activityEditSemester";
import activityDirections from "../Activities/activityDirections/activityDirections";
import activityCreateInstituteOrUser from "../Activities/activityCreateInstituteOrUser/activityCreateInstituteOrUser";
import activityDashboard from "../Activities/activityDashboard/activityDashboard";
import activityArrangeLecture from "../Activities/activityArrangeLecture/activityArrangeLecture";
import activityArrangeLectureBySubject from "../Activities/activityArrangeLectureBySubject/activityArrangeLectureBySubject";
import activityArrangeLectureByValueAdded from "../Activities/activityArrangeLectureByValueAdded/activityArrangeLectureByValueAdded";
import activityManageHoliday from "../Activities/activityManageHoliday/activityManageHoliday";
import activityAttendanceAnalysis from "../Activities/activityAttendanceAnalysis/activityAttendanceAnalysis";
import activitySyllabusTracking from "../Activities/activitySyllabusTracking/activitySyllabusTracking";
import activityFeedbackAnalysis from "../Activities/activityFeedbackAnalysis/activityFeedbackAnalysis";
import activityNotifications from "../Activities/activityNotifications/activityNotifications";
import activityManageUsers from "../Activities/activityManageUsers/activityManageUsers";
import activityTimeline from "../Activities/activityTimeline/activityTimeline";
import activityDiscussion from "../Activities/activityDiscussion/activityDiscussion";
import activityManageSMS from "../Activities/activityManageSMS/activityManageSMS";
import activitySMSUsers from "../Activities/activitySMSUsers/activitySMSUsers";
import activityTransferUsers from "../Activities/activityTransferUsers/activityTransferUsers";
import activityManageTopicFeedbackTemplates from "../Activities/activityManageTopicFeedbackTemplates/activityManageTopicFeedbackTemplates";
import activityTopicFeedbackAnalysis from "../Activities/activityTopicFeedbackAnalysis/activityTopicFeedbackAnalysis";
import activityAdmission from "../Activities/activityAdmission/activityAdmission";
import activityAdmissionUser from "../Activities/activityAdmissionUser/activityAdmissionUser";
import activityAdmissionNewUsers from "../Activities/activityAdmissionNewUsers/activityAdmissionNewUsers";
import activityAdmissionForm from "../Activities/activityAdmissionForm/activityAdmissionForm";
import activityCreateAdmission from "../Activities/activityCreateAdmission/activityCreateAdmission";
import activityEditAdmission from "../Activities/activityEditAdmission/activityEditAdmission";
import activityPaymentSchedule from "../Activities/activityPaymentSchedule/activityPaymentSchedule";
import activityInstitutesManagment from "../Activities/activityInstitutesManagment/activityInstitutesManagment";
import activityAddSubjectInstituteLevel from "../Activities/activityAddSubjectInstituteLevel/activityAddSubjectInstituteLevel";
import activityValueAdded from "../Activities/activityValueAdded/activityValueAdded";
import activitySemestersManagment from "../Activities/activitySemestersManagment/activitySemestersManagment";
import activityEditSemesterDates from "../Activities/activityEditSemesterDates/activityEditSemesterDates";
import activityEditSemesterHolidays from "../Activities/activityEditSemesterHolidays/activityEditSemesterHolidays";
import activityEditSemesterShifts from "../Activities/activityEditSemesterShifts/activityEditSemesterShifts";
import activityEditSemesterBatches from "../Activities/activityEditSemesterBatches/activityEditSemesterBatches";
import activityEditSemesterSubjects from "../Activities/activityEditSemesterSubjects/activityEditSemesterSubjects";
import activityEditSemesterSubjects1 from "../Activities/activityEditSemesterSubjects1/activityEditSemesterSubjects1";
import activityEditSemesterClassteacher from "../Activities/activityEditSemesterClassteacher/activityEditSemesterClassteacher";
import activityEditSemesterTimetable from "../Activities/activityEditSemesterTimetable/activityEditSemesterTimetable";
import activityEditSemesterUsers from "../Activities/activityEditSemesterUsers/activityEditSemesterUsers";
import activityEditStudentsRegistration from "../Activities/activityEditStudentsRegistration/activityEditStudentsRegistration";
import activityEditFacultyAllocation from "../Activities/activityEditFacultyAllocation/activityEditFacultyAllocation";
import activityFeeReceipt from "../Activities/activityFeeReceipt/activityFeeReceipt.vue";
import activityStudentProfile from "../Activities/activityStudentProfile/activityStudentProfile";
import activityFacultyProfile from "../Activities/activityFacultyProfile/activityFacultyProfile";
import activityQuickTest from "../Activities/activityQuickTest/activityQuickTest";
import activityQuickTestAveragePerformance from "../Activities/activityQuickTestAveragePerformance/activityQuickTestAveragePerformance";
import activityQuickTestInsight from "../Activities/activityQuickTestInsight/activityQuickTestInsight";
import activityCreateTest from "../Activities/activityCreateTest/activityCreateTest";
import activityCourses from "../Activities/activityCourses/activityCourses";
import activityCoursesRevamped from "../Activities/activityCourses2/activityCourses";
import activitySubjectCourses from "../Activities/activityCourses/activitySubjectCourses/activitySubjectCourses";
import activitySubjectCoursesBasicDetails from "../Activities/activityCourses2/activitySubjectCourses/activitySubjectCourses";
import activityCourseAnalysis from "../Activities/activityCourseAnalysis/activityCourseAnalysis.vue";
import activityCourseMaterial from "../Activities/activityCourseAnalysis/activityCourseMaterial/activityCourseMaterial";
import activityCourseSubjectAnalysis from "../Activities/activityCourseSubjectAnalysis/activityCourseSubjectAnalysis.vue";
import activityCourseAssignmentChecking from "../Activities/activityCourseAssignmentChecking/activityCourseAssignmentChecking.vue";
import activityCourseAssignmentCheck from "../Activities/activityCourseAnalysisRevamped/activeCourseAssignmentCheck/activeCourseAssignmentCheck.vue";
import activityCourseSubsectionFeedback from "../Activities/activityCourseAnalysisRevamped/activityCourseSubsectionFeedback/activityCourseSubsectionFeedback.vue";
import activityMergeLecture from "../Activities/activityMergeLecture/activityMergeLecture";
import activityControlAccess from "../Activities/activityControlAccess/activityControlAccess";
import activityCourseAnalysisRevamped from "../Activities/activityCourseAnalysisRevamped/activityCourseAnalysisRevamped";
import activityManageAssignments from "../Activities/activityManageAssignments/activityManageAssignments";
import activityCreateAssignments from "../Activities/activityCreateAssignments/activityCreateAssignments";
import activityManageStudyMaterial from "../Activities/activityManageStudyMaterial/activityManageStudyMaterial";
import activityCheckAssignments from "../Activities/activityCheckAssignments/activityCheckAssignments";
import activityFeedbackForm from "../Activities/activityFeedbackForm/activityFeedbackForm";
import activityCreateFeedback from "../Activities/activityCreateFeedback/activityCreateFeedback";
import activityTakeAttendance from "../Activities/activityTakeAttendance/activityTakeAttendance";
import activityDepartmentManagement from "../Activities/activityDepartmentManagement/activityDepartmentManagement";
import activityCumulativeAttendance from "../Activities/activityCumulativeAttendance/activityCumulativeAttendance";
import activityMediaLibrary from "../Activities/activityMediaLibrary/activityMediaLibrary";
import activityUniversityEvents from "../Activities/activityUniversityEvents/activityUniversityEvents";
import activityTakeEventAttendace from "../Activities/activityTakeEventAttendace/activityTakeEventAttendace";
import activityQuizResult from "../Activities/activityCourseSubjectAnalysis/activityQuizResult/activityQuizResult.vue";
import feedbackSubmitted from "../Activities/activityCourseSubjectAnalysis/feedbackSubmitted/feedbackSubmitted.vue";

import activityExamSettings from "../Activities/activityExamSettings/activityExamSettings";
import activityNormalCourse from "../Activities/activityCourseFile/normalCourse/normalCourse";

// EXAM ACTIVITIES
import activityExams1 from "../Activities/examActivities/activityExams1/activityExams1";
import activityExamAccessControl from "../Activities/examActivities/activityExamAccessControl/activityExamAccessControl";
import activityCreateExam from "../Activities/examActivities/activityCreateExam/activityCreateExam";
import activityExamAnalysis from "../Activities/examActivities/activityExamAnalysis/activityExamAnalysis";
import activityExamDashboard from "../Activities/examActivities/activityExamDashboard/activityExamDashboard";
import activityExamConductionMonitor from "../Activities/examActivities/activityExamConductionMonitor/activityExamConductionMonitor";
import activitySelectSubjectsForExam from "../Activities/examActivities/activitySelectSubjectsForExam/activitySelectSubjectsForExam";
import activityDefineExamSubjects from "../Activities/examActivities/activityDefineExamSubjects/activityDefineExamSubjects";
import activityExamUploadResult from "../Activities/examActivities/activityExamUploadResult/activityExamUploadResult";
import activityEditExamSubjects from "../Activities/examActivities/activityEditExamSubjects/activityEditExamSubjects";
import activityEditExamRooms from "../Activities/examActivities/activityEditExamRooms/activityEditExamRooms";
import activityEditExamSubjectQuestions from "../Activities/examActivities/activityEditExamSubjectQuestions/activityEditExamSubjectQuestions";
import activityEditExam from "../Activities/examActivities/activityEditExam/activityEditExam";
import activityInvigilatorDashboard from "../Activities/examActivities/activityInvigilatorDashboard/activityInvigilatorDashboard";
import activitySubjectGroup from "../Activities/activitySubjectGroup/activitySubjectGroup";
import activityManageExamsRevamped from "../Activities/activityManageExamsRevamped/activityManageExamsRevamped";
import examQuestions from "../Activities/activityManageExamsRevamped/examQuestions/examQuestions";
import examStudentMarks from "../Activities/activityManageExamsRevamped/examStudentMarks/examStudentMarks";
import examQuestionDetails from "../Activities/activityManageExamsRevamped/examQuestionDetails/examQuestionDetails";
import activitySemesterResults from "../Activities/activitySemesterResults/activitySemesterResults";

// NBA ACTIVITIES
import activityNBAModule from "../Activities/activityNBAModule/activityNBAModule";
import activityNBAextended from "../Activities/activityNBAextended/activityNBAextended";

//FEEDBACK ACTIVITIES
import activityFBForm from "../Activities/feedbackActivities/activityFBForm/activityFBForm";
import activityCreateFBForm from "../Activities/feedbackActivities/activityCreateFBForm/activityCreateFBForm";

//FEEDBACK REVAMPED
import activityFeedbackFormRevamped from "../Activities/activityFeedbackFormRevamped/activityFeedbackFormRevamped.vue";
import activityCreateFeedbackform from "../Activities/activityFeedbackFormRevamped/activityCreateFeedbackform/activityCreateFeedbackform.vue";
import activityQuestionTemplate from "../Activities/activityFeedbackFormRevamped/activityQuestionTemplate/activityQuestionTemplate.vue"
import activitySettingsAndTemplates from "../Activities/activityFeedbackFormRevamped/activitySettingsAndTemplates/activitySettingsAndTemplates.vue"

// ADDITIONAL FACILITY ACTIVITIES
import activityAdditionalFacility from "../Activities/activityAdditionalFacility/activityAdditionalFacility";

// ADDING FACILITY
import addFacility from "../Activities/addFacility/addFacility";
import addAdditionalFacility from "../Activities/addAdditionalFacility/addAdditionalFacility";

Vue.use(Router); // Using Router object in Vue

export const router = new Router({
  // Exporting a constant object 'router' which is of Router type
  mode: "history", // using history mode to maintain history stack of routes
  base: process.env.BASE_URL, // using 'base' variable as a baseURL
  routes: [
    // this is the array which contains all the paths/routes
    {
      // this is the default route which redirects to route of 'redirect' property
      path: "/",
      redirect: "/activityWelcome",
    },
    {
      // this is the object which defines route and name for component 'activityWelcome'
      path: "/activityWelcome", // route of component
      name: "activityWelcome", // name of component
      component: activityWelcome, // object of component
      meta: { guestOnly: true },
    },
    {
      path: "/activitySetup",
      name: "activitySetup",
      component: activitySetup,
      meta: { guestOnly: true },
    },
    {
      path: "/activitySelectInstituteNSemester",
      name: "activitySelectInstituteNSemester",
      component: activitySelectInstituteNSemester,
      meta: { requireAuth: true },
    },
    {
      path: "/activityUser",
      name: "activityUser",
      component: activityCreateInstituteOrUser,
      meta: { requireAuth: true },
    },
    {
      path: "/activityCreateInstitute",
      name: "acitivityCreateInstitute",
      component: activityCreateInstituteOrUser,
      meta: { requireAuth: true },
    },


    {
      path: "/activityHome",
      name: "activityHome",
      component: activityHome,
      meta: { requireAuth: true },
      children: [
        {
          path: "/",
          redirect: "/activityDirections",
        },
        {
          path: "/activityTimeline",
          name: "activityTimeline",
          component: activityTimeline,
          meta: { requireAuth: true },
        },
        {
          path: "/activityDiscussion",
          name: "activityDiscussion",
          component: activityDiscussion,
          meta: { requireAuth: true },
        },
        {
          path: "/activityDirections",
          name: "activityDirections",
          component: activityDirections,
          meta: { requireAuth: true },
        },
        {
          path: "/activityProfile",
          name: "activityProfile",
          component: activityProfile,
          meta: { requireAuth: true },
        },
        {
          path: "/activityInstitutesBasicDetails",
          name: "activityInstitutesBasicDetails",
          component: activityInstitutesBasicDetails,
          meta: { requireAuth: true },
        },
        {
          path: "/activityReferenceAcademicDetails",
          name: "activityReferenceAcademicDetails",
          component: activityReferenceAcademicDetails,
          meta: { requireAuth: true },
        },
        {
          path: "/activityReferenceInfrastructure",
          name: "activityReferenceInfrastructure",
          component: activityReferenceInfrastructure,
          meta: { requireAuth: true },
        },
        {
          path: "/activityReferenceShiftDetails",
          name: "activityReferenceShiftDetails",
          component: activityReferenceShiftDetails,
          meta: { requireAuth: true },
        },
        {
          path: "/activityManageRoles",
          name: "activityManageRoles",
          component: activityManageRoles,
          meta: { requireAuth: true },
        },
        {
          path: "/activityViewSemesters",
          name: "activityViewSemesters",
          component: activityViewSemesters,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCreateSemester",
          name: "activityCreateSemester",
          component: activityCreateSemester,
          meta: { requireAuth: true },
        },
        {
          path: "/activityDepartmentManagement",
          name: "activityDepartmentManagement",
          component: activityDepartmentManagement,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCumulativeAttendance",
          name: "activityCumulativeAttendance",
          component: activityCumulativeAttendance,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditSemester",
          name: "activityEditSemester",
          component: activityEditSemester,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityDashboard",
          name: "activityDashboard",
          component: activityDashboard,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityExams1",
          name: "activityExams1",
          component: activityExams1,
          meta: { requireAuth: true },
        },
        {
          path: "/activityNormalCourse",
          name: "activityNormalCourse",
          component: activityNormalCourse,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCreateExam",
          name: "activityCreateExam",
          component: activityCreateExam,
          meta: { requireAuth: true },
        },
        {
          path: "/activityExamAccessControl",
          name: "activityExamAccessControl",
          component: activityExamAccessControl,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditExam",
          name: "activityEditExam",
          component: activityEditExam,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityExamAnalysis",
          name: "activityExamAnalysis",
          component: activityExamAnalysis,
          meta: { requireAuth: true },
        },
        {
          path: "/activityExamDashboard",
          name: "activityExamDashboard",
          component: activityExamDashboard,
          meta: { requireAuth: true },
        },
        {
          path: "/activityExamConductionMonitor",
          name: "activityExamConductionMonitor",
          component: activityExamConductionMonitor,
          meta: { requireAuth: true },
        },
        {
          path: "/activitySelectSubjectsForExam",
          name: "activitySelectSubjectsForExam",
          component: activitySelectSubjectsForExam,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditExamSubjectQuestions",
          name: "activityEditExamSubjectQuestions",
          component: activityEditExamSubjectQuestions,
          meta: { requireAuth: true },
        },
        {
          path: "/activityDefineExamSubjects",
          name: "activityDefineExamSubjects",
          component: activityDefineExamSubjects,
          meta: { requireAuth: true },
        },
        {
          path: "/activityExamUploadResult",
          name: "activityExamUploadResult",
          component: activityExamUploadResult,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditExamSubjects",
          name: "activityEditExamSubjects",
          component: activityEditExamSubjects,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditExamRooms",
          name: "activityEditExamRooms",
          component: activityEditExamRooms,
          meta: { requireAuth: true },
        },
        {
          path: "/activityArrangeLecture",
          name: "activityArrangeLecture",
          component: activityArrangeLecture,
          meta: { requireAuth: true },
        },
        {
          path: "/activityArrangeLectureBySubject",
          name: "activityArrangeLectureBySubject",
          component: activityArrangeLectureBySubject,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityManageHoliday",
          name: "activityManageHoliday",
          component: activityManageHoliday,
          meta: { requireAuth: true },
        },
        {
          path: "/activityAttendanceAnalysis",
          name: "activityAttendanceAnalysis",
          component: activityAttendanceAnalysis,
          meta: { requireAuth: true },
        },
        {
          path: "/activitySyllabusTracking",
          name: "activitySyllabusTracking",
          component: activitySyllabusTracking,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityFeedbackForm",
          name: "activityFeedbackForm",
          component: activityFeedbackForm,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCreateFeedback",
          name: "activityCreateFeedback",
          component: activityCreateFeedback,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityFeedbackAnalysis",
          name: "activityFeedbackAnalysis",
          component: activityFeedbackAnalysis,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityNotifications",
          name: "activityNotifications",
          component: activityNotifications,
          meta: { requireAuth: true },
        },
        {
          path: "/activityManageUsers",
          name: "activityManageUsers",
          component: activityManageUsers,
          meta: { requireAuth: true },
        },
        {
          path: "/activityManageSMS",
          name: "activityManageSMS",
          component: activityManageSMS,
          meta: { requireAuth: true },
        },
        {
          path: "/activitySMSUsers",
          name: "activitySMSUsers",
          props: true,
          component: activitySMSUsers,
          meta: { requireAuth: true },
        },
        {
          path: "/activityTransferUsers",
          name: "activityTransferUsers",
          props: true,
          component: activityTransferUsers,
          meta: { requireAuth: true },
        },
        {
          path: "/activityManageTopicFeedbackTemplates",
          name: "activityManageTopicFeedbackTemplates",
          component: activityManageTopicFeedbackTemplates,
          meta: { requireAuth: true },
        },
        {
          path: "/activityTopicFeedbackAnalysis",
          name: "activityTopicFeedbackAnalysis",
          component: activityTopicFeedbackAnalysis,
          meta: { requireAuth: true },
        },
        {
          path: "/activityNBAModule",
          name: "activityNBAModule",
          component: activityNBAModule,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityNBAextended",
          name: "activityNBAextended",
          component: activityNBAextended,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityAdmission",
          name: "activityAdmission",
          component: activityAdmission,
          meta: { requireAuth: true },
        },
        {
          path: "/activityAdmissionUser",
          name: "activityAdmissionUser",
          component: activityAdmissionUser,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityAdmissionNewUsers",
          name: "activityAdmissionNewUsers",
          component: activityAdmissionNewUsers,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityAdmissionForm",
          name: "activityAdmissionForm",
          component: activityAdmissionForm,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCreateAdmission",
          name: "activityCreateAdmission",
          component: activityCreateAdmission,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditAdmission",
          name: "activityEditAdmission",
          component: activityEditAdmission,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityPaymentSchedule",
          name: "activityPaymentSchedule",
          component: activityPaymentSchedule,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityInstitutesManagment",
          name: "activityInstitutesManagment",
          component: activityInstitutesManagment,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activitySemestersManagment",
          name: "activitySemestersManagment",
          component: activitySemestersManagment,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditSemesterDates",
          name: "activityEditSemesterDates",
          component: activityEditSemesterDates,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditSemesterHolidays",
          name: "activityEditSemesterHolidays",
          component: activityEditSemesterHolidays,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditSemesterShifts",
          name: "activityEditSemesterShifts",
          component: activityEditSemesterShifts,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditSemesterBatches",
          name: "activityEditSemesterBatches",
          component: activityEditSemesterBatches,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditSemesterUsers",
          name: "activityEditSemesterUsers",
          component: activityEditSemesterUsers,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditSemesterClassteacher",
          name: "activityEditSemesterClassteacher",
          component: activityEditSemesterClassteacher,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditSemesterSubjects",
          name: "activityEditSemesterSubjects",
          component: activityEditSemesterSubjects,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditSemesterSubjects1",
          name: "activityEditSemesterSubjects1",
          component: activityEditSemesterSubjects1,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditFacultyAllocation",
          name: "activityEditFacultyAllocation",
          component: activityEditFacultyAllocation,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditStudentsRegistration",
          name: "activityEditStudentsRegistration",
          component: activityEditStudentsRegistration,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityEditSemesterTimetable",
          name: "activityEditSemesterTimetable",
          component: activityEditSemesterTimetable,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityStudentProfile",
          name: "activityStudentProfile",
          component: activityStudentProfile,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityFacultyProfile",
          name: "activityFacultyProfile",
          component: activityFacultyProfile,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityFeeReceipt",
          name: "activityFeeReceipt",
          component: activityFeeReceipt,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityQuickTest",
          name: "activityQuickTest",
          component: activityQuickTest,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityQuickTestAveragePerformance",
          name: "activityQuickTestAveragePerformance",
          component: activityQuickTestAveragePerformance,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityQuickTestInsight",
          name: "activityQuickTestInsight",
          component: activityQuickTestInsight,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCreateTest",
          name: "activityCreateTest",
          component: activityCreateTest,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityManageAssignments",
          name: "activityManageAssignments",
          component: activityManageAssignments,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCreateAssignments",
          name: "activityCreateAssignments",
          component: activityCreateAssignments,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityManageStudyMaterial",
          name: "activityManageStudyMaterial",
          component: activityManageStudyMaterial,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCheckAssignments",
          name: "activityCheckAssignments",
          component: activityCheckAssignments,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityMediaLibrary",
          name: "activityMediaLibrary",
          component: activityMediaLibrary,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityUniversityEvents",
          name: "activityUniversityEvents",
          component: activityUniversityEvents,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityTakeEventAttendace",
          name: "activityTakeEventAttendace",
          component: activityTakeEventAttendace,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityTakeAttendance",
          name: "activityTakeAttendance",
          component: activityTakeAttendance,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityMergeLecture",
          name: "activityMergeLecture",
          component: activityMergeLecture,
          props: true,
          meta: { requireAuth: true },
        },
        // {
        //   path: "/activityCourses",
        //   name: "activityCourses",
        //   component: activityCourses,
        //   props: true,
        //   meta: { requireAuth: true },
        // },
        {
          path: "/activityCourses",
          name: "activityCourses2",
          component: activityCoursesRevamped,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activitySubjectCourses",
          name: "activitySubjectCourses",
          component: activitySubjectCourses,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activitySubjectCoursesBasicDetails",
          name: "activitySubjectCoursesBasicDetails",
          component: activitySubjectCoursesBasicDetails,
          props: true,
          meta: { requireAuth: true },
        },
        // {
        //   path: "/activityCourseAnalysis",
        //   name: "activityCourseAnalysis",
        //   component: activityCourseAnalysis,
        //   props: true,
        //   meta: { requireAuth: true },
        // },
        {
          path: "/activityCourseAnalysis",
          name: "activityCourseAnalysisRevamped",
          component: activityCourseAnalysisRevamped,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCourseSubsectionFeedback",
          name: "activityCourseSubsectionFeedback",
          component: activityCourseSubsectionFeedback,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCourseSubjectAnalysis",
          name: "activityCourseSubjectAnalysis",
          component: activityCourseSubjectAnalysis,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCourses",
          name: "activityCourses",
          component: activityCourses,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activitySubjectCourses",
          name: "activitySubjectCourses",
          component: activitySubjectCourses,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCourseAnalysis",
          name: "activityCourseAnalysis",
          component: activityCourseAnalysis,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCourseAssignmentChecking",
          name: "activityCourseAssignmentChecking",
          component: activityCourseAssignmentChecking,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCourseAssignmentCheck",
          name: "activityCourseAssignmentCheck",
          component: activityCourseAssignmentCheck,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCourseSubjectAnalysis",
          name: "activityCourseSubjectAnalysis",
          component: activityCourseSubjectAnalysis,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityQuizResult",
          name: "activityQuizResult",
          component: activityQuizResult,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/feedbackSubmitted",
          name: "feedbackSubmitted",
          component: feedbackSubmitted,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityFBForm",
          name: "activityFBForm",
          component: activityFBForm,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCreateFBForm",
          name: "activityCreateFBForm",
          component: activityCreateFBForm,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityFeedbackFormRevamped",
          name: "activityFeedbackFormRevamped",
          component: activityFeedbackFormRevamped,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCreateFeedbackform",
          name: "activityCreateFeedbackform",
          component: activityCreateFeedbackform,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityQuestionTemplate",
          name: "activityQuestionTemplate",
          component: activityQuestionTemplate,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activitySettingsAndTemplates",
          name: "activitySettingsAndTemplates",
          component: activitySettingsAndTemplates,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityCourseMaterial",
          name: "activityCourseMaterial",
          component: activityCourseMaterial,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityAddSubjectInstituteLevel",
          name: "activityAddSubjectInstituteLevel",
          component: activityAddSubjectInstituteLevel,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityExamSettings",
          name: "activityExamSettings",
          component: activityExamSettings,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityValueAdded",
          name: "activityValueAdded",
          component: activityValueAdded,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityInvigilatorDashboard",
          name: "activityInvigilatorDashboard",
          component: activityInvigilatorDashboard,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activitySubjectGroup",
          name: "activitySubjectGroup",
          component: activitySubjectGroup,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityArrangeLectureByValueAdded",
          name: "activityArrangeLectureByValueAdded",
          component: activityArrangeLectureByValueAdded,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityAdditionalFacility",
          name: "activityAdditionalFacility",
          component: activityAdditionalFacility,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/addFacility",
          name: "addFacility",
          component: addFacility,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/addAdditionalFacility",
          name: "addAdditionalFacility",
          component: addAdditionalFacility,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityControlAccess",
          name: "activityControlAccess",
          component: activityControlAccess,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activityManageExamsRevamped",
          name: "activityManageExamsRevamped",
          component: activityManageExamsRevamped,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/examQuestions",
          name: "examQuestions",
          component: examQuestions,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/examStudentMarks",
          name: "examStudentMarks",
          component: examStudentMarks,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/examQuestionDetails",
          name: "examQuestionDetails",
          component: examQuestionDetails,
          props: true,
          meta: { requireAuth: true },
        },
        {
          path: "/activitySemesterResults",
          name: "activitySemesterResults",
          component: activitySemesterResults,
          props: true,
          meta: { requireAuth: true },
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  validateTravel(to, from, next);
});
// router.afterEach(to => {
//   localStorage.setItem(to.name, to.name);
//   // console.log('to.name', to);
// });
export default router;

<template src="./activityQuestions.html"></template>

<script>
import { v4 } from "uuid";
import showStatus from "../../../../NetworkManager/showStatus";
import NewFeedbackFormRepository from "../../../../Repository/Feedbackform";
import UploadToDigitalOceanRepository from "../../../../Repository/uploadToDigitalOcean";

export default {
  name: "activityQuestions",
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      black: "#000000",
      progressIndicator1: 0,
      uploadQuestionsViaExcel: false,
      questionWiseScales: [
        {
          questionNo: "Q1",
          statement: "w1",
          type: "rating",
          lowestNo: 1,
          highestNo: 5,
          labels: [],
        },
        {
          questionNo: "Q2",
          statement: "w2",
          type: "rating",
          lowestNo: 1,
          highestNo: 5,
          labels: [],
        },
      ],
      questionsArray: [],
      headers: [],
      sectionNo: 0,
      questionNo: 0,
      questionDialogue: false,
      orQuestionDialogue: false,
      subQuestionDialogue: false,
      questionsValidationSuccess: true,
      createdSets: [
        {
          no: 1,
          setName: "set 1",
        },
      ],
      numberOfSets: 0,
      allTypeOfQuestions: [
        "Short Answer",
        "Long Answer",
        "Rating",
        "Single select",
        "Multiple select",
      ],
      allScaleOrOptions: ["Scale", "Options"],
      blue: "#050D92",
      orange: "#FF4F1F",
      downArrow: "mdi-chevron-down",
      upArrow: "mdi-arrow-up",
      examsLoaded: true,
      isMobileView: false,
      department: "Test Department",
      examTypes: ["Assignment"], //'Theory', 'Online'
      examType: "",
      subjectNames: [],
      secondaryTabItems: ["Question Paper"], //, 'Rubrics', 'Model Answers'
      tabs: 0,
      tabs1: 0,
      tabs2: 0,
      search: "",
      selectedTab: 0,
      examName: "",
      descriptionOfExam: "",
      totalMarks: "",
      passingMarks: "",
      thresholdMarks: "",
      dateOfPublication: null,
      dateOfCompletion: null,
      timeOfPublication: null,
      timeOfCompletion: null,
      bufferEndDate: null,
      dopmodel: false,
      docmodel: false,
      topmodel: false,
      tocmodel: false,
      btaedmodel: false,
      showCanvasDialog: false,
      canvasContainer: null,
      showAttachment: false,
      mainCard: null,
      canvas: null,
      remarkForLateSubmission: "",
      templateData: null,
      startDate: "",
      fileType: [],
      allFileTypes: ["Images", "PDF", "Audio", "Video"],
      menu2: false,
      calenderIcon: "mdi-calendar-month-outline",
      attainmentCheckbox: false,
      questionsCheckbox: false,
      rubricCheckbox: false,
      selectedItem: "",
      isForQuestionsTab: false,
      switch1: false,
      setsToCreate: [],
      createdSets: [
        {
          no: 1,
          setName: "set 1",
        },
      ],
      numberOfSets: 0,
      setsObjectForQuestions: {
        "set 1": [],
      },
      count: 0,
      uploadQuestionExcelDialog: false,
      uploadQuestionViseMarksViaExcel: false,
      quizQuestionExcelFile: null,
      questionString: "",
      exam: null,
      examQuestions: [],
      isSetNameEmpty: false,
      activeSet: {
        no: 1,
        setName: "set 1",
      },
      progressIndicator1: 0,
      loading: false,
      newTempTab:
        this.questionsCheckbox || this.rubricCheckbox
          ? "Questions"
          : "Model Answers",
      mainTabNames: [
        "Basic Details",
        this.newTempTab,
        "Student Marks",
        "Insights",
      ],
      date: "",
      subjectIds: [],
      questionsArray: [],
      sectionDialog: false,
      sectionType: "",
      createSetDialog: false,
      students: [],
      openQuestionsPreview: false,
      questionDialogue: false,
      orQuestionDialogue: false,
      subQuestionDialogue: false,
      sectionNo: 0,
      questionNo: 0,
      subQuestionNo: 0,
      questionItem: "",
      allQuestionObjectArray: [],
      arrayOfQuestionsToUpdate: [],
      selectedRadio: null,
      questionToGiveMarks: [],
      allQuestions: [],
      questionsValidationSuccess: true,
      seperatedSetQuestions: [],
      allSections: [],
      studentData: {},
      questionWiseMarksDialog: false,
      nbaData: [],
      sectionsOfSelectedSet: [],
      studentMarksArrayToUpdate: [],
      subQnIndex: null,
      questionWiseMarksExcelExtraMarks: [],
      excelErrors: [],
      schema: {},
      uploadOverallMarksViaExcel: false,
      uploadSectionMarksViaExcel: false,
      overallBlooms: [],
      overallCos: [],
      selectedCoType: null,
      selectedBloomType: null,
      attachmentFiles: [],
      subjectObjectiveFileLink: [],
      allBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)",
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)",
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)",
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)",
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)",
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)",
        },
      ],
      bloomsNames: [],
      coNames: [],
      overallCosBloomsData: false,
      templateId: "",
      courseOutcomeData: [],
      isNext: true,
      userData: {},
      courseOutcomeList: [
        "CO1",
        "CO2",
        "CO3",
        "CO4",
        "CO5",
        "CO6",
        "CO7",
        "CO8",
      ],
      programOutcomeList: [
        "PO1",
        "PO2",
        "PO3",
        "PO4",
        "PO5",
        "PO6",
        "PO7",
        "PO8",
      ],
      coPoList: [
        "CO1",
        "CO2",
        "CO3",
        "CO4",
        "CO5",
        "CO6",
        "CO7",
        "CO8",
        "PO1",
        "PO2",
        "PO3",
        "PO4",
        "PO5",
        "PO6",
        "PO7",
        "PO8",
      ],
      selectedMappedCOs: [],
      filledQuestions: [],
      isCourseFeedback: false,
      note: "",
      isSaved: false,
      rules: {
        validate: (value) => {
          const pattern = /<.*?>/;
          return !pattern.test(value) || "Text within < > is not allowed";
        },
        required: (value) => !!value || "This field is required",
        totalMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
        passingMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
      },
    };
  },
  activated() {
    this.templateData =
      this.$store.getters["liveData/get_templateTabDependencies"];
    this.userData = this.$store.getters["user/get_userData"];
    this.allTypeOfQuestions = this.templateData.selectedTypeOfQuestions;
    this.templateId = this.templateData.templateId;

    if (this.templateData.typeOfFeedback === "Course Feedback") {
      this.note =
        "Note: If you wish to include CO Statements in every course feedback form, please ensure they are added in the <strong>&lt;CO1&gt;</strong> format within the question statement. This will enable them to be appropriately fetched for each course.";
    } else {
      this.note =
        " Note: If you wish to include PO Statements in every program feedback form, please ensure they are added in the <strong>&lt;PO1&gt;</strong> format within the question statement. This will enable them to be appropriately fetched for each course.";
    }

    this.manageHeader();
    if (this.templateId) {
      this.storeQuestionData();
      this.templateData.allQuestions.length === 0
        ? this.handleQuestionData()
        : null;
    } else {
      this.handleQuestionData();
    }
  },
  created() {
    this.uploadToDigitalOceanInstance = new UploadToDigitalOceanRepository(
      this
    );
    this.NewFeedbackFormRepositoryInstance = new NewFeedbackFormRepository(
      this
    );
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
  },
  methods: {
    handleQuestionData() {
      const data =
        this.templateData.typeOfFeedback === "Course Feedback"
          ? this.courseOutcomeList
          : this.templateData.typeOfFeedback === "Program Feedback"
          ? this.programOutcomeList
          : this.coPoList;

      const sectionNames = `Section${++this.sectionNo}`;
      const sectionObject = {
        sectionNames,
        question: "sec",
        sectionId: v4(),
      };

      this.questionsArray.push(sectionObject);

      const questions = data.map((item, index) => ({
        questionId: v4(),
        questionType: "Question",
        question: `<${item}>`,
        questionNumber: `Q${index + 1}`,
        questionForSection: sectionNames, // Dynamically use section name
        selectedCos: [item],
        selectedPos: [item],
        blooms: [],
        attachmentFiles: [],
        scales: {},
        cos: data,
      }));

      this.questionsArray.push(...questions);

    },
    manageHeader() {
      let feedbackType = this.templateData.typeOfFeedback;
      const getMapText = (feedbackType) => {
        switch (feedbackType) {
          case "Course Feedback":
            return "Map CO";
          case "Program Feedback":
            return "Map PO";
          default:
            return "Map PO/PSO";
        }
      };
      this.headers = [
        {
          text: "Sections",
          value: "sectionNames",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "Question No",
          value: "questionNumber",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "Sub Qn No",
          value: "subQnNo",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "Question",
          value: "question",
          sortable: false,
          width: "400px",
          align: "center",
        },
        {
          text: "Attachment",
          value: "attachmentFiles",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: getMapText(feedbackType), // Dynamic text based on feedback type
          value: "cos",
          sortable: false,
          width: "100px",
          align: "center",
        },
        {
          text: "",
          value: "delete",
          sortable: false,
          width: "5%",
          align: "left",
        },
      ];
    },
    setActiveSet(set) {
      this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray;
      this.activeSet = set;
      this.questionsArray = this.setsObjectForQuestions[this.activeSet.setName];
    },
    rowClass(item) {
      if (item.sectionNames) {
        return "sectionBackgrond";
      }
    },
    validateSectionName(value) {
      // Regular expression to detect any text inside < and >
      const restrictedPattern = /<.*?>/;
      if (restrictedPattern.test(value)) {
        return "Text within < > is not allowed";
      }
      return true;
    },
    fieldBackgroundColors(item) {
      if (
        item.questionNumber === "OR" ||
        item.subQnNo === "OR" ||
        item.questionNumberAny
      ) {
      } else {
        return "#9205050D";
      }
    },
    resetQueNo() {
      let secNo = 1;
      let qnNo = 1;
      let sQnNo = 1;
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i].sectionNames) {
          qnNo = 1;
          sQnNo = 1;
          this.questionsArray[i].questionNo = "Section" + secNo;
          this.questionsArray[i].sectionNames = "Section" + secNo;
          secNo++;
        } else if (
          this.questionsArray[i].questionNumber &&
          this.questionsArray[i].questionNumber !== "OR"
        ) {
          sQnNo = 1;
          this.questionsArray[i].questionNo = "Q" + qnNo;
          this.questionsArray[i].questionNumber = "Q" + qnNo;
          qnNo++;
        } else if (
          this.questionsArray[i].subQnNo &&
          this.questionsArray[i].subQnNo !== "OR" &&
          !this.questionsArray[i].questionNumberAny &&
          this.questionsArray[i].subQnNo.slice(0, 3) !== "Any"
        ) {
          this.questionsArray[i].subQnNo = "Q" + (qnNo - 1) + "." + sQnNo;
          sQnNo++;
        }
      }
    },
    storeQuestionData() {
      this.questionsArray = [];
      if (this.templateData?.allQuestions?.length > 0) {
        this.getAllSections();
        this.activeSet = {
          no: 1,
          setName: "set 1",
        };
        this.setActiveSet(this.activeSet);
        this.createdSets = [];
        this.setsObjectForQuestions = {};
        for (let i = 0; i < this.templateData.allQuestions?.length; i++) {
          if (this.templateData.allQuestions[i].questionType === "Set") {
            const questionStatement =
              this.templateData.allQuestions[i].questionStatement;
            this.createdSets.push({
              no: i + 1,
              setName: questionStatement,
            });
            if (
              !Object.prototype.hasOwnProperty.call(
                this.setsObjectForQuestions,
                questionStatement
              )
            ) {
              this.setsObjectForQuestions[questionStatement] = [];
            }
            this.setsObjectForQuestions[questionStatement].push(
              ...this.getQuestionsOfSelectedSet(
                { setName: questionStatement, forShowingQuestions: true },
                null
              )
            );
          }
        }
        this.questionsArray = this.setsObjectForQuestions["set 1"];
      }
    },
    addSection() {
      let sectionNames = ++this.sectionNo;
      for (let i = this.questionsArray?.length - 1; i >= 0; i--) {
        if (this.questionsArray[i]?.sectionNames) {
          sectionNames =
            this.questionsArray[i].sectionNames[
              this.questionsArray[i].sectionNames.length - 1
            ] -
            "" +
            1;
        }
      }
      this.questionNo = 0;
      const quesObject = {
        sectionNames: "Section" + sectionNames,
        question: "",
        sectionId: v4(),
      };
      this.questionsArray.push(quesObject);
      this.resetQueNo();
    },
    addNormalQuestion(item) {
      this.subQuestionNo = 0;
      this.questionItem = item.sectionNames;
      const quesObject = {
        questionNumber: "Q" + ++this.questionNo,
        questionForSection: item.sectionNames,
        question: "",
        questionId: v4(),
        cos: this.coNames,
      };
      let foundSec = false;
      let breaked = false;
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i]?.sectionNames === item.sectionNames) {
          foundSec = true;
        } else if (
          this.questionsArray[i].sectionNames &&
          this.questionsArray[i].sectionNames !== item.sectionNames &&
          foundSec
        ) {
          this.questionsArray.splice(i, 0, quesObject);
          breaked = true;
          break;
        }
      }
      if (!breaked) {
        this.questionsArray.push(quesObject);
      }
      this.resetQueNo();
    },
    addSubQuestion(index, item) {
      this.questionItem = item.questionNo;
      this.questionItemIndex = index + 1;

      const quesObject = {
        question: "",
        subQuestionId: v4(),
        subQnNo: "Q" + item.questionNo + "." + ++this.subQuestionNo,
        cos: this.coNames,
      };
      let foundQn = false;
      let breaked = false;
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (
          this.questionsArray[i]?.questionNo === this.questionItem &&
          i === this.questionItemIndex - 1
        ) {
          foundQn = true;
        } else if (
          (this.questionsArray[i].questionNo &&
            this.questionsArray[i].questionNo !== this.questionItem &&
            foundQn) ||
          (this.questionsArray[i].questionNumber && foundQn)
        ) {
          this.questionsArray.splice(i, 0, quesObject);
          breaked = true;
          break;
        }
      }
      if (!breaked) {
        this.questionsArray.push(quesObject);
      }
      this.resetQueNo();
    },
    getAllSections() {
      this.allSections = [];
      for (let i = 0; i < this.templateData?.allQuestions.length; i++) {
        if (this.templateData?.allQuestions[i].questionType === "Set") {
          for (
            let j = 0;
            j < this.templateData.allQuestions[i].question.length;
            j++
          ) {
            this.allSections.push({
              questionId:
                this.templateData.allQuestions[i].question[j].questionId,
              sectionName:
                this.templateData.allQuestions[i].question[j].questionStatement,
            });
          }
        }
      }
      for (let i = 0; i < this.students.length; i++) {
        this.students[i].allSections = this.allSections;
      }
    },
    getQuestionsOfSelectedSet(set, item) {
      this.studentData = item;
      let anyQuestionFound = false;
      let setQuestions = [];
      for (let i = 0; i < this.templateData.allQuestions.length; i++) {
        if (
          this.templateData.allQuestions[i].questionType === "Set" &&
          this.templateData.allQuestions[i].questionStatement === set.setName
        ) {
          setQuestions = this.templateData.allQuestions[i].question;
        }
      }
      this.seperatedSetQuestions = [];

      for (let i = 0; i < setQuestions?.length; i++) {
        anyQuestionFound = false;
        this.seperatedSetQuestions.push({
          sectionNames: setQuestions[i].questionNumber,
          question: setQuestions[i].questionStatement,
          questionId: setQuestions[i].questionId,
          cos: setQuestions[i]?.cos,
          selectedCos: setQuestions[i]?.selectedCos,
          selectedPos: setQuestions[i]?.selectedPos,
          selectedBlooms: setQuestions[i].blooms,
          blooms: this.bloomsNames,
          scales: setQuestions.scales,
        });
        if (setQuestions[i].question?.length > 0) {
          const sectionQuestion = setQuestions[i].question;
          for (let j = 0; j < sectionQuestion?.length; j++) {
            if (sectionQuestion[j].questionType === "OrQuestion") {
              anyQuestionFound = false;
              this.seperatedSetQuestions.push({
                questionNumber: "OR",
              });
            }
            anyQuestionFound = false;
            this.seperatedSetQuestions.push({
              questionNumber: sectionQuestion[j]?.questionNumber,
              question:
                sectionQuestion[j]?.questionStatement === "Solve any_out of"
                  ? "Solve any (" +
                    sectionQuestion[j].NumberOfQuestionsToSolve +
                    ")"
                  : sectionQuestion[j].questionStatement,
              tryl: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              anyCount: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              outOfCount: sectionQuestion[j]?.question?.length,
              scaleOrOptions: sectionQuestion[j].scaleOrOptions,
              type: sectionQuestion[j].type,
              solveAnyCount:
                sectionQuestion[j]?.questionStatement === "Solve any_out of"
                  ? 0
                  : sectionQuestion[j]?.NumberOfQuestionsToSolve,
              questionId: sectionQuestion[j]?.questionId,
              cos: sectionQuestion[j]?.cos,
              selectedCos: sectionQuestion[j]?.selectedCos,
              selectedPos: sectionQuestion[j]?.selectedPos,
              scales: sectionQuestion[j].scales,
              selectedBlooms: sectionQuestion[j]?.blooms,
              blooms: this.bloomsNames,
            });
            if (sectionQuestion[j].NumberOfQuestionsToSolve) {
              anyQuestionFound = true;
              this.seperatedSetQuestions.push({
                subQnNo: "Any " + sectionQuestion[j].NumberOfQuestionsToSolve,
                anyCount: sectionQuestion[j].NumberOfQuestionsToSolve,
                anyStatement: true,
              });
            }
            if (sectionQuestion[j]?.question?.length > 0) {
              const subQuestion = sectionQuestion[j]?.question;
              for (let k = 0; k < subQuestion.length; k++) {
                if (subQuestion[k].questionType === "OrSubQuestion") {
                  this.seperatedSetQuestions.push({
                    subQnNo: "OR",
                    anyStatement: false,
                  });
                }
                if (anyQuestionFound) {
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anySubQuestion: true,
                    anyStatement: true,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    cos: subQuestion[k]?.cos,
                    selectedCos: subQuestion[k]?.selectedCos,
                    selectedPos: subQuestion[k].selectedPos,
                    selectedBlooms: subQuestion[k].blooms,
                    scales: subQuestion[k].scales,
                    blooms: this.bloomsNames,
                  });
                } else {
                  anyQuestionFound = false;
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anyStatement: false,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    cos: subQuestion[k]?.cos,
                    selectedCos: subQuestion[k]?.selectedCos,
                    selectedPos: subQuestion[k].selectedPos,
                    selectedBlooms: subQuestion[k].blooms,
                    scales: subQuestion[k].scales,
                    blooms: this.bloomsNames,
                  });
                }
              }
            }
          }
        }
      }
      this.isNext = false;
      if (!set.forShowingQuestions && this.studentData?.answers) {
        const studentDataMap = new Map(
          this.studentData?.answers.map((data) => [data.questionId, data])
        );
        for (const setQuestion of this.seperatedSetQuestions) {
          const studentDataForQuestion = studentDataMap.get(
            setQuestion.questionId
          );
        }
      } else return this.seperatedSetQuestions;
    },
    deleteQuestion(index) {
      if (index >= 0 && index < this.questionsArray.length) {
        if (this.questionsArray[index].sectionNames) {
          this.questionsArray.splice(index, 1);
          for (let i = index; i < this.questionsArray.length; i++) {
            if (this.questionsArray[i].sectionNames) {
              break;
            } else {
              this.questionsArray.splice(i, 1);
              i--;
            }
          }
        } else if (this.questionsArray[index].questionNumber) {
          this.questionsArray.splice(index, 1);
          for (let i = index; i < this.questionsArray.length; i++) {
            if (
              this.questionsArray[i].questionNumber ||
              this.questionsArray[i].sectionNames
            ) {
              break;
            } else {
              this.questionsArray.splice(i, 1);
              i--;
            }
          }
        } else if (this.questionsArray[index].subQnNo) {
          this.questionsArray.splice(index, 1);
        }
      }

      this.resetQueNo();
    },
    finalSetQuestions() {
      this.allQuestions = [];
      this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray;
      const sets = Object.keys(this.setsObjectForQuestions);
      for (let i = 0; i < sets.length; i++) {
        this.questionsArray = this.setsObjectForQuestions[sets[i]];
        let isBreaked = false;
        for (let j = 0; j < this.questionsArray.length; j++) {
          if (this.questionsArray[j]?.question === "") {
            isBreaked = true;
            showStatus(
              "Enter question statement " +
                (this.questionsArray[j].sectionNames
                  ? this.questionsArray[j].sectionNames
                  : this.questionsArray[j].questionNumber
                  ? this.questionsArray[j].questionNumber
                  : this.questionsArray[j].subQnNo),
              5000,
              "error",
              this
            );
            break;
          }
        }
        if (isBreaked) {
          this.questionsValidationSuccess = false;
          break;
        } else {
          this.questionsValidationSuccess = true;
          this.saveQuestions(sets[i]);
        }
      }
      if (this.questionsValidationSuccess) {
        this.questionsObject();
      }
    },
    async saveQuestions(set) {
      this.questionToGiveMarks = this.questionsArray;
      this.allQuestions.push({
        setName: set,
      });
      for (let i = 0; i < this.questionsArray.length; i++) {
        if (this.questionsArray[i].sectionNames) {
          this.allQuestions.push({
            "Section No.": this.questionsArray[i].sectionNames,
            Section: this.questionsArray[i].question,
            selectedCos: this.questionsArray[i].selectedCos,
            selectedPos: this.questionsArray[i].selectedPos,
            Blooms: this.questionsArray[i].selectedBlooms,
          });
          continue;
        }
        if (
          this.questionsArray[i].questionNumber &&
          this.questionsArray[i].questionNumber !== "OR"
        ) {
          this.allQuestions.push({
            "Question No.": this.questionsArray[i].questionNumber,
            Question: this.questionsArray[i].question,
            selectedCos: this.questionsArray[i].selectedCos,
            selectedPos: this.questionsArray[i].selectedPos,
            Blooms: this.questionsArray[i].selectedBlooms,
            scaleOrOptions: this.questionsArray[i].scaleOrOptions,
            type: this.questionsArray[i].type,
          });
          continue;
        }
        if (
          this.questionsArray[i].questionNumber &&
          this.questionsArray[i].questionNumber === "OR"
        ) {
          this.allQuestions.push({
            "Question No.": "OR",
          });
          continue;
        }
        if (
          this.questionsArray[i].subQnNo &&
          this.questionsArray[i].subQnNo !== "OR"
        ) {
          this.allQuestions.push({
            "Sub-Question No.": this.questionsArray[i].subQnNo,
            "Sub-Question": this.questionsArray[i].question,
            selectedCos: this.questionsArray[i].selectedCos,
            selectedPos: this.questionsArray[i].selectedPos,
            Blooms: this.questionsArray[i].selectedBlooms,
            scaleOrOptions: this.questionsArray[i].scaleOrOptions,
            type: this.questionsArray[i].type,
          });
          continue;
        }
        if (
          this.questionsArray[i].subQnNo &&
          this.questionsArray[i].subQnNo === "OR"
        ) {
          this.allQuestions.push({
            "Sub-Question No.": "OR",
          });
          continue;
        }
        if (this.questionsArray[i].questionNumberAny) {
          this.allQuestions.push({
            "Question No.": this.questionsArray[i].questionNumberAny,
          });
          continue;
        }
      }
    },
    async questionsObject() {
      try {
        let isNextOrSection = false;
        let allQuestions = [];
        let isNextOrSubSection = false;
        let isNextOrQuestion = false;
        let isNextOrSubQuestion = false;
        let section;
        let set;
        let subSection;
        let question;
        let subQuestion;

        for (let i = 0; i < this.questionsArray?.length; i++) {
          if (
            Array.isArray(this.questionsArray[i].attachmentFiles) &&
            this.questionsArray[i].attachmentFiles?.length > 0
          ) {
            const files = this.questionsArray[i].attachmentFiles.filter(
              (e) => !e.url
            );
            if (Array.isArray(files) && files?.length > 0) {
              const uploadedFiles =
                await this.uploadToDigitalOceanInstance.upload(
                  files,
                  this.userData.uId
                );
              this.questionsArray[i].attachmentFiles = uploadedFiles.filter(
                (e) => e !== null || e !== undefined
              );
              this.allQuestions[i + 1]["attachmentFiles"] = this.questionsArray[
                i
              ].attachmentFiles
                ? this.questionsArray[i].attachmentFiles
                : [];
            } else {
              this.allQuestions[i + 1]["attachmentFiles"] =
                this.questionsArray[i].attachmentFiles;
            }
          }
        }
        for (let i = 0; i < this.allQuestions.length; i++) {
          const questions = this.allQuestions[i];
          if (questions.setName) {
            allQuestions.push({
              questionId: v4(),
              questionType: "Set",
              questionStatement: questions?.setName,
              question: [],
            });
          }
          if (questions?.Section && !isNextOrSection) {
            section = {
              questionId: v4(),
              questionType: "Section",
              questionStatement: questions?.Section,
              questionNumber: questions["Section No."],
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            allQuestions[allQuestions.length - 1]?.question.push(section);
          }
          if (
            questions["Question No."] &&
            typeof questions["Question No."] === "string"
          ) {
            let anyQuestion = questions["Question No."].trim();

            if (anyQuestion.length >= 3 && anyQuestion.slice(0, 3) === "Any") {
              let numberOfQuestionsToSolve = parseInt(
                anyQuestion.slice(-1),
                10
              );
              const lastSection = allQuestions[allQuestions.length - 1];
              if (
                lastSection &&
                (lastSection.questionType === "Section" ||
                  lastSection.questionType === "OrSection")
              )
                lastSection.NumberOfQuestionsToSolve = numberOfQuestionsToSolve;
            }
          }
          if (
            questions["Sub-Question No."] &&
            typeof questions["Sub-Question No."] === "string"
          ) {
            let subQuestion = questions["Sub-Question No."].trim();

            if (subQuestion.length >= 3 && subQuestion.slice(0, 3) === "Any") {
              let numberOfQuestionsToSolve = parseInt(
                subQuestion.slice(-1),
                10
              );
              const lastSection = allQuestions[allQuestions.length - 1];
              const lastSubSectionQuestion =
                lastSection?.question[lastSection?.question.length - 1];
              const lastQuestion =
                lastSubSectionQuestion?.question[
                  lastSubSectionQuestion?.question.length - 1
                ];
              if (
                lastQuestion &&
                (lastQuestion.questionType === "Question" ||
                  lastQuestion.questionType === "OrQuestion")
              )
                lastQuestion.NumberOfQuestionsToSolve =
                  numberOfQuestionsToSolve;
            }
          }
          if (questions["Section No."] === "OR") {
            isNextOrSection = true;
          }
          if (questions.Section && isNextOrSection) {
            isNextOrSection = false;
            section = {
              questionId: v4(),
              questionType: "OrSection",
              questionStatement: questions?.Section,
              questionNumber: questions["Section No."],
              scaleOrOptions: questions?.scaleOrOptions,
              type: questions?.type,
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            allQuestions[allQuestions.length - 1]?.question.push(section);
          }
          if (questions["Question No."] === "OR") {
            isNextOrQuestion = true;
          }
          if (questions.Question && !isNextOrQuestion) {
            question = {
              questionId: v4(),
              questionType: "Question",
              questionStatement: questions.Question,
              questionNumber: questions["Question No."],
              scaleOrOptions: questions?.scaleOrOptions,
              type: questions?.type,
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            const lastSection = allQuestions[allQuestions.length - 1];
            const lastSubSectionQuestion =
              lastSection?.question[lastSection?.question.length - 1];
            if (
              lastSubSectionQuestion &&
              lastSubSectionQuestion.questionType === "Section"
            )
              lastSubSectionQuestion.question.push(question);
            else if (lastSection && lastSection.questionType === "Set")
              lastSection.question.push(question);
            else allQuestions.push(question);
          }

          if (questions.Question && isNextOrQuestion) {
            isNextOrQuestion = false;
            question = {
              questionId: v4(),
              questionType: "OrQuestion",
              questionStatement: questions?.Question,
              questionNumber: questions["Question No."],
              scaleOrOptions: questions?.scaleOrOptions,
              type: questions?.type,
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            const lastSection = allQuestions[allQuestions.length - 1];
            const lastSubSectionQuestion =
              lastSection?.question[lastSection?.question.length - 1];
            if (
              lastSubSectionQuestion &&
              lastSubSectionQuestion.questionType === "Section"
            )
              lastSubSectionQuestion.question.push(question);
            else if (lastSection && lastSection.questionType === "Set")
              lastSection.question.push(question);
            else allQuestions.push(question);
          }
          if (questions["Sub-Question No."] === "OR") {
            isNextOrSubQuestion = true;
          }
          if (questions["Sub-Question"] && !isNextOrSubQuestion) {
            subQuestion = {
              questionId: v4(),
              questionType: "SubQuestion",
              questionStatement: questions["Sub-Question"],
              questionNumber: questions["Sub-Question No."],
              scaleOrOptions: questions?.scaleOrOptions,
              type: questions?.type,
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            const lastSection = allQuestions[allQuestions.length - 1];
            const lastSubSectionQuestion =
              lastSection?.question[lastSection?.question.length - 1];
            const lastQuestion =
              lastSubSectionQuestion?.question[
                lastSubSectionQuestion?.question.length - 1
              ];
            if (
              lastQuestion &&
              (lastQuestion.questionType === "Question" ||
                lastQuestion.questionType === "OrQuestion")
            )
              lastQuestion.question.push(subQuestion);
            else if (
              lastSubSectionQuestion &&
              lastSubSectionQuestion.questionType === "Section"
            )
              lastSubSectionQuestion.question.push(subQuestion);
            else if (lastSection && lastSection?.questionType === "Set")
              lastSection.question.push(subQuestion);
          }
          if (questions["Sub-Question"] && isNextOrSubQuestion) {
            isNextOrSubQuestion = false;
            subQuestion = {
              questionId: v4(),
              questionType: "OrSubQuestion",
              questionStatement: questions["Sub-Question"],
              questionNumber: questions["Sub-Question No."],
              scaleOrOptions: questions?.scaleOrOptions,
              type: questions?.type,
              selectedCos: Array.isArray(questions?.selectedCos)
                ? questions?.selectedCos
                : questions?.selectedCos
                ? questions?.selectedCos.split(",")
                : [],
              selectedPos: Array.isArray(questions?.selectedPos)
                ? questions?.selectedPos
                : questions?.selectedPos
                ? questions?.selectedPos.split(",")
                : [],
              blooms: Array.isArray(questions?.Blooms)
                ? questions?.Blooms
                : questions?.Blooms
                ? questions?.Blooms.split(",")
                : [],
              question: [],
              attachmentFiles: questions?.attachmentFiles
                ? questions?.attachmentFiles
                : [],
            };
            const lastSection = allQuestions[allQuestions.length - 1];
            const lastSubSectionQuestion =
              lastSection?.question[lastSection?.question.length - 1];
            const lastQuestion =
              lastSubSectionQuestion?.question[
                lastSubSectionQuestion?.question.length - 1
              ];
            if (
              lastQuestion &&
              (lastQuestion.questionType === "Question" ||
                lastQuestion.questionType === "OrQuestion")
            )
              lastQuestion.question.push(subQuestion);
            else if (
              lastSubSectionQuestion &&
              lastSubSectionQuestion.questionType === "Section"
            )
              lastSubSectionQuestion.question.push(subQuestion);
            else if (lastSection && lastSection?.questionType === "Set")
              lastSection.question.push(subQuestion);
          }
        }
        this.addQuestionToFeedback(allQuestions);
        this.filledQuestions = allQuestions;
      } catch (error) {
        console.error(error);
      }
    },
    storeExistingQuestionData(allQuestions) {
      let updatedQuestions = this.handleExistingQuestion(
        this.templateData.tempQuestions,
        allQuestions
      );
      return updatedQuestions;
    },
    handleExistingQuestion(oldQuestions, newQuestions) {
      const updateQuestions = (newQuestionsArray, oldQuestionsArray) => {
        newQuestionsArray?.forEach((newQuestion) => {
          const matchingOldQuestion = oldQuestionsArray?.find(
            (oldQuestion) =>
              oldQuestion.questionType === newQuestion.questionType &&
              oldQuestion.questionNumber === newQuestion.questionNumber
          );

          if (matchingOldQuestion) {
            newQuestion.questionId = matchingOldQuestion.questionId;
            newQuestion.scales = matchingOldQuestion.scales;

            if (
              newQuestion.question?.length &&
              matchingOldQuestion.question?.length
            ) {
              updateQuestions(
                newQuestion.question,
                matchingOldQuestion.question
              );
            }
          }
        });
      };

      newQuestions?.forEach((newSet) => {
        const matchingOldSet = oldQuestions?.find(
          (oldSet) => oldSet.questionType === newSet.questionType
        );

        if (matchingOldSet) {
          updateQuestions(newSet.question, matchingOldSet.question);
        }
      });
    },
    async addQuestionToFeedback(allQuestions) {
      let updatedQuestions = this.storeExistingQuestionData(allQuestions);
      try {
        if (this.isSaved) {
          const objToPush = {
            instituteId: this.selectedInstitute,
            templateId: this.templateId,
            allQuestions: updatedQuestions,
          };

          this.templateData["allQuestions"] = allQuestions;
          const response =
            await this.NewFeedbackFormRepositoryInstance.createTemplates(
              this.templateData
            );
          if (response.status.status === 200) {
            showStatus("Successfully saved.", 2000, "success", this);
            this.isNext = false;
          } else {
            showStatus("Something went wrong", 2000, "error", this);
          }
        } else {
          this.goToNextPage(allQuestions);
        }
      } catch (e) {
        console.error(e);
      }
    },
    areRequiredFieldsEmpty() {
      return (
        this.selectedFeedback === undefined ||
        this.selectedStakeholder === undefined ||
        this.selectedStakeholder?.length === 0
      );
    },
    goToNextPage(allQuestions) {
      this.templateData["allQuestions"] = allQuestions;
      this.$store.commit(
        "liveData/set_templateTabDependencies",
        this.templateData
      );
      this.$emit("nextButtonClick");
    },
  },
};
</script>

<style src="./activityQuestions.css"></style>

<template>
  <v-btn
    :color="color"
    :disabled="disabled"
    :loading="loading"
    :outlined="outlined"
    :depressed="depressed"
    :block="block"
    :elevation="elevation"
    @click="handleClick"
    :style="buttonStyle"
    :class="buttonClasses"
  >
    <v-icon v-if="icon" :style="iconStyle">{{
      icon
    }}</v-icon>
    <slot></slot>
  </v-btn>
</template>
  
  <script>
import { theme } from "../../../Constants/Utils/Theme";
export default {
  name: "SecondaryButton",
  data() {
    return {
      secondary: theme.secondary,
      white: theme.white,
    };
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    iconFontSize: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: theme.secondary,
    },
    size: {
      type: String,
      default: "normal",
      validator: (value) =>
        [
          "xxx-small",
          "xx-small",
          "x-small",
          "small",
          "normal",
          "large",
          "x-large",
          "xx-large",
        ].includes(value),
    },
    textColor: {
      type: String,
      default: theme.secondary,
    },
    textFontSize: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    squared: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    depressed: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: String,
      default: "0",
    },
  },
  computed: {
    buttonStyle() {
      const sizeInPixels = this.getDefaultButtonHeight();
      let sizeObj = {
        color: this.textColor,
        fontSize: this.textFontSize,
      };
      if (this.squared) {
        sizeObj["height"] = sizeInPixels;
        sizeObj["lineHeight"] = sizeInPixels;
        sizeObj["width"] = sizeInPixels;
        sizeObj["minHeight"] = sizeInPixels;
        sizeObj["minWidth"] = sizeInPixels;
        sizeObj["padding"] = 0;
      }
      return sizeObj;
    },
    buttonClasses() {
      return {
        [`v-btn--${this.size}`]: true,
      };
    },
    iconStyle() {
      return {
        color: this.textColor,
        fontSize: this.iconFontSize,
        margin: '0 5% 0 0'
      };
    },
  },
  methods: {
    handleClick(event) {
      this.$emit("click", event);
    },
    getDefaultButtonHeight() {
      // Define default heights for Vuetify button sizes
      const sizeMap = {
        "xxx-small": "24px",
        "xx-small": "28px",
        "x-small": "32px",
        small: "36px",
        normal: "40px",
        large: "44px",
        "x-large": "48px",
        "xx-large": "52px",
      };
      return sizeMap[this.size] || sizeMap["normal"];
    },
  },
};
</script>
  
  <style scoped>
/* Add any styles you need here */
</style>
  
import networkManager from "../NetworkManager/index";
import ApiNames from "../ApiNames/Feedbackform";

class FeedbackForm {
  constructor(context) {
    this.context = context;
  }
  async createSettings(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createSettings,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
  async updateSettings(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.updateSettings,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
  async createTemplates(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.createTemplates,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
  async updateTemplates(data) {
    this.context.$store.commit("liveData/set_loading", true);
    const response = await networkManager.postRequest(
      ApiNames.updateTemplates,
      data
    );
    this.context.$store.commit("liveData/set_loading", false);
    return response;
  }
  async getSettings (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSettings, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.settings
  }
  async getTemplatesByTypeAndStakeholder (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTemplatesByTypeAndStakeholder, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.templates
  }
  async getSettingsBySettingId (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getSettingsBySettingId, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.settings
  }
  
  async getTemplates (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getTemplates, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.templates
  }
  async deleteTemplate (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.deleteTemplate, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
}
export default FeedbackForm

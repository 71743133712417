<template src="./activityCreationOfFeedbacks.html"></template>

<script>
import SubjectRepository from "../../../Repository/Subject";
import SemesterRepository from "../../../Repository/Semester";
import FeedbackFormRepository from "../../../Repository/Feedback";
import showStatus from "../../../NetworkManager/showStatus";
import activityAnalysis from "../activityAnalysis/activityAnalysis.vue";
import SoloIcon from "../../../Components/styleGuide/Buttons/Icons/SoloIcon.vue";
import arrayOps from "../../../Services/Utils/arrayOps";
import AccreditationTag from "../../../Components/styleGuide/AccreditationTag.vue"

export default {
  name: "activityCreationOfFeedbacks",
  components: {
    SoloIcon,
    activityAnalysis,
    AccreditationTag
  },
  props: [
    "props_topSelections",
    "props_selectedSemester",
    "props_allFeedacks",
    "props_feedbackSelections",
    "props_getFeedbacks",
    "props_feedbackCount",
  ],
  watch: {
    isCopied: function resetIcon() {
      setTimeout(() => {
        this.isCopied = false;
        this.copyIcon = "mdi-content-copy";
        this.copyIconColor = "black";
      }, 2000);
    },
  },
  data() {
    return {
      chevronIcon: "mdi-chevron-down",
      backgroundColor: "#050D920D",
      textColor: "#707070",
      copyIcon: "mdi-content-copy",
      copyIconColor: "black",
      isCopied: false,
      feedbackNameSearch: "",
      feedbackLoading: false,
      filteredFeedbacksToShow: [],
      selectedColumns: [],
      feedbackHeaders: [
        {
          text: "",
          value: "setting.isForNBA",
          sortable: true,
        },
        {
          text: "Sr.No",
          value: "srNo",
          sortable: true,
        },
        {
          text: "Title",
          value: "formTitle",
          sortable: false,
        },
        {
          text: "Creation Date",
          value: "localStartDate",
          align: "start",
        },
        {
          text: "End Date",
          value: "localEndDate",
        },
        // {
        //   text: "Created By",
        //   align: "start",
        //   value: "createdBy",
        // },
        {
          text: "Type",
          align: "start",
          value: "formType",
        },
        // {
        //   text: "Progress Overview",
        //   align: "start",
        //   value: "progressOverview",
        // },
        {
          text: "Status",
          align: "start",
          value: "conduction",
        },
        // {
        //   text: "Conduction",
        //   align: "center",
        //   value: "conductionDetails",
        // },
        // {
        //   text: "Responses received",
        //   align: "center",
        //   value: "responsesReceived",
        // },
        {
          text: "Analysis Report",
          value: "analysisReport",
          align: "center",
        },
        {
          text: "Action",
          width: "100px",
          value: "action",
          align: "center",
        },
      ],
      feedbacksFor: [
        "Students",
        // "Faculty",
        // "Parents",
        // "Alumni",
        // "Employer/Recruiters",
        // "Guest",
        // "Specific",
      ],
      typeOfFeedbacks: [
        "Course Feedback",
        "Program Feedback",
        // "Teachers Effectiveness",
        // "Alumni Feedback",
        // "Employer Feedback",
        // "Guest Feedback",
        // "Parents Feedback",
        // "Infrastructure Feedback",
        // "Event Feedback",
      ],
      stakeHolderWiseFeedbackList: [
        {
          stackholder: "Students",
          feedbackFor: ["Course Feedback", "Program Feedback"],
        },
        {
          stackholder: "Faculty",
          feedbackFor: [
            "Program Feedback",
            // "Teachers effectiveness",
            // "Event feedback",
            "Other feedback",
          ],
        },
        {
          stackholder: "Parents",
          feedbackFor: [
            "Alumni feedback",
            "Parents feedback",
            "Event feedback",
            "Other feedback",
          ],
        },
        {
          stackholder: "Alumni",
          feedbackFor: ["Alumni feedback", "Event feedback", "Other feedback"],
        },
        {
          stackholder: "Employer/Recruiters",
          feedbackFor: [
            "Employer feedback",
            "Event feedback",
            "Other feedback",
          ],
        },
        {
          stackholder: "Guest",
          feedbackFor: [
            "Guest feedback",
            "Alumni feedback",
            "Event feedback",
            "Other feedback",
          ],
        },
        {
          stackholder: "Infrastructure feedback",
          feedbackFor: ["Event feedback", "Other feedback"],
        },
        {
          stackholder: "Specific",
          feedbackFor: [
            "Specific Students",
            "Specific Faculties",
            "Other feedback",
          ],
        },
      ],
      selectedFeedbackFor: "",
      selectedTypeOfFeedback: "",
      allCourseYears: [],
      allCourses: [],
      allCoursesWithAllOption: [{ subjectName: "All", id: "all" }],
      selectedSemester: {},
      selectedDepartment: "",
      selectedProgram: "",
      selectedCourseYear: "",
      selectedCourse: [],
      selectedOption: "",
      allDivisions: [],
      selectedDivision: [],
      selectedBatch: "",
      selectedGroup: "",
      allDepartments: [],
      allGroups: [],
      allBatches: [],
      allSubjects: [],
      groupForSubjects: [],
      group: {},
      selections: [],
      feedbackSelections: {},
      isFeedbackSelectionsSaved: false,
      allFeedacks: [],
      selectedFeedback: {},
      feedbackDeleteDialog: false,
      feedbackDeletionObject: {},
      isShow: false,
      analysisModal: false,
      feedbacks: [],
      isFirstTime: null,
    };
  },
  computed: {
    isAllSelected() {
      return this.selectedCourse.length === this.allCoursesWithAllOption.length;
    },
    selectAllIcon() {
      return this.isAllSelected
        ? "mdi-checkbox-marked"
        : "mdi-checkbox-blank-outline";
    },
  },
  async created() {
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.feedbackFormRepositoryInstance = new FeedbackFormRepository(this);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];

    this.allDepartments = this.props_feedbackSelections.departments;
    this.allCourseYears = this.props_feedbackSelections.courses;
    this.selectedSemester = this.props_feedbackSelections.semester;
    this.selectedColumns = this.headersExam;
    this.feedbacks = this.props_allFeedacks;

    this.allFeedacks = arrayOps.sortByProperty(
      this.props_allFeedacks,
      "createdAt",
      "desc"
    );
    this.isFirstTime = true;
  },
  watch: {
    props_allFeedacks(newVal, oldVal) {
      if (this.isFirstTime) {
        this.isFirstTime = false;
      } else {
        // Check if the new value is different from the old one
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.allFeedacks = arrayOps.sortByProperty(
            newVal,
            "createdAt",
            "desc"
          );
        }
      }
    },
  },
  methods: {
    gotoFeedback() {
      this.$router.push({
        name: "activityCreateFeedbackform",
        params: {
          // props_selections: this.selections,
          props_selections: this.props_feedbackSelections,
          props_selectedFeedback: {},
        },
      });
      this.$store.commit("liveData/set_createdFeedback", {});
    },
    gotoFeedbackForm(item) {
      this.$router.push({
        name: "activityCreateFeedbackform",
        params: {
          props_selections: {
            courseYear: item.courseYear,
            department: item.department,
            selectedFeedbackFor: item.stakeholder,
            selectedTypeOfFeedback: item.formType,
            conduction: item.conduction,
          },
          props_selectedFeedback: item,
        },
      });
      this.$store.commit("liveData/set_createdFeedback", {});
    },
    handleAnalysis(item) {
      item["coursesWiseQuestions"] = item.courses || item.allQuestions;
      this.analysisModal = true;
      this.$store.commit("liveData/set_createdFeedback", item);
    },
    copyLink(item) {
      // const feedbackURL = `https://alpha-student-feedback-git-featfeedbackurl-studium-tech-pvt-ltd.vercel.app/${item.formURL}`;
      const feedbackURL = `https://feedbacks.studiumtech.in/${item.formURL}`;
      navigator.clipboard.writeText(feedbackURL);
      item.isCopied = true;
      if (item.isCopied) {
        item.copyIcon = "mdi-check-circle";
        item.copyIconColor = "green";
      }
      showStatus("Copied to Clipboard", 2000, "success", this);
    },
    async deleteFeedbackDialogFunc(item) {
      try {
        this.feedbackDeletionObject = item;
        this.feedbackDeleteDialog = true;
      } catch (e) {
        console.error(e);
      }
    },
    handleSelectedFeedbacks() {
      this.isShow = false;
      // this.allFeedacks = [];
      this.selectedDepartment = "";
      this.selectedCourseYear = "";
      this.selectedCourse = "";
      this.selectedDivision = "";
      this.selectedTypeOfFeedback = "";

      let selectedFeebacks = this.stakeHolderWiseFeedbackList.find(
        (item) => item.stackholder === this.selectedFeedbackFor
      );
      this.typeOfFeedbacks = selectedFeebacks?.feedbackFor;
    },
    handleAYBatch() {
      if (this.selectedOption === "Group") {
        this.allSubjects.map((item) => {
          if (item.subjectName === this.selectedCourse.subjectName) {
            this.groupForSubjects = item.groupForSubject;
            this.allGroups = item.groupForSubject.map(
              (item1) => item1.groupName
            );
          }
        });
      }
    },
    handleTypeOfFeedback() {
      this.isShow = false;
      // this.allFeedacks = [];
      this.selectedDepartment = "";
      this.selectedCourseYear = "";
      this.selectedCourse = "";
      this.selectedDivision = "";
    },
    handleDepartment() {
      this.isShow = false;
      // this.allFeedacks = [];
      this.selectedCourseYear = "";
      this.selectedCourse = "";
      this.selectedDivision = "";
    },
    handleCourseYear() {
      this.isShow = false;
      // this.allFeedacks = [];
      this.selectedCourse = "";
      this.selectedDivision = "";
      this.getSubjects();
      this.getDivisions();
    },
    handleCourse(selected) {
      // this.allFeedacks = [];
      this.isShow =
        this.selectedFeedbackFor !== "" &&
        this.selectedTypeOfFeedback !== "" &&
        this.selectedDepartment !== "" &&
        this.selectedCourseYear !== "" &&
        this.selectedCourse.length > 0;
    },
    handleDivision() {
      // this.allFeedacks = [];
      this.isShow =
        this.selectedFeedbackFor !== "" &&
        this.selectedTypeOfFeedback !== "" &&
        this.selectedDepartment !== "" &&
        this.selectedCourseYear !== "" &&
        this.selectedDivision.length > 0;
    },
    handleBatch() {},
    handleGroup() {
      this.group = this.groupForSubjects.find(
        (item) => item.groupName === this.selectedGroup
      );
    },
    async saveSelections() {
      this.feedbackSelections.selectedFeedbackFor = this.selectedFeedbackFor;
      this.feedbackSelections.selectedTypeOfFeedback =
        this.selectedTypeOfFeedback;
      this.feedbackSelections.semester = this.props_topSelections.semester;
      this.feedbackSelections.department = this.selectedDepartment;
      this.feedbackSelections.courseYear = this.selectedCourseYear;

      this.selections = {
        topSelection: {
          selected: this.props_topSelections.selected,
          academic: this.props_topSelections.academic,
          batch: this.props_topSelections.batch,
        },
        selectedFeedbackFor: this.selectedFeedbackFor,
        selectedTypeOfFeedback: this.selectedTypeOfFeedback,
        semester: this.props_topSelections.semester,
        department: this.selectedDepartment,
        courseYear: this.selectedCourseYear,
        selection: this.selectedOption,
      };

      if (this.selectedTypeOfFeedback === "Course Feedback") {
        let selectedCourse = [];
        this.selectedCourse?.map((item) => {
          selectedCourse.push({
            subjectName: item.subjectName,
            subjectId: item.subjectId,
          });
        });
        this.selections.course = this.selectedCourse;
        this.feedbackSelections.course = this.selectedCourse;
        this.feedbackSelections.selectedSubjectIds = selectedCourse;
      }
      if (this.selectedTypeOfFeedback === "Program Feedback") {
        this.selections.division = this.selectedDivision;
        this.feedbackSelections.division = this.selectedDivision;
        this.feedbackSelections.divisions = this.selectedDivision;
      }

      this.$store.commit(
        "liveData/set_feedbackTopSelections",
        this.feedbackSelections
      );
      this.isFeedbackSelectionsSaved = true;
    },
    async getSubjects() {
      try {
        this.allCourses = [];
        this.allSubjects =
          await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
            {
              instituteId: this.selectedInstitute,
              semId: this.selectedSemester.semId,
              department: this.selectedDepartment,
              courseYear: this.selectedCourseYear,
            }
          );
        this.allCourses = this.allSubjects;
        this.allCoursesWithAllOption.push(...this.allSubjects);
      } catch (e) {
        console.error(e);
      }
    },
    async getDivisions() {
      try {
        this.allDivisions = [];
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          semName: this.selectedSemester.semName,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
        };
        const semestersList =
          await this.semesterRepositoryInstance.getSemestersForACourseYear(
            objToPush
          );
        this.allDivisions = semestersList.map((item) => item.division);
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async getDivisionBatches() {
      try {
        let batches = [];
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          division: this.selectedDivisions,
        };
        batches = await this.semesterRepositoryInstance.getBatchesDetails(
          objToPush
        );
        this.allBatches = batches.batchesDetails.map((item) => item.batchName);
      } catch (e) {
        console.error(e);
      }

      this.loading = false;
    },
    async deleteFeedback() {
      try {
        let objToPush = {
          feedbackID: this.feedbackDeletionObject._id,
        };
        let feedbackDelete =
          await this.feedbackFormRepositoryInstance.deleteFeedbackForm(
            objToPush
          );
        if (feedbackDelete === "OK") {
          showStatus("Feedback Deleted Successfully.", 2000, "success", this);
          let allFeedacks = await this.props_getFeedbacks();

          this.allFeedacks = arrayOps.sortByProperty(
            allFeedacks,
            "createdAt",
            "desc"
          );
        } else {
          showStatus("Something went wrong", 2000, "error", this);
        }
        this.feedbackDeleteDialog = false;
      } catch (e) {
        console.error(e);
      }
    },
    convertDateTimeToUTCFormat(localDateOrUTC, localTime = null) {
      try {
        let dateObj;

        if (localTime) {
          // Combine the local date and time if both are provided
          const localDateTime = `${localDateOrUTC} ${localTime}`;
          dateObj = new Date(localDateTime);

          // Check if the Date object is valid
          if (isNaN(dateObj.getTime())) {
            throw new Error(
              "Invalid date or time format. Please use 'YYYY-MM-DD' for the date and 'HH:MM' for the time."
            );
          }

          // Convert the Date object to a UTC string
          const utcDateTime = dateObj.toISOString();

          // Format the UTC string to the desired format
          return utcDateTime.replace(/\.\d{3}Z$/, "Z");
        } else {
          // If only one argument is passed, assume it is in UTC and parse it
          dateObj = new Date(localDateOrUTC);

          // Check if the Date object is valid
          if (isNaN(dateObj.getTime())) {
            throw new Error(
              "Invalid date format. Please use 'YYYY-MM-DDTHH:MM:SSZ' or similar format."
            );
          }

          // Convert the UTC date to local date and time
          const localDate = dateObj.toLocaleDateString("en-CA"); // Format: YYYY-MM-DD
          const localTime = dateObj.toLocaleTimeString("en-US", {
            hour12: false,
          }); // Format: HH:MM:SS

          return { date: localDate, time: localTime };
        }
      } catch (error) {
        return `Error: ${error.message}`;
      }
    },
  },
};
</script>

<style src="./activityCreationOfFeedbacks.css" scoped></style>

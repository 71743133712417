<template src="./activitySettingsAndTemplates.html"></template>

<script>
import showStatus from "../../../NetworkManager/showStatus";
import NewFeedbackFormRepository from "../../../Repository/Feedbackform";
import activityQuestionTemplate from "../activityQuestionTemplate/activityQuestionTemplate.vue";

export default {
  name: "activitySettingsAndTemplates",
  components: {
    activityQuestionTemplate,
  },
  data() {
    return {
      headers: [
        {
          text: "Sr",
          align: "start",
          sortable: false,
          value: "srNo",
        },
        { text: "Template Type", value: "typeOfFeedback" },
        { text: "Stakeholder", value: "stakeHolders" },
        { text: "Date of Creation", value: "createdAt" },
        { text: "Questions added", value: "questionsAdded" },
        { text: "Actions", value: "actions" },
      ],
      goToTemplate: false,
      allTemplates: [],
      feedbackDeleteDialog: false,
      deletionObject: {},
      createdTemplates: [],
    };
  },
  async created() {
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.$store.commit("liveData/set_templateTabDependencies", {});
    this.NewFeedbackFormRepositoryInstance = new NewFeedbackFormRepository(
      this
    );
    this.getTemplates();
  },
  methods: {
    goToQuestionTab() {
      this.$router.push({
        name: "activityQuestionTemplate",
        params: { createdTemplates: this.createdTemplates },
      });
    },
    goToAddQuestions() {
      this.$router.push({
        name: "activityQuestionTemplate",
        params: { createdTemplates: this.createdTemplates },
      });
    },
    goToTemplateCreation(item, currentTab) {
      if (currentTab === 2) {
        item.moveToquestionTab = true;
      } else {
        item.moveToquestionTab = false;
      }
      this.$store.commit("liveData/set_templateTabDependencies", item);
      this.goToTemplate = true;
      this.$router.push({
        name: "activityQuestionTemplate",
        params: {
          createdTemplates: [],
        },
      });
    },
    goToFeedbackDialog(item) {
      this.deletionObject = item;
      this.feedbackDeleteDialog = true;
    },
    async getTemplates() {
      try {
        this.createdTemplates = []
        const objToPush = {
          instituteId: this.selectedInstitute,
        };
        this.allTemplates =
          await this.NewFeedbackFormRepositoryInstance.getTemplates(objToPush);
        this.allTemplates?.forEach((item1) => {
          let quesLength = 0;
          item1?.allQuestions?.map((item2) => {
            item2.question.map((item3) => {
              quesLength = item3.question.length;
            });
          });
          item1.questionsAdded =
            quesLength > 0 ? `${quesLength}Qns` : "Add Qns";
          this.createdTemplates.push(item1.typeOfFeedback);
        });
      } catch (e) {
        console.error(e);
      }
    },
    async deleteTemplate() {
      try {
        const objToPush = {
          templateId: this.deletionObject.templateId,
        };
        let deleteTemplate =
          await this.NewFeedbackFormRepositoryInstance.deleteTemplate(
            objToPush
          );
        if (deleteTemplate.status === 200) {
          await this.getTemplates();
          this.feedbackDeleteDialog = false;
          showStatus("Successfully Deleted.", 2000, "success", this);
        } else {
          showStatus("Something went wrong", 2000, "error", this);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style src="./activitySettingsAndTemplates.css"></style>

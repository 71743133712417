<template src="./examQuestionDetails.html"></template>

<script>
import showStatus from "../../../NetworkManager/showStatus";
import NewExamRepository from "../../../Repository/NewExam";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion";
import SubjectRepository from "../../../Repository/Subject";
import NbaRepository from "../../../Repository/NBA";
import { v4 } from "uuid";
export default {
  name: "examQuestionDetails",
  props: [
    'prop_examTagDetails'
  ],
  data() {
    return {
      progressIndicator1: 0,
      examName: "",
      descriptionOfExam: "",
      backgroundColor: "#E7E7FFCF",
      calenderIcon: "mdi-calendar-month-outline",
      rules: {
        required: (value) => !!value || "This field is required",
        totalMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
        passingMarks: (value) =>
          /^\d*$/.test(value) || "Only numbers are allowed.",
      },
      examFormat: null,
      startDateModel: false,
      startDate: null,
      endDateModel: false,
      endDate: null,
      attainmentCheckbox: false,
      examFormCheckbox: false,
      hallTicketCheckbox: false,
      invigilationCheckbox: false,
      exam: {},
      examToEdit: null,
      examId: null,
      secondaryTabItems: ["Questions Paper Overview"],
      tabs1: 0,
      search: "",
      selectedSubjects: [],
      studentsSearch: "",
      createdQuestions: [],
      headers: [
        { text: "Sr No.", value: "srNo", sortable: false },
        { text: "Subject code", value: "subjectCode", sortable: true },
        { text: "Subject name", value: "subjectName", sortable: true },
        { text: "Total marks", value: "totalMarks", sortable: false },
        { text: "Question Paper", value: "quePaper", sortable: false },
        { text: "Model Answers", value: "modelAnswers", sortable: false },
      ],
      coDialog: false,
      bloomsDialog: false,
      nbaData: [],
      mappedCOs: [],
      totalCos: [],
      item: {},
      mappedBlooms: [],
      totalBlooms: [
        {
          name: "CREATE (C)",
          shortCode: "C",
          description:
            "Produce new or original work (Verbs : Design, assemble, contract, conjecture, develop, formulate, author, investigate)",
        },
        {
          name: "EVALUATE (E)",
          shortCode: "E",
          description:
            "Justify a stand or decision (Verbs : appraise, argue, defend, judge, select, support, value, critique, weigh)",
        },
        {
          name: "ANALYZE (A)",
          shortCode: "A",
          description:
            "Draw connections among ideas (Verbs : differentiate, organise, relate, compare, contrast, distinguish, examine, experiment, question, test)",
        },
        {
          name: "APPLY (Ap)",
          shortCode: "Ap",
          description:
            "Use information in new situations (Verbs : execute, implement, solve, use, demonstrate, interpret, operate, schedule, sketch)",
        },
        {
          name: "UNDERSTAND (U)",
          shortCode: "U",
          description:
            "Explain ideas of concepts (Verbs : classify, describe, discuss, explain, identify, locate, recognise, report, select, translate)",
        },
        {
          name: "REMEMBER (R)",
          shortCode: "R",
          description:
            "Recall facts and basic concepts (Verbs : define, duplicate, list, memorise, repeat, state)",
        },
      ],
      examTabDependecies: {},
      dataLoader: false,
      primaryColor: "#050D92",
    };
  },
  async created() {
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.examSubjectAndQuestionRepositoryInstance =
      new ExamSubjectAndQuestionRepository(this);
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester =
      this.$store.getters["instituteData/get_selectedSemester"];
    this.examId = this.$store.getters["liveData/get_examId"];
    if (this.examId !== null) {
      this.fillExamData();
    }
    await this.fetchData();
    // if (this.examToEdit) {
    //     console.log('only then do this')
    //     this.fetchExamData()
    // }
    // console.log("exam to edit", this.examToEdit)
    this.prepareExamDataForParent()
  },
  async activated() {
    // console.log("activated");
    // Your logic here
    let newValue = this.$store.state.liveData.examTabDependencies;
    let flag = false;
    Object.entries(newValue).forEach(([key, value]) => {
      // console.log("examTabDependecies changed:123456' value", value);
      if (value["questionAndAnswersTab"] === true) {
        flag = true;
        value["questionAndAnswersTab"] = false;
      }
    });
    // console.log("flag", flag);
    if (flag === true) {
      // console.log("here to fetch data");
      await this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      this.dataLoader = true
      await this.fetchSubjects();
      await this.getExam();
      await this.getSubjectData();

      // for (let i = 0; i < this.nbaData.data.length; i++) {
      //     this.coNames.push(this.nbaData.data[i].CONumber)
      // }
      this.calculateProgresBar();
      this.dataLoader = false
    },
    handleOptions() {
      let objToPush = {
        examForm: this.examFormCheckbox,
        hallticket: this.hallTicketCheckbox
      }
      this.$emit("changeExtraOptions", objToPush);
      this.calculateProgress()
    },
    prepareExamDataForParent() {
      let objToPush = {
        examCategory: this.exam.examCategory ?? 1,
        isRegularExam: this.exam.isRegularExam ?? true,
        parentExamId: this.exam.parentExamId ?? "",
        examAttemptNumber: this.exam.examAttemptNumber ?? 1,
      };
      this.$emit("examDetailsFetch", objToPush);
    },
    // async fetchCosSubjectWise(){

    //     this.nbaData = await this.nbaRepositoryInstance.getCos({
    //         lmsInstituteId: this.selectedInstitute,
    //         semesterId: this.selectedSemester.semId,
    //         subjectId: this.prop_selectedSubjectId
    //     });
    // },
    async fetchSubjects() {
      const allSubjects =
        await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester(
          {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
          }
        );
      // console.log("allSubjects", allSubjects);
      this.subjects = allSubjects.map((s) => {
        // console.log("respon", s);
        const { subjectCode, subjectName, subjectId } = s;
        return { subjectCode, subjectName, subjectId };
      });
      // console.log("Subjectss", this.subjects);

      // console.log("response of the object", response)
    },
    async getExam() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
          examId: this.examId,
        };
        let res = await this.newExamRepositoryInstance.getExam(objToPush);
        this.exam = res.exam;
        // console.log("91093",!this.headers.some(item => item.value === "cos"))
        if (this.exam.settings.isForAttainment === true && !this.headers.some(item => item.value === "threshold")) {
            this.headers.splice(4, 0, { text: "Threshold", value: "threshold", sortable: false },);
        }
        if (this.exam.settings.isForAttainment === true && !this.headers.some(item => item.value === "cos")) {
            this.headers.splice(6, 0, { text: "Mapped Cos", value: "cos", sortable: false }, { text: "Mapped Blooms", value: "blooms", sortable: false });
        }
        // console.log("exam resssssscueddss", this.exam);
        // console.log("subjects detials", this.subjects);
        // console.log("subjects selected", this.selectedSubjects);
        // this.exam = res
      } catch (error) {
        console.error("error", error);
      }
    },
    async getSubjectData() {
      // console.log("response 99", this.exam);

      let response =
        await this.examSubjectAndQuestionRepositoryInstance.getExamMultipleSubjectQuestions(
          {
            instituteId: this.selectedInstitute,
            examId: this.examId,
            subjectIds: this.exam.subjectIds,
          }
        );
      // console.log("response of subjects 99", response);
      let subjectsData = response.result;

      let filteredSubjects = this.subjects
        .map((subject) => {
          let dataFound = subjectsData.find(
            (d) => d?.subjectId === subject?.subjectId
          );

          if (dataFound) {
            // console.log("dataFound", data/Found);
            console.log('dataFound.allQuestions', dataFound.allQuestions);
            return {
              subjectCode: subject.subjectCode,
              subjectId: subject.subjectId,
              subjectName: subject.subjectName,
              duration: dataFound.duration,
              threshold: dataFound?.threshold || "-",
              passingMarks: dataFound?.passingMarks || "-",
              totalMarks: dataFound?.totalMarks || "-",
              examDate: dataFound.examDate,
              examTime: dataFound.examTime,
              allocatedStudents: dataFound.allocatedStudents,
              allQuestions: dataFound.allQuestions,
              overallCOs: dataFound?.overallCOs,
              overallBlooms: dataFound?.overallBlooms,
            };
          }
          return undefined;
        })
        .filter((item) => item !== undefined);

      this.selectedSubjects = filteredSubjects;
      for (let i = 0; i < this.selectedSubjects.length; i++) {
        this.createdQuestions = this.selectedSubjects[i];
        this.getQuestionsOfSelectedSet(this.selectedSubjects[i]);
      }
      await this.fillCosOfSubjects();
      await this.fillBloomsOfSubjects();
    },
    async fillCosOfSubjects() {
      const promises = this.selectedSubjects.map(async (sub) => {
        if (sub?.overallCOs?.length > 0) {
          let temp = await this.getCourseOutcomes(sub.subjectId);
          let temp2 = temp.filter((co) => sub.overallCOs.includes(co.coNumber));
          this.$set(sub, "mappedCOs", temp2);
          this.$set(sub, "mappedCOsCount", temp2.length);
        }
      });
      await Promise.all(promises);
    },
    async fillBloomsOfSubjects() {
      this.selectedSubjects.forEach((sub) => {
        if (sub.overallBlooms?.length > 0) {
          let temp = this.totalBlooms.filter((bloom) =>
            sub.overallBlooms.includes(bloom.name)
          );
          this.$set(sub, "mappedBlooms", temp);
          this.$set(sub, "mappedBloomsCount", temp.length);
        }
      });
    },
    async fillExamData() {
      await this.getExam();
      this.examName = this.exam.title;
      this.descriptionOfExam = this.exam.description;
      this.examFormat = this.exam.examFormat;
      this.startDate = this.exam.publishDate?.split("T0")[0];
      this.endDate = this.exam.dueDate?.split("T0")[0];
      this.attainmentCheckbox = this.exam.settings.isForAttainment;
      this.examFormCheckbox = this.exam.settings.isExamFormIncluded;
      this.hallTicketCheckbox = this.exam.settings.isHallTicketIncluded;
      this.invigilationCheckbox = this.exam.settings.isInvigilationIncluded;
    },
    // async fetchAssignmentQuestions() {
    //     if (this.exam !== null) {
    //         const assignmentQuestions = await this.examSubjectAndQuestionRepositoryInstance.getExamSubjectQuestions({
    //             instituteId: this.selectedInstitute,
    //             examId: this.examId,
    //             subjectId: this.prop_selectedSubjectId
    //         })
    //         this.createdQuestions = assignmentQuestions.result
    //         if (this.createdQuestions.overallCOs.length > 0 || this.createdQuestions.overallBlooms.length > 0) {
    //             this.row = 'radio-1'
    //             this.overallCosBloomsData = true
    //         }
    //         this.overallCos = this.createdQuestions.overallCOs
    //         this.overallBlooms = this.createdQuestions.overallBlooms
    //     }
    //     if (this.createdQuestions?.allQuestions.length > 0) {
    //         this.getAllSections()
    //         this.activeSet = {
    //             no: 1,
    //             setName: 'set 1'
    //         }
    //         this.setActiveSet(this.activeSet)
    //         this.createdSets = []
    //         this.setsObjectForQuestions = {}
    //         for (let i = 0; i < this.createdQuestions.allQuestions.length; i++) {
    //             if (this.createdQuestions.allQuestions[i].questionType === 'Set') {
    //                 const questionStatement = this.createdQuestions.allQuestions[i].questionStatement;
    //                 this.createdSets.push({
    //                     no: i + 1,
    //                     setName: questionStatement
    //                 })
    //                 if (!Object.prototype.hasOwnProperty.call(this.setsObjectForQuestions, questionStatement)) {
    //                     this.setsObjectForQuestions[questionStatement] = [];
    //                 }
    //                 this.setsObjectForQuestions[questionStatement].push(...this.getQuestionsOfSelectedSet({ setName: questionStatement, forShowingQuestions: true }, null));
    //             }
    //         }
    //         this.questionsArray = this.setsObjectForQuestions['set 1']
    //     }
    // },
    // getAllSections() {
    //     this.allSections = []
    //     for (let i = 0; i < this.createdQuestions?.allQuestions.length; i++) {
    //         if (this.createdQuestions?.allQuestions[i].questionType === 'Set') {
    //             for (let j = 0; j < this.createdQuestions.allQuestions[i].question.length; j++) {
    //                 this.allSections.push({ questionId: this.createdQuestions.allQuestions[i].question[j].questionId, sectionName: this.createdQuestions.allQuestions[i].question[j].questionStatement, obtainedMarks: null, questionMarks: this.createdQuestions.allQuestions[i].question[j].questionMarks })
    //             }
    //         }
    //     }
    //     for (let i = 0; i < this.students.length; i++) {
    //         this.students[i].allSections = this.allSections
    //     }
    // },
    // setActiveSet(set) {
    //     this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray
    //     this.activeSet = set;
    //     this.questionsArray = this.setsObjectForQuestions[this.activeSet.setName]
    // },
    getQuestionsOfSelectedSet(questionObject) {
      let count = 0;
      let COsCount = 0;
      let anyQuestionFound = false;
      let setQuestions = [];
      for (let i = 0; i < this.createdQuestions.allQuestions.length; i++) {
        if (this.createdQuestions.allQuestions[i].questionType === "Set") {
          setQuestions = this.createdQuestions.allQuestions[i].question;
        }
      }
      this.seperatedSetQuestions = [];

      for (let i = 0; i < setQuestions?.length; i++) {
        anyQuestionFound = false;
        count++;
        if(setQuestions[i]?.cos?.length >0){
            COsCount++;
        }
        this.seperatedSetQuestions.push({
          sectionNames: setQuestions[i].questionNumber,
          question: setQuestions[i].questionStatement,
          questionId: setQuestions[i].questionId,
          questionMarks: setQuestions[i].questionMarks,
          selectedCos: setQuestions[i]?.cos,
          cos: this.coNames,
          selectedBlooms: setQuestions[i].blooms,
          blooms: this.bloomsNames,
        });
        if (setQuestions[i].question?.length > 0) {
          const sectionQuestion = setQuestions[i].question;
          for (let j = 0; j < sectionQuestion?.length; j++) {
            if (sectionQuestion[j].questionType === "OrQuestion") {
              anyQuestionFound = false;
              this.seperatedSetQuestions.push({
                questionNumber: "OR",
              });
            }
            anyQuestionFound = false;
            count++;
            if(sectionQuestion[j]?.cos?.length > 0){
            COsCount++;
             }
            this.seperatedSetQuestions.push({
              questionNumber: sectionQuestion[j]?.questionNumber,
              question:
                sectionQuestion[j]?.questionStatement === "Solve any_out of"
                  ? "Solve any (" +
                    sectionQuestion[j].NumberOfQuestionsToSolve +
                    ")"
                  : sectionQuestion[j].questionStatement,
              tryl: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              anyCount: sectionQuestion[j]?.NumberOfQuestionsToSolve,
              outOfCount: sectionQuestion[j]?.question?.length,
              solveAnyCount:
                sectionQuestion[j]?.questionStatement === "Solve any_out of"
                  ? 0
                  : sectionQuestion[j]?.NumberOfQuestionsToSolve,
              questionId: sectionQuestion[j]?.questionId,
              questionMarks: sectionQuestion[j]?.questionMarks,
              selectedCos: sectionQuestion[j]?.cos,
              cos: this.coNames,
              selectedBlooms: sectionQuestion[j]?.blooms,
              blooms: this.bloomsNames,
            });
            if (sectionQuestion[j].NumberOfQuestionsToSolve) {
              anyQuestionFound = true;
              this.seperatedSetQuestions.push({
                subQnNo: "Any " + sectionQuestion[j].NumberOfQuestionsToSolve,
                anyCount: sectionQuestion[j].NumberOfQuestionsToSolve,
                anyStatement: true,
              });
            }
            if (sectionQuestion[j]?.question?.length > 0) {
              const subQuestion = sectionQuestion[j]?.question;
              for (let k = 0; k < subQuestion.length; k++) {
                if (subQuestion[k].questionType === "OrSubQuestion") {
                  this.seperatedSetQuestions.push({
                    subQnNo: "OR",
                    anyStatement: false,
                  });
                }
                if (anyQuestionFound) {
                  count++;

                  if(subQuestion[k]?.cos?.length > 0){
                    COsCount++;
                    }
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anySubQuestion: true,
                    anyStatement: true,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    selectedCos: subQuestion[k].cos,
                    cos: this.coNames,
                    selectedBlooms: subQuestion[k].blooms,
                    blooms: this.bloomsNames,
                  });
                } else {
                  anyQuestionFound = false;
                  count++;
                  if(subQuestion[k]?.cos?.length > 0){
                    COsCount++;
                    }
                  this.seperatedSetQuestions.push({
                    subQnNo: subQuestion[k].questionNumber,
                    anyStatement: false,
                    question: subQuestion[k].questionStatement,
                    questionId: subQuestion[k]?.questionId,
                    questionMarks: subQuestion[k].questionMarks,
                    selectedCos: subQuestion[k].cos,
                    cos: this.coNames,
                    selectedBlooms: subQuestion[k].blooms,
                    blooms: this.bloomsNames,
                  });
                }
              }
            }
          }
        }
      }
      questionObject.questionsCount = count;
      questionObject.questionWiseCOsCount =  COsCount;
      //   console.log("COsCount", COsCount)
      // console.log("questionObject", questionObject);
      // this.questionWiseMarksDialog = true
      // if (!set.forShowingQuestions && this.studentData?.answers) {
      //     const studentDataMap = new Map(this.studentData?.answers.map(data => [data.questionId, data]));
      //     for (const setQuestion of this.seperatedSetQuestions) {
      //         const studentDataForQuestion = studentDataMap.get(setQuestion.questionId);
      //         if (studentDataForQuestion) {
      //             setQuestion.assignMarks = studentDataForQuestion.obtainedMarks;
      //         }
      //     }
      //     this.questionWiseMarksDialog = true
      // } else return this.seperatedSetQuestions
    },
    goToAddQuestions(item) {
      this.$router.push({
        name: "examQuestions",
        params: {
          // prop_department: this.prop_department,
          // prop_course: this.prop_course,
          prop_selectedSubject: item.subjectName,
          prop_selectedSubjectId: item.subjectId,
          prop_tabNumber: 4,
          prop_allSubjects: this.selectedSubjects,
          prop_subject: item,
          prop_examTagDetails: this.prop_examTagDetails
          // prop_typeDivGrpBatch: this.prop_typeDivGrpBatch,
          // prop_selectedDivision: this.prop_selectedDivision,
          // prop_selectedBatch: this.prop_selectedBatch,
          // prop_selectedGroup: this.prop_selectedGroup
        },
      });
    },
    goToNextPage() {
      this.$emit("nextButtonClick");
    },
    calculateProgresBar() {
      let createdQuestionPaper = this.selectedSubjects.filter(
        (sub) => sub.questionsCount > 0
      ).length;
      let total = this.selectedSubjects.filter(
        (sub) => sub.totalMarks !== "-"
      ).length;
      this.progressIndicator1 = (createdQuestionPaper / total) * 100;
    },
    async getCourseOutcomes(subjectId) {
      try {
        const nbaData = await this.nbaRepositoryInstance.getCos({
          lmsInstituteId: this.selectedInstitute,
          semesterId: this.selectedSemester.semId,
          subjectId: subjectId,
        });
        this.totalCos = [];
        nbaData.data.map((d) => {
          this.totalCos.push({
            coNumber: d.CONumber,
            coName: d.COName,
          });
        });
      } catch (error) {
        console.error("error", error);
      }
      // this.mappedCos = this.totalCos
      return this.totalCos;
    },
    async getBlooms() {},
    async handleSubjectCos(item) {
      this.mappedCOs = [];
      this.coDialog = true;
      this.item = item;
      await this.getCourseOutcomes(item.subjectId);
      if (Array.isArray(this.item.mappedCOs)) {
        this.mappedCOs = [...this.item.mappedCOs];
      } else {
        this.mappedCOs = []; // Assign an empty array if item.mappedCOs is not an array
      }
      this.$set(this.item, "mappedCOs", this.mappedCOs);
      // console.log("item after set", this.item);
      //   this.item.mappedCOs = this.mappedCOs;
    },
    addSubjectCos() {
      this.coDialog = false;
      this.$set(this.item, "mappedCOs", this.mappedCOs);
      this.$set(this.item, "mappedCOsCount", this.mappedCOs.length);
      // console.log("item add", this.item);
    },
    handleBlooms(item) {
      // console.log("blooms", item);
      this.mappedBlooms = [];
      this.bloomsDialog = true;
      this.item = item;
      if (Array.isArray(this.item.mappedBlooms)) {
        this.mappedBlooms = [...this.item.mappedBlooms];
      } else {
        this.mappedBlooms = []; // Assign an empty array if item.mappedCOs is not an array
      }
      this.$set(this.item, "mappedBlooms", this.mappedBlooms);
      // console.log("item after set", this.item);
    },
    addSubjectBlooms() {
      this.bloomsDialog = false;
      this.$set(this.item, "mappedBlooms", this.mappedBlooms);
      this.$set(this.item, "mappedBloomsCount", this.mappedBlooms.length);
      // console.log("item add", this.item);
    },
    async saveSubjectDetails() {
    
        let subjectsToUpdate = this.selectedSubjects.map((s) => {
          const { mappedCOs,mappedBlooms, subjectId } = s;
          const overallCOs = mappedCOs?.map((c) => c.coNumber);
          const overallBlooms = mappedBlooms?.map((b) => b.name);
          const { examId, selectedInstitute: instituteId } = this;

          return {
            instituteId,
            examId,
            subjectId,
            ...(overallCOs !== undefined && { overallCOs: overallCOs }), // Conditionally include cosArray
            ...(overallBlooms !== undefined && { overallBlooms: overallBlooms }), // Conditionally include cosArray
          };
        });
        // console.log("s", subjectsToUpdate);
        try {
          let response =
            await this.examSubjectAndQuestionRepositoryInstance.bulkCreateExamSubjectQuestions(
              subjectsToUpdate
            );
          if (response.status.status === 200) {
           await this.saveExamSettings();
            for(let key in  this.examTabDependecies['questionAndAnswersTab']){
                // console.log("key99", key);
                this.examTabDependecies['questionAndAnswersTab'][key] = true;
            }
            showStatus(
              "Successfully saved basic details of the exam.",
              2000,
              "success",
              this
            );
          }
        } catch (err) {
          console.error("error", err);
        }
    },

    async saveExamSettings() {
        let objToPush = {};
      let createdQuestionPaperLength = this.selectedSubjects.filter((sub) => sub.questionsCount > 0).length;
      let totalSubjectForOverAllCOs = this.selectedSubjects.filter((sub)=>sub.mappedCOs).length
      let questionWiseCosSubject = this.selectedSubjects.filter((sub)=>sub.questionWiseCOsCount >0).length;
      // console.log("questionWiseCosSubject", questionWiseCosSubject)
      if(totalSubjectForOverAllCOs > 0 && createdQuestionPaperLength === 0 ){
        // console.log("case1")
      } else if (totalSubjectForOverAllCOs > 0 && createdQuestionPaperLength>0 && questionWiseCosSubject > 0){
        // console.log("case3")
        // console.log("case 3", this.exam)
        objToPush = {
            areQuestionsAvailable : true,
            questionWiseCo : true,
        }
      } else if(totalSubjectForOverAllCOs > 0 && createdQuestionPaperLength>0) {
        // console.log("case2")
        objToPush = {
            areQuestionsAvailable : true,
            questionWiseCo : false,
        }
      }
      const mergedSettings = { ...this.exam.settings, ...objToPush };
      // console.log("obj to push", objToPush, this.exam.settings)
      // console.log("mergedSettings", mergedSettings)
      try {
        let response = await this.newExamRepositoryInstance.createExam({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          examId: this.examId,
          settings: mergedSettings,
          courseYear: this.selectedCourseYear,
        });
        this.exam = response.exam;
        if (response.status.status === 200) {
            showStatus(
              "Successfully saved exam settings.",
              2000,
              "success",
              this
            );
        } else {
          showStatus("Something went wrong", 2000, "error", this);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style src="./examQuestionDetails.css"></style>
<template src="./activityQuestionTemplate.html"></template>

<script>
import activitySettings from "./activitySettings/activitySettings.vue";
import activityQuestions from "./activityQuestions/activityQuestions.vue";
import activityScale from "./activityScale/activityScale.vue";
export default {
  name: "activityQuestionTemplate",
  components: {
    activitySettings,
    activityQuestions,
    activityScale,
  },
  props: [
    "prop_settingId",
    "prop_department",
    "prop_tabNumber",
    "prop_feedback",
    "prop_stakeHolder",
  ],
  data() {
    return {
      backgroundColor: "#ECECFF",
      currentTab: 0,
      mainTabNames: [" Template Details", "Questions", "Scale & Options"],
      isScaleChange: "No",
      lowestNo: "",
      highestNo: "",
      scales: [],
      questionWiseScales: [
        {
          questionNo: "Q1",
          statement: "w1",
          type: "rating",
          lowestNo: 1,
          highestNo: 5,
          labels: [],
        },
        {
          questionNo: "Q2",
          statement: "w2",
          type: "rating",
          lowestNo: 1,
          highestNo: 5,
          labels: [],
        },
      ],
      propData: {},
      disableTabs: [false, true, true],
      isNewPage: true,
      selectedValue:''
    };
  },
  mounted() {},
  async created() {
    this.propData = {
      settingId: this.prop_settingId,
      department: this.prop_department,
      tabNumber: this.prop_tabNumber,
      feedback: this.prop_feedback,
      stakeHolder: this.prop_stakeHolder,
      createdTemplates: this.$route.params.createdTemplates,
      selectedTemplate: this.$route.params.selectedTemplate,
    };
    const template =
      this.$store.getters["liveData/get_templateTabDependencies"];

    // if (template?.templateId) {
    //   this.disableTabs = [false, false, false];
    // }
    if (template?.moveToquestionTab) {
      this.handleNextButtonClick();
    }
  },
  methods: {
    isTabDisabled(index) {
      return this.disableTabs[index];
    },
    updateSelectedValue(value) {
      this.selectedValue = value; // Update with value from child
    },
    handleNextButtonClick() {
      this.currentTab += 1;
    },
    async handleGenerateScales() {
      const data = await this.generateScales(this.lowestNo, this.highestNo);
      this.scales = data;
    },
    async generateScales(lowestNo, highestNo) {
      const length = parseInt(highestNo) - parseInt(lowestNo) + 1;
      const scales = Array.from({ length }, (_, index) => ({
        scaleNo: (index + parseInt(lowestNo)).toString(),
        scaleLabel: "",
      }));
      return scales;
    },
    async validateScaleAndOptionsDetails() {
      try {
        const objToPush = {};
        const fixedScaleForAllQuestion = false;
        const scales = {
          lowestNo: this.lowestNo,
          highestNo: this.highestNo,
          scales: this.scales,
        };
        if (fixedScaleForAllQuestion === true) {
          objToPush.scales = scales;
        } else {
          objToPush.scales = this.questionWiseScales;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async modifyQuestionSet(lowestNo, highestNo, index) {
      const data = await this.generateScales(lowestNo, highestNo);
      this.questionWiseScales[index].labels = data;
    },
    goToNextPage() {
      this.$emit("nextButtonClick");
    },
    goToFeedback() {
      this.$router.push("activityInstitutesManagment");
    },
  },
};
</script>

<style src="./activityQuestionTemplate.css" scope></style>
